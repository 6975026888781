// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReSchemaI18n from "./ReSchemaI18n.bs.js";
import * as ReSchemaRegExp from "./ReSchemaRegExp.bs.js";

function Make(Lenses) {
  var schema = Belt_Array.concatMany;
  var mergeValidators = function (validations) {
    return Belt_Array.reduce(validations, [], (function (rules, param) {
                  var rule = param[0];
                  if (rule !== undefined) {
                    return Belt_Array.concat(rules, [param[1](Caml_option.valFromOption(rule))]);
                  } else {
                    return rules;
                  }
                }));
  };
  var custom = function (predicate, field) {
    return [{
              TAG: "Custom",
              field: field,
              predicate: predicate
            }];
  };
  var true_ = function (error, field) {
    return [{
              TAG: "True",
              field: field,
              error: error
            }];
  };
  var false_ = function (error, field) {
    return [{
              TAG: "False",
              field: field,
              error: error
            }];
  };
  var email = function (error, field) {
    return [{
              TAG: "Email",
              field: field,
              error: error
            }];
  };
  var nonEmpty = function (error, field) {
    return [{
              TAG: "StringNonEmpty",
              field: field,
              error: error
            }];
  };
  var string = function (min, minError, max, maxError, field) {
    return mergeValidators([
                [
                  min,
                  (function (min) {
                      return {
                              TAG: "StringMin",
                              field: field,
                              min: min,
                              error: minError
                            };
                    })
                ],
                [
                  max,
                  (function (max) {
                      return {
                              TAG: "StringMax",
                              field: field,
                              max: max,
                              error: maxError
                            };
                    })
                ]
              ]);
  };
  var regExp = function (error, matches, field) {
    return [{
              TAG: "StringRegExp",
              field: field,
              matches: matches,
              error: error
            }];
  };
  var $$float = function (min, minError, max, maxError, field) {
    return mergeValidators([
                [
                  min,
                  (function (min) {
                      return {
                              TAG: "FloatMin",
                              field: field,
                              min: min,
                              error: minError
                            };
                    })
                ],
                [
                  max,
                  (function (max) {
                      return {
                              TAG: "FloatMax",
                              field: field,
                              max: max,
                              error: maxError
                            };
                    })
                ]
              ]);
  };
  var $$int = function (min, minError, max, maxError, field) {
    return mergeValidators([
                [
                  min,
                  (function (min) {
                      return {
                              TAG: "IntMin",
                              field: field,
                              min: min,
                              error: minError
                            };
                    })
                ],
                [
                  max,
                  (function (max) {
                      return {
                              TAG: "IntMax",
                              field: field,
                              max: max,
                              error: maxError
                            };
                    })
                ]
              ]);
  };
  var Validation = {
    schema: schema,
    mergeValidators: mergeValidators,
    custom: custom,
    true_: true_,
    false_: false_,
    email: email,
    nonEmpty: nonEmpty,
    string: string,
    regExp: regExp,
    $$float: $$float,
    $$int: $$int
  };
  var validateField = function (validator, values, i18n) {
    switch (validator.TAG) {
      case "Email" :
          var field = validator.field;
          var value = Lenses.get(values, field);
          return [
                  {
                    TAG: "Field",
                    _0: field
                  },
                  ReSchemaRegExp.email.test(value) ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.email(value))
                      })
                ];
      case "NoValidation" :
          return [
                  {
                    TAG: "Field",
                    _0: validator.field
                  },
                  "Valid"
                ];
      case "StringNonEmpty" :
          var field$1 = validator.field;
          var value$1 = Lenses.get(values, field$1);
          return [
                  {
                    TAG: "Field",
                    _0: field$1
                  },
                  value$1 === "" ? ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.stringNonEmpty(value$1))
                      }) : "Valid"
                ];
      case "StringRegExp" :
          var matches = validator.matches;
          var field$2 = validator.field;
          var value$2 = Lenses.get(values, field$2);
          return [
                  {
                    TAG: "Field",
                    _0: field$2
                  },
                  new RegExp(matches).test(value$2) ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.stringRegExp(value$2, matches))
                      })
                ];
      case "StringMin" :
          var min = validator.min;
          var field$3 = validator.field;
          var value$3 = Lenses.get(values, field$3);
          return [
                  {
                    TAG: "Field",
                    _0: field$3
                  },
                  value$3.length >= min ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.stringMin(value$3, min))
                      })
                ];
      case "StringMax" :
          var max = validator.max;
          var field$4 = validator.field;
          var value$4 = Lenses.get(values, field$4);
          return [
                  {
                    TAG: "Field",
                    _0: field$4
                  },
                  value$4.length <= max ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.stringMax(value$4, max))
                      })
                ];
      case "IntMin" :
          var min$1 = validator.min;
          var field$5 = validator.field;
          var value$5 = Lenses.get(values, field$5);
          return [
                  {
                    TAG: "Field",
                    _0: field$5
                  },
                  value$5 >= min$1 ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.intMin(value$5, min$1))
                      })
                ];
      case "IntMax" :
          var max$1 = validator.max;
          var field$6 = validator.field;
          var value$6 = Lenses.get(values, field$6);
          return [
                  {
                    TAG: "Field",
                    _0: field$6
                  },
                  value$6 <= max$1 ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.intMax(value$6, max$1))
                      })
                ];
      case "FloatMin" :
          var min$2 = validator.min;
          var field$7 = validator.field;
          var value$7 = Lenses.get(values, field$7);
          return [
                  {
                    TAG: "Field",
                    _0: field$7
                  },
                  value$7 >= min$2 ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.floatMin(value$7, min$2))
                      })
                ];
      case "FloatMax" :
          var max$2 = validator.max;
          var field$8 = validator.field;
          var value$8 = Lenses.get(values, field$8);
          return [
                  {
                    TAG: "Field",
                    _0: field$8
                  },
                  Lenses.get(values, field$8) <= max$2 ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.floatMax(value$8, max$2))
                      })
                ];
      case "Custom" :
          return [
                  {
                    TAG: "Field",
                    _0: validator.field
                  },
                  validator.predicate(values)
                ];
      case "True" :
          var field$9 = validator.field;
          var value$9 = Lenses.get(values, field$9);
          return [
                  {
                    TAG: "Field",
                    _0: field$9
                  },
                  value$9 ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.true_())
                      })
                ];
      case "False" :
          var field$10 = validator.field;
          var value$10 = Lenses.get(values, field$10);
          return [
                  {
                    TAG: "Field",
                    _0: field$10
                  },
                  value$10 === false ? "Valid" : ({
                        TAG: "Error",
                        _0: Belt_Option.getWithDefault(validator.error, i18n.false_())
                      })
                ];
      
    }
  };
  var getFieldValidator = function (validators, fieldName) {
    return Belt_Array.getBy(validators, (function (validator) {
                  return Caml_obj.equal({
                              TAG: "Field",
                              _0: validator.field
                            }, fieldName);
                }));
  };
  var getFieldValidators = function (validators, fieldName) {
    return Belt_Array.keep(validators, (function (validator) {
                  return Caml_obj.equal({
                              TAG: "Field",
                              _0: validator.field
                            }, fieldName);
                }));
  };
  var validateOne = function (field, values, i18nOpt, schema) {
    var i18n = i18nOpt !== undefined ? i18nOpt : ReSchemaI18n.default;
    return Belt_Array.getBy(Belt_Array.map(getFieldValidators(schema, field), (function (validator) {
                      return validateField(validator, values, i18n);
                    })), (function (validation) {
                  var tmp = validation[1];
                  if (typeof tmp !== "object" || tmp.TAG === "NestedErrors") {
                    return false;
                  } else {
                    return true;
                  }
                }));
  };
  var validateFields = function (fields, values, i18n, schema) {
    return Belt_Array.map(fields, (function (field) {
                  return Belt_Option.map(getFieldValidator(schema, field), (function (validator) {
                                return validateField(validator, values, i18n);
                              }));
                }));
  };
  var validate = function (i18nOpt, values, schema) {
    var i18n = i18nOpt !== undefined ? i18nOpt : ReSchemaI18n.default;
    var validationList = Belt_Array.map(schema, (function (validator) {
            return validateField(validator, values, i18n);
          }));
    var errors = Belt_Array.keepMap(validationList, (function (param) {
            var fieldState = param[1];
            if (typeof fieldState !== "object" || fieldState.TAG === "NestedErrors") {
              return ;
            } else {
              return [
                      param[0],
                      fieldState._0
                    ];
            }
          }));
    if (errors.length !== 0) {
      return {
              TAG: "Errors",
              _0: errors
            };
    } else {
      return "Valid";
    }
  };
  return {
          Validation: Validation,
          validateField: validateField,
          getFieldValidator: getFieldValidator,
          getFieldValidators: getFieldValidators,
          validateOne: validateOne,
          validateFields: validateFields,
          validate: validate
        };
}

export {
  Make ,
}
/* No side effect */
