// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as React from "react";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as MeterKeg from "../../components/MeterKeg/MeterKeg.bs.js";
import * as TapKegOn from "./TapKegOn.bs.js";
import * as TapAddNew from "./TapAddNew.bs.js";
import * as TapRename from "./TapRename.bs.js";
import * as Reactfire from "reactfire";
import * as ButtonMenu from "../../components/ButtonMenu/ButtonMenu.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as SectionWithHeader from "../../components/SectionWithHeader/SectionWithHeader.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DialogConfirmation from "../../components/DialogConfirmation/DialogConfirmation.bs.js";
import TapsSettingModuleCss from "./TapsSetting.module.css";

var classes = TapsSettingModuleCss;

function TapsSetting$ConfirmDeleteTap(props) {
  return JsxRuntime.jsx(DialogConfirmation.make, {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      "Potvzením smažete pípu: ",
                      JsxRuntime.jsx("b", {
                            children: props.tapName
                          })
                    ]
                  }),
              heading: "Smazat pípu ❓",
              onConfirm: props.onConfirm,
              onDismiss: props.onDismiss,
              visible: true
            });
}

var ConfirmDeleteTap = {
  make: TapsSetting$ConfirmDeleteTap
};

function TapsSetting(props) {
  var untappedChargedKegs = props.untappedChargedKegs;
  var tappedChargedKegs = props.tappedChargedKegs;
  var placeId = props.placeId;
  var place = props.place;
  var firestore = Reactfire.useFirestore();
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDialog = match[1];
  var dialogState = match[0];
  var hideDialog = function (param) {
    setDialog(function (param) {
          return "Hidden";
        });
  };
  var hasKegsToTap = untappedChargedKegs.length > 0;
  var tappedKegsById = React.useMemo((function () {
          return Object.fromEntries(tappedChargedKegs.map(function (keg) {
                          return [
                                  Db.getUid(keg),
                                  keg
                                ];
                        }));
        }), [tappedChargedKegs]);
  var sortedTapEntries = React.useMemo((function () {
          var tapsEntries = Js_dict.entries(place.taps);
          tapsEntries.sort(function (a, b) {
                return a[0].localeCompare(b[0]);
              });
          return tapsEntries;
        }), [place.taps]);
  var tapsCount = sortedTapEntries.length;
  var tmp;
  if (typeof dialogState !== "object") {
    tmp = dialogState === "Hidden" ? null : JsxRuntime.jsx(TapAddNew.make, {
            existingNames: Object.keys(place.taps),
            onDismiss: hideDialog,
            onSubmit: (async function (values) {
                Db.Place.tapAdd(firestore, placeId, values.name);
                return setDialog(function (param) {
                            return "Hidden";
                          });
              })
          });
  } else {
    switch (dialogState.TAG) {
      case "RenameTap" :
          var tapName = dialogState._0;
          tmp = JsxRuntime.jsx(TapRename.make, {
                existingNames: Object.keys(place.taps),
                initialName: tapName,
                onDismiss: hideDialog,
                onSubmit: (function (values) {
                    Db.Place.tapRename(firestore, placeId, tapName, values.name);
                    setDialog(function (param) {
                          return "Hidden";
                        });
                  })
              });
          break;
      case "DeleteTap" :
          var tapName$1 = dialogState._0;
          tmp = JsxRuntime.jsx(TapsSetting$ConfirmDeleteTap, {
                onConfirm: (function () {
                    Db.Place.tapDelete(firestore, placeId, tapName$1);
                  }),
                onDismiss: hideDialog,
                tapName: tapName$1
              });
          break;
      case "TapKegOn" :
          var tapName$2 = dialogState._0;
          tmp = JsxRuntime.jsx(TapKegOn.make, {
                onDismiss: hideDialog,
                onSubmit: (function (values) {
                    Db.Place.tapKegOn(firestore, placeId, tapName$2, values.keg);
                    setDialog(function (param) {
                          return "Hidden";
                        });
                  }),
                tapName: tapName$2,
                untappedChargedKegs: untappedChargedKegs
              });
          break;
      
    }
  }
  return JsxRuntime.jsxs(SectionWithHeader.make, {
              children: [
                JsxRuntime.jsx("ul", {
                      children: sortedTapEntries.map(function (param) {
                            var tapName = param[0];
                            var tappedKeg = Core__Option.flatMap(Caml_option.null_to_opt(param[1]), (function (kegRef) {
                                    return tappedKegsById[kegRef.id];
                                  }));
                            return JsxRuntime.jsxs("li", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          tapName,
                                                          Core__Option.mapOr(tappedKeg, null, (function (keg) {
                                                                  return JsxRuntime.jsxs("div", {
                                                                              children: [
                                                                                JsxRuntime.jsx("span", {
                                                                                      children: keg.serialFormatted
                                                                                    }),
                                                                                " " + keg.beer
                                                                              ],
                                                                              className: classes.tappedBeer
                                                                            });
                                                                }))
                                                        ]
                                                      }),
                                                  tappedKeg !== undefined ? JsxRuntime.jsx("button", {
                                                          children: "Odrazit",
                                                          className: Styles.button.variantDanger,
                                                          type: "button",
                                                          onClick: (function (param) {
                                                              Db.Place.tapKegOff(firestore, placeId, tapName);
                                                            })
                                                        }) : JsxRuntime.jsx("button", {
                                                          children: "Narazit",
                                                          className: Styles.button.variantPrimary,
                                                          disabled: !hasKegsToTap,
                                                          type: "button",
                                                          onClick: (function (param) {
                                                              setDialog(function (param) {
                                                                    return {
                                                                            TAG: "TapKegOn",
                                                                            _0: tapName
                                                                          };
                                                                  });
                                                            })
                                                        }),
                                                  JsxRuntime.jsx(ButtonMenu.make, {
                                                        children: "⋯",
                                                        className: Styles.button.iconOnly,
                                                        menuItems: [
                                                          {
                                                            label: "Přejmenovat",
                                                            onClick: (function (param) {
                                                                setDialog(function (param) {
                                                                      return {
                                                                              TAG: "RenameTap",
                                                                              _0: tapName
                                                                            };
                                                                    });
                                                              })
                                                          },
                                                          {
                                                            disabled: tappedKeg !== undefined || tapsCount < 2,
                                                            label: "Smazat",
                                                            onClick: (function (param) {
                                                                setDialog(function (param) {
                                                                      return {
                                                                              TAG: "DeleteTap",
                                                                              _0: tapName
                                                                            };
                                                                    });
                                                              })
                                                          }
                                                        ],
                                                        title: "další akce"
                                                      })
                                                ]
                                              }),
                                          tappedKeg !== undefined ? JsxRuntime.jsx(MeterKeg.make, {
                                                  ariaLabel: "Sud " + tappedKeg.serialFormatted + " pivo " + tappedKeg.beer + " zbývající hladina",
                                                  keg: tappedKeg
                                                }) : null
                                        ]
                                      }, tapName);
                          }),
                      className: classes.list
                    }),
                tmp
              ],
              buttonsSlot: JsxRuntime.jsx("button", {
                    children: "Přidat pípu",
                    className: Styles.button.base,
                    type: "button",
                    onClick: (function (param) {
                        setDialog(function (param) {
                              return "AddTap";
                            });
                      })
                  }),
              headerId: "taps_setting",
              headerSlot: "Pípy"
            });
}

var make = TapsSetting;

export {
  classes ,
  ConfirmDeleteTap ,
  make ,
}
/* classes Not a pure module */
