// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FormattedDateTimeShort from "../FormattedDataTime/FormattedDateTimeShort.bs.js";
import TableConsumptionsModuleCss from "./TableConsumptions.module.css";

var classes = TableConsumptionsModuleCss;

function TableConsumptions(props) {
  var onDeleteConsumption = props.onDeleteConsumption;
  var formatConsumption = props.formatConsumption;
  return JsxRuntime.jsxs("table", {
              children: [
                JsxRuntime.jsx("thead", {
                      children: JsxRuntime.jsxs("tr", {
                            children: [
                              JsxRuntime.jsx("th", {
                                    children: "Pivo",
                                    scope: "col"
                                  }),
                              JsxRuntime.jsx("th", {
                                    children: "Kdy",
                                    scope: "col"
                                  }),
                              JsxRuntime.jsx("th", {
                                    children: "Objem",
                                    scope: "col"
                                  }),
                              JsxRuntime.jsx("th", {
                                    children: JsxRuntime.jsx("span", {
                                          children: "Akce",
                                          className: Styles.utility.srOnly
                                        }),
                                    scope: "col"
                                  })
                            ],
                            className: Styles.utility.srOnly
                          })
                    }),
                JsxRuntime.jsx("tbody", {
                      children: props.unfinishedConsumptions.map(function (consumption) {
                            var createdAt = consumption.createdAt.toISOString();
                            return JsxRuntime.jsxs("tr", {
                                        children: [
                                          JsxRuntime.jsx("th", {
                                                children: consumption.beer,
                                                scope: "row"
                                              }),
                                          JsxRuntime.jsx("td", {
                                                children: JsxRuntime.jsx(FormattedDateTimeShort.make, {
                                                      value: consumption.createdAt
                                                    })
                                              }),
                                          JsxRuntime.jsx("td", {
                                                children: formatConsumption(consumption.milliliters)
                                              }),
                                          JsxRuntime.jsx("td", {
                                                children: JsxRuntime.jsx("button", {
                                                      children: "🗑️ Smáznout",
                                                      className: classes.deleteButton + " " + Styles.button.sizeExtraSmall,
                                                      type: "button",
                                                      onClick: (function (param) {
                                                          onDeleteConsumption(consumption);
                                                        })
                                                    })
                                              })
                                        ]
                                      }, createdAt);
                          })
                    })
              ],
              "aria-labelledby": props.ariaLabelledby,
              className: classes.table + " " + Styles.table.inDialog
            });
}

var make = TableConsumptions;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
