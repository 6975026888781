// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dialog from "../Dialog/Dialog.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import DialogConfirmationModuleCss from "./DialogConfirmation.module.css";

var classes = DialogConfirmationModuleCss;

function DialogConfirmation(props) {
  var onDismiss = props.onDismiss;
  var onConfirm = props.onConfirm;
  return JsxRuntime.jsxs(Dialog.make, {
              children: [
                JsxRuntime.jsx("header", {
                      children: JsxRuntime.jsx("h3", {
                            children: props.heading
                          })
                    }),
                props.children,
                JsxRuntime.jsxs("footer", {
                      children: [
                        JsxRuntime.jsx("button", {
                              children: "Provést",
                              className: Styles.button.variantPrimary,
                              type: "submit",
                              onClick: (function (param) {
                                  onConfirm();
                                })
                            }),
                        JsxRuntime.jsx("button", {
                              children: "Zrušit",
                              className: Styles.button.base,
                              type: "button",
                              onClick: (function (param) {
                                  onDismiss();
                                })
                            })
                      ]
                    })
              ],
              className: props.className,
              visible: props.visible
            });
}

var make = DialogConfirmation;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
