// Generated by ReScript, PLEASE EDIT WITH CARE

import EyeShowSvg from "./eyeShow.svg";
import PouringSvg from "./pouring.svg";
import EyeHiddenSvg from "./eyeHidden.svg";
import BeerGlassLargeSvg from "./beerGlassLarge.svg";
import BeerGlassSmallSvg from "./beerGlassSmall.svg";

var beerGlassLarge = BeerGlassLargeSvg;

var beerGlassSmall = BeerGlassSmallSvg;

var eyeHidden = EyeHiddenSvg;

var eyeShow = EyeShowSvg;

var pouring = PouringSvg;

export {
  beerGlassLarge ,
  beerGlassSmall ,
  eyeHidden ,
  eyeShow ,
  pouring ,
}
/* beerGlassLarge Not a pure module */
