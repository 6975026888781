// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DomUtils from "../../utils/DomUtils.bs.js";
import * as Reactfire from "reactfire";
import * as AppStorage from "../../utils/AppStorage.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Firestore from "firebase/firestore";

var initializedFirestore = {
  contents: undefined
};

function FirebaseFirestoreProvider(props) {
  var isStandaloneModeStatus = DomUtils.useIsStandaloneMode();
  var match = Reactfire.useInitFirestore(async function (app) {
        var firestore = initializedFirestore.contents;
        if (firestore !== undefined) {
          return Caml_option.valFromOption(firestore);
        }
        var isStandaloneMode = Core__Option.getOr(isStandaloneModeStatus.data, false);
        var firestore$1 = Firestore.initializeFirestore(app, !isStandaloneMode && AppStorage.getThrustDevice() === undefined ? ({}) : ({
                  localCache: Caml_option.some(Firestore.persistentLocalCache({
                            tabManager: Caml_option.some(Firestore.persistentMultipleTabManager())
                          }))
                }));
        initializedFirestore.contents = Caml_option.some(firestore$1);
        return firestore$1;
      });
  var status = match.status;
  if (status === "loading") {
    return null;
  }
  if (status !== "success") {
    return JsxRuntime.jsx("div", {
                children: "Some error hapenned"
              });
  }
  var firestore = match.data;
  if (firestore !== undefined) {
    return JsxRuntime.jsx(Reactfire.FirestoreProvider, {
                sdk: Caml_option.valFromOption(firestore),
                children: props.children
              });
  } else {
    return null;
  }
}

var make = FirebaseFirestoreProvider;

export {
  initializedFirestore ,
  make ,
}
/* DomUtils Not a pure module */
