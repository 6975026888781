// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/src/Core__Float.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function get(state, field) {
  switch (field) {
    case "Amount" :
        return state.amount;
    case "Note" :
        return state.note;
    case "Person" :
        return state.person;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case "Amount" :
        return {
                amount: value,
                note: state.note,
                person: state.person
              };
    case "Note" :
        return {
                amount: state.amount,
                note: value,
                person: state.person
              };
    case "Person" :
        return {
                amount: state.amount,
                note: state.note,
                person: value
              };
    
  }
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function AddFinancialTransactions(props) {
  var personsAll = props.personsAll;
  var personId = props.personId;
  var onSubmit = props.onSubmit;
  var match = FormattedCurrency.useCurrency();
  var minorUnit = match.minorUnit;
  var form = Form.use({
        amount: "",
        note: "",
        person: props.initialCounterParty
      }, Validators$1.schema([
            Validators$1.required("Amount"),
            Validators$1.isNumeric(undefined, "Amount"),
            Validators$1.required("Person")
          ]), (function (param) {
          var state = param.state;
          var amountFloat = Core__Option.getExn(Core__Float.fromString(state.values.amount), undefined);
          var amountMinor = amountFloat * minorUnit;
          onSubmit({
                amount: amountMinor | 0,
                note: state.values.note,
                person: state.values.person
              });
        }), undefined, undefined, "OnDemand", undefined);
  return JsxRuntime.jsxs(DialogForm.make, {
              children: [
                JsxRuntime.jsxs("p", {
                      children: [
                        JsxRuntime.jsx("b", {
                              children: props.personName
                            }),
                        " předává peníze."
                      ]
                    }),
                JsxRuntime.jsx(Form.Provider.make, {
                      value: form,
                      children: JsxRuntime.jsx("form", {
                            children: JsxRuntime.jsxs("fieldset", {
                                  children: [
                                    JsxRuntime.jsx(Form.Field.make, {
                                          field: "Person",
                                          render: (function (field) {
                                              return JsxRuntime.jsx(InputWrapper.make, {
                                                          inputError: field.error,
                                                          inputName: "amount",
                                                          inputSlot: JsxRuntime.jsxs("select", {
                                                                children: [
                                                                  JsxRuntime.jsx("option", {
                                                                        children: "Příjemce platby",
                                                                        disabled: true,
                                                                        value: ""
                                                                      }),
                                                                  Belt_Array.keepMap(personsAll, (function (param) {
                                                                          var pId = param[0];
                                                                          if (pId === personId) {
                                                                            return ;
                                                                          } else {
                                                                            return Caml_option.some(JsxRuntime.jsx("option", {
                                                                                            children: param[1].name,
                                                                                            value: pId
                                                                                          }, pId));
                                                                          }
                                                                        }))
                                                                ],
                                                                value: field.value,
                                                                onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                              }),
                                                          labelSlot: "Komu"
                                                        });
                                            })
                                        }),
                                    JsxRuntime.jsx(Form.Field.make, {
                                          field: "Amount",
                                          render: (function (field) {
                                              return JsxRuntime.jsx(InputWrapper.make, {
                                                          inputError: field.error,
                                                          inputName: "amount",
                                                          inputSlot: JsxRuntime.jsx("input", {
                                                                step: 1.0,
                                                                type: "number",
                                                                value: field.value,
                                                                onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                              }),
                                                          labelSlot: "Částka"
                                                        });
                                            })
                                        }),
                                    JsxRuntime.jsx(Form.Field.make, {
                                          field: "Note",
                                          render: (function (field) {
                                              return JsxRuntime.jsx(InputWrapper.make, {
                                                          inputError: field.error,
                                                          inputName: "note",
                                                          inputSlot: JsxRuntime.jsx("input", {
                                                                type: "text",
                                                                value: field.value,
                                                                onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                              }),
                                                          labelSlot: "Poznámka"
                                                        });
                                            })
                                        })
                                  ],
                                  className: "reset " + Styles.fieldset.grid
                                }),
                            id: "addFinancialTransaction",
                            onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                          })
                    })
              ],
              formId: "addFinancialTransaction",
              heading: "Platba mezi účastníky",
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = AddFinancialTransactions;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
