// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as HtmlEntities from "../../utils/HtmlEntities.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as FirebaseError from "../../backend/FirebaseError.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import SignInFormModuleCss from "./SignInForm.module.css";
import * as UnauthenticatedTemplate from "./UnauthenticatedTemplate.bs.js";

var classes = SignInFormModuleCss;

function get(state, field) {
  if (field === "Email") {
    return state.email;
  } else {
    return state.password;
  }
}

function set(state, field, value) {
  if (field === "Email") {
    return {
            email: value,
            password: state.password
          };
  } else {
    return {
            email: state.email,
            password: value
          };
  }
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

var wrongPasswordMessage = "@@wrong-password";

function SignInForm(props) {
  var onSignUp = props.onSignUp;
  var onSignInWithPassword = props.onSignInWithPassword;
  var onSignInWithPasskey = props.onSignInWithPasskey;
  var onSignInWithGoogle = props.onSignInWithGoogle;
  var onForgottenPassword = props.onForgottenPassword;
  var form = Form.use({
        email: "",
        password: ""
      }, Validators$1.schema([
            Validators$1.required("Email"),
            Validators$1.email("Email"),
            Validators$1.required("Password"),
            Validators$1.password("Password")
          ]), (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          Core__Promise.$$catch(onSignInWithPassword(param.state.values), (function (error) {
                  var match = FirebaseError.toFirebaseError(error);
                  var errorMessage;
                  if (match.RE_EXN_ID === FirebaseError.InvalidPassword) {
                    errorMessage = wrongPasswordMessage;
                  } else {
                    var exn = Core__Option.getExn(Caml_js_exceptions.as_js_exn(error), undefined);
                    LogUtils.captureException(exn);
                    var msg = exn.message;
                    errorMessage = msg !== undefined ? "Chyba: " + msg : "Neznámá chyba";
                  }
                  raiseSubmitFailed(errorMessage);
                  return Promise.resolve();
                }));
        }), undefined, undefined, "OnDemand", undefined);
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = JsxRuntime.jsx("p", {
          children: maybeErrorMessage$1 !== undefined ? (
              maybeErrorMessage$1 !== wrongPasswordMessage ? maybeErrorMessage$1 : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        "Špatný e" + HtmlEntities.nbhp + "mail nebo heslo. Máte problém s pamětí? ",
                        JsxRuntime.jsx("button", {
                              children: "Obnovte heslo přes e" + HtmlEntities.nbhp + "mail.",
                              className: Styles.link.base,
                              type: "button",
                              onClick: (function (param) {
                                  onForgottenPassword(form.values.email);
                                })
                            })
                      ]
                    })
            ) : "Neznámá chyba",
          className: Styles.messageBar.variantDanger,
          role: "alert"
        });
  }
  return JsxRuntime.jsxs(UnauthenticatedTemplate.make, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Přihlášení"
                    }),
                JsxRuntime.jsx(Form.Provider.make, {
                      value: form,
                      children: JsxRuntime.jsxs("form", {
                            children: [
                              JsxRuntime.jsxs("fieldset", {
                                    children: [
                                      JsxRuntime.jsx(Form.Field.make, {
                                            field: "Email",
                                            render: (function (field) {
                                                return JsxRuntime.jsx(InputWrapper.make, {
                                                            inputError: field.error,
                                                            inputName: "email",
                                                            inputSlot: JsxRuntime.jsx("input", {
                                                                  autoComplete: "username email",
                                                                  type: "email",
                                                                  value: field.value,
                                                                  onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                }),
                                                            labelSlot: "E" + HtmlEntities.nbhp + "mail"
                                                          });
                                              })
                                          }),
                                      JsxRuntime.jsx(Form.Field.make, {
                                            field: "Password",
                                            render: (function (field) {
                                                return JsxRuntime.jsx(InputWrapper.make, {
                                                            inputError: field.error,
                                                            inputName: "password",
                                                            inputSlot: JsxRuntime.jsx("input", {
                                                                  autoComplete: "current-password",
                                                                  type: "password",
                                                                  value: field.value,
                                                                  onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                }),
                                                            labelSlot: "Heslo"
                                                          });
                                              })
                                          })
                                    ],
                                    className: "reset " + Styles.fieldset.grid
                                  }),
                              tmp,
                              JsxRuntime.jsx("button", {
                                    children: "Přihlásit se heslem",
                                    className: Styles.button.base,
                                    type: "submit"
                                  })
                            ],
                            className: Styles.stack.base,
                            onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                          })
                    }),
                JsxRuntime.jsxs("p", {
                      children: [
                        "Nemáte účet? ",
                        JsxRuntime.jsx("button", {
                              children: "Zaregistrujte se.",
                              className: Styles.link.base,
                              type: "button",
                              onClick: (function (param) {
                                  onSignUp();
                                })
                            })
                      ]
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: JsxRuntime.jsx("span", {
                                    children: "nebo"
                                  }),
                              id: "other_methods"
                            }),
                        onSignInWithPasskey !== undefined ? JsxRuntime.jsx("button", {
                                children: "Přihlásit otiskem",
                                className: Styles.button.base,
                                type: "button",
                                onClick: (function (param) {
                                    onSignInWithPasskey();
                                  })
                              }) : null,
                        JsxRuntime.jsx("button", {
                              children: "Přihlásit přes Google",
                              className: Styles.button.base,
                              type: "button",
                              onClick: (function (param) {
                                  onSignInWithGoogle();
                                })
                            })
                      ],
                      "aria-labelledby": "other_methods"
                    })
              ],
              className: classes.root,
              isOnline: props.isOnline,
              loadingOverlay: props.loadingOverlay
            });
}

var make = SignInForm;

export {
  classes ,
  FormFields ,
  Form ,
  Validators$1 as Validators,
  wrongPasswordMessage ,
  make ,
}
/* classes Not a pure module */
