// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FirebaseConfig from "./firebaseConfig";
import * as Messaging from "firebase/messaging";

var FirebaseOptions = {};

var FirebaseApp = {};

var Analytics = {};

var Transaction = {};

var WriteBatch = {};

var User = {};

var EmailAuthProvider = {
  providerID: "password"
};

var GithubAuthProvider = {
  providerID: "github.com"
};

var GoogleAuthProvider = {
  providerID: "google.com"
};

var FederatedAuthProvider = {};

var Auth = {
  EmailAuthProvider: EmailAuthProvider,
  GithubAuthProvider: GithubAuthProvider,
  GoogleAuthProvider: GoogleAuthProvider,
  FederatedAuthProvider: FederatedAuthProvider
};

var appCheckToken = FirebaseConfig.APP_CHECK_TOKEN;

var PersistentTabManager = {};

var FirestoreLocalCache = {
  PersistentTabManager: PersistentTabManager
};

var AppCheckProvider = {};

function getToken(messaging) {
  return Messaging.getToken(messaging, {
              vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY
            });
}

var Messaging$1 = {
  getToken: getToken
};

var Timestamp = {};

var StyledFirebaseAuth = {};

var Functions = {};

export {
  FirebaseOptions ,
  FirebaseApp ,
  Analytics ,
  Transaction ,
  WriteBatch ,
  User ,
  Auth ,
  appCheckToken ,
  FirestoreLocalCache ,
  AppCheckProvider ,
  Messaging$1 as Messaging,
  Timestamp ,
  StyledFirebaseAuth ,
  Functions ,
}
/* appCheckToken Not a pure module */
