// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReSchema from "./ReSchema.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactUpdate from "rescript-react-update/src/ReactUpdate.bs.js";
import * as ReSchemaI18n from "./ReSchemaI18n.bs.js";

function Make(Config) {
  var ReSchema$1 = ReSchema.Make(Config);
  var getInitialFieldsState = function (schema) {
    return Belt_Array.map(schema, (function (validator) {
                  return [
                          {
                            TAG: "Field",
                            _0: validator.field
                          },
                          "Pristine"
                        ];
                }));
  };
  var formContext = React.createContext(undefined);
  var useFormContext = function () {
    return React.useContext(formContext);
  };
  var useField = function (field) {
    var $$interface = React.useContext(formContext);
    return Belt_Option.map($$interface, (function (param) {
                  var validateField = param.validateField;
                  var handleChange = param.handleChange;
                  return {
                          handleChange: (function (value) {
                              handleChange(field, value);
                            }),
                          error: param.getFieldError({
                                TAG: "Field",
                                _0: field
                              }),
                          state: param.getFieldState({
                                TAG: "Field",
                                _0: field
                              }),
                          validate: (function () {
                              validateField({
                                    TAG: "Field",
                                    _0: field
                                  });
                            }),
                          value: Config.get(param.state.values, field)
                        };
                }));
  };
  var make = formContext.Provider;
  var Provider = {
    make: make
  };
  var ReForm$Make$Field = function (props) {
    var __renderOnMissingContext = props.renderOnMissingContext;
    var render = props.render;
    var renderOnMissingContext = __renderOnMissingContext !== undefined ? Caml_option.valFromOption(__renderOnMissingContext) : null;
    var fieldInterface = useField(props.field);
    return React.useMemo((function () {
                  return Belt_Option.getWithDefault(Belt_Option.map(fieldInterface, render), renderOnMissingContext);
                }), [
                Belt_Option.map(fieldInterface, (function (param) {
                        return param.error;
                      })),
                Belt_Option.map(fieldInterface, (function (param) {
                        return param.value;
                      })),
                Belt_Option.map(fieldInterface, (function (param) {
                        return param.state;
                      }))
              ]);
  };
  var Field = {
    make: ReForm$Make$Field
  };
  var use = function (initialState, schema, onSubmit, onSubmitFailOpt, i18nOpt, validationStrategyOpt, param) {
    var onSubmitFail = onSubmitFailOpt !== undefined ? onSubmitFailOpt : (function (prim) {
          
        });
    var i18n = i18nOpt !== undefined ? i18nOpt : ReSchemaI18n.default;
    var validationStrategy = validationStrategyOpt !== undefined ? validationStrategyOpt : "OnChange";
    var match = ReactUpdate.useReducer((function (state, action) {
            if (typeof action !== "object") {
              switch (action) {
                case "TrySubmit" :
                    return {
                            TAG: "SideEffects",
                            _0: (function (self) {
                                self.send({
                                      TAG: "ValidateForm",
                                      _0: true
                                    });
                              })
                          };
                case "Submit" :
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              formState: "Submitting",
                              values: state.values,
                              fieldsState: state.fieldsState
                            },
                            _1: (function (self) {
                                return onSubmit({
                                            send: self.send,
                                            state: self.state,
                                            raiseSubmitFailed: (function (error) {
                                                self.send({
                                                      TAG: "RaiseSubmitFailed",
                                                      _0: error
                                                    });
                                              })
                                          });
                              })
                          };
                case "ResetForm" :
                    return {
                            TAG: "Update",
                            _0: {
                              formState: "Pristine",
                              values: initialState,
                              fieldsState: getInitialFieldsState(schema)
                            }
                          };
                
              }
            } else {
              switch (action.TAG) {
                case "ValidateField" :
                    var field = action._0;
                    return {
                            TAG: "SideEffects",
                            _0: (function (self) {
                                var fieldState = ReSchema$1.validateOne(field, self.state.values, i18n, schema);
                                var newFieldState;
                                if (fieldState !== undefined) {
                                  var message = fieldState[1];
                                  newFieldState = typeof message !== "object" ? "Valid" : (
                                      message.TAG === "NestedErrors" ? ({
                                            TAG: "NestedErrors",
                                            _0: message._0
                                          }) : ({
                                            TAG: "Error",
                                            _0: message._0
                                          })
                                    );
                                } else {
                                  newFieldState = "Valid";
                                }
                                var newFieldsState = Belt_Array.concat(Belt_Array.keep(state.fieldsState, (function (param) {
                                            return Caml_obj.notequal(param[0], field);
                                          })), [[
                                        field,
                                        newFieldState
                                      ]]);
                                self.send({
                                      TAG: "SetFieldsState",
                                      _0: newFieldsState
                                    });
                              })
                          };
                case "ValidateForm" :
                    var submit = action._0;
                    return {
                            TAG: "SideEffects",
                            _0: (function (self) {
                                var recordState = ReSchema$1.validate(i18n, self.state.values, schema);
                                if (typeof recordState !== "object") {
                                  var newFieldsState = Belt_Array.map(self.state.fieldsState, (function (param) {
                                          return [
                                                  param[0],
                                                  "Valid"
                                                ];
                                        }));
                                  self.send({
                                        TAG: "SetFieldsState",
                                        _0: newFieldsState
                                      });
                                  if (submit) {
                                    self.send("Submit");
                                  }
                                  self.send({
                                        TAG: "SetFormState",
                                        _0: "Valid"
                                      });
                                } else {
                                  var newFieldsState$1 = Belt_Array.map(recordState._0, (function (param) {
                                          return [
                                                  param[0],
                                                  {
                                                    TAG: "Error",
                                                    _0: param[1]
                                                  }
                                                ];
                                        }));
                                  self.send({
                                        TAG: "SetFieldsState",
                                        _0: newFieldsState$1
                                      });
                                  if (submit) {
                                    var init = self.state;
                                    onSubmitFail({
                                          send: self.send,
                                          state: {
                                            formState: init.formState,
                                            values: init.values,
                                            fieldsState: newFieldsState$1
                                          },
                                          raiseSubmitFailed: (function (error) {
                                              self.send({
                                                    TAG: "RaiseSubmitFailed",
                                                    _0: error
                                                  });
                                            })
                                        });
                                  }
                                  self.send({
                                        TAG: "SetFormState",
                                        _0: "Errored"
                                      });
                                }
                              })
                          };
                case "SetFieldsState" :
                    return {
                            TAG: "Update",
                            _0: {
                              formState: state.formState,
                              values: state.values,
                              fieldsState: action._0
                            }
                          };
                case "FieldChangeState" :
                    return "NoUpdate";
                case "FieldChangeValue" :
                    var field$1 = action._0;
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              formState: state.formState === "Errored" ? "Errored" : "Dirty",
                              values: Config.set(state.values, field$1, action._1),
                              fieldsState: state.fieldsState
                            },
                            _1: (function (self) {
                                if (validationStrategy === "OnChange") {
                                  self.send({
                                        TAG: "ValidateField",
                                        _0: {
                                          TAG: "Field",
                                          _0: field$1
                                        }
                                      });
                                }
                                
                              })
                          };
                case "FieldChangeValueWithCallback" :
                    var field$2 = action._0;
                    var oldValue = Config.get(state.values, field$2);
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              formState: state.formState === "Errored" ? "Errored" : "Dirty",
                              values: Config.set(state.values, field$2, action._1(oldValue)),
                              fieldsState: state.fieldsState
                            },
                            _1: (function (self) {
                                if (validationStrategy === "OnChange") {
                                  self.send({
                                        TAG: "ValidateField",
                                        _0: {
                                          TAG: "Field",
                                          _0: field$2
                                        }
                                      });
                                }
                                
                              })
                          };
                case "FieldArrayAdd" :
                    var field$3 = action._0;
                    return {
                            TAG: "Update",
                            _0: {
                              formState: state.formState,
                              values: Config.set(state.values, field$3, Belt_Array.concat(Config.get(state.values, field$3), [action._1])),
                              fieldsState: state.fieldsState
                            }
                          };
                case "FieldArrayUpdateByIndex" :
                    var index = action._2;
                    var value = action._1;
                    var field$4 = action._0;
                    return {
                            TAG: "Update",
                            _0: {
                              formState: state.formState,
                              values: Config.set(state.values, field$4, Belt_Array.mapWithIndex(Config.get(state.values, field$4), (function (i, currentValue) {
                                          if (i === index) {
                                            return value;
                                          } else {
                                            return currentValue;
                                          }
                                        }))),
                              fieldsState: state.fieldsState
                            }
                          };
                case "FieldArrayRemove" :
                    var index$1 = action._1;
                    var field$5 = action._0;
                    return {
                            TAG: "Update",
                            _0: {
                              formState: state.formState,
                              values: Config.set(state.values, field$5, Belt_Array.keepWithIndex(Config.get(state.values, field$5), (function (param, i) {
                                          return i !== index$1;
                                        }))),
                              fieldsState: state.fieldsState
                            }
                          };
                case "FieldArrayRemoveBy" :
                    var predicate = action._1;
                    var field$6 = action._0;
                    return {
                            TAG: "Update",
                            _0: {
                              formState: state.formState,
                              values: Config.set(state.values, field$6, Belt_Array.keep(Config.get(state.values, field$6), (function (entry) {
                                          return !predicate(entry);
                                        }))),
                              fieldsState: state.fieldsState
                            }
                          };
                case "SetFormState" :
                    return {
                            TAG: "Update",
                            _0: {
                              formState: action._0,
                              values: state.values,
                              fieldsState: state.fieldsState
                            }
                          };
                case "SetValues" :
                    return {
                            TAG: "Update",
                            _0: {
                              formState: state.formState,
                              values: action._0(state.values),
                              fieldsState: state.fieldsState
                            }
                          };
                case "SetFieldValue" :
                    return {
                            TAG: "Update",
                            _0: {
                              formState: state.formState,
                              values: Config.set(state.values, action._0, action._1),
                              fieldsState: state.fieldsState
                            }
                          };
                case "RaiseSubmitFailed" :
                    return {
                            TAG: "Update",
                            _0: {
                              formState: {
                                TAG: "SubmitFailed",
                                _0: action._0
                              },
                              values: state.values,
                              fieldsState: state.fieldsState
                            }
                          };
                
              }
            }
          }), {
          formState: "Pristine",
          values: initialState,
          fieldsState: getInitialFieldsState(schema)
        });
    var send = match[1];
    var state = match[0];
    var getFieldState = function (field) {
      return Belt_Option.mapWithDefault(Belt_Array.getBy(state.fieldsState, (function (param) {
                        return Caml_obj.equal(param[0], field);
                      })), "Pristine", (function (param) {
                    return param[1];
                  }));
    };
    var getFieldError = function (field) {
      var error = getFieldState(field);
      if (typeof error !== "object" || error.TAG === "NestedErrors") {
        return ;
      } else {
        return error._0;
      }
    };
    var validateFields = function (fields) {
      var fieldsValidated = ReSchema$1.validateFields(fields, state.values, i18n, schema);
      var newFieldsState = Belt_Array.concatMany(Belt_Array.map(state.fieldsState, (function (fieldStateItem) {
                  var field = fieldStateItem[0];
                  if (!Belt_Array.some(fields, (function (fieldItem) {
                            return Caml_obj.equal(fieldItem, field);
                          }))) {
                    return [fieldStateItem];
                  }
                  var newFieldState = Belt_Array.getBy(fieldsValidated, (function (fieldStateValidated) {
                          return Caml_obj.equal(Belt_Option.map(fieldStateValidated, (function (param) {
                                            return param[0];
                                          })), field);
                        }));
                  return Belt_Option.mapWithDefault(Belt_Option.getWithDefault(newFieldState, undefined), [], (function (param) {
                                var newFieldStateValidated = param[1];
                                if (typeof newFieldStateValidated !== "object") {
                                  return [[
                                            field,
                                            "Valid"
                                          ]];
                                } else if (newFieldStateValidated.TAG === "NestedErrors") {
                                  return [[
                                            field,
                                            {
                                              TAG: "NestedErrors",
                                              _0: newFieldStateValidated._0
                                            }
                                          ]];
                                } else {
                                  return [[
                                            field,
                                            {
                                              TAG: "Error",
                                              _0: newFieldStateValidated._0
                                            }
                                          ]];
                                }
                              }));
                })));
      send({
            TAG: "SetFieldsState",
            _0: newFieldsState
          });
      return Belt_Array.keepMap(newFieldsState, (function (param) {
                    var field = param[0];
                    if (Belt_Array.some(fields, (function (fieldItem) {
                              return Caml_obj.equal(fieldItem, field);
                            }))) {
                      return param[1];
                    }
                    
                  }));
    };
    var raiseSubmitFailed = function (error) {
      send({
            TAG: "RaiseSubmitFailed",
            _0: error
          });
    };
    var getNestedFieldError = function (field, index) {
      var errors = getFieldState(field);
      if (typeof errors !== "object") {
        return ;
      }
      if (errors.TAG !== "NestedErrors") {
        return ;
      }
      var error = Belt_Array.get(errors._0, index);
      if (error !== undefined) {
        return error.error;
      }
      
    };
    return {
            state: state,
            values: state.values,
            formState: state.formState,
            fieldsState: state.fieldsState,
            isSubmitting: state.formState === "Submitting",
            isDirty: state.formState === "Dirty",
            isPristine: state.formState === "Pristine",
            getFieldState: getFieldState,
            getFieldError: getFieldError,
            getNestedFieldError: getNestedFieldError,
            handleChange: (function (field, value) {
                send({
                      TAG: "FieldChangeValue",
                      _0: field,
                      _1: value
                    });
              }),
            handleChangeWithCallback: (function (field, updateFn) {
                send({
                      TAG: "FieldChangeValueWithCallback",
                      _0: field,
                      _1: updateFn
                    });
              }),
            arrayPush: (function (field, value) {
                send({
                      TAG: "FieldArrayAdd",
                      _0: field,
                      _1: value
                    });
              }),
            arrayUpdateByIndex: (function (field, index, value) {
                send({
                      TAG: "FieldArrayUpdateByIndex",
                      _0: field,
                      _1: value,
                      _2: index
                    });
              }),
            arrayRemoveByIndex: (function (field, index) {
                send({
                      TAG: "FieldArrayRemove",
                      _0: field,
                      _1: index
                    });
              }),
            arrayRemoveBy: (function (field, predicate) {
                send({
                      TAG: "FieldArrayRemoveBy",
                      _0: field,
                      _1: predicate
                    });
              }),
            submit: (function () {
                send("TrySubmit");
              }),
            resetForm: (function () {
                send("ResetForm");
              }),
            setValues: (function (fn) {
                send({
                      TAG: "SetValues",
                      _0: fn
                    });
              }),
            setFieldValue: (function (field, value, shouldValidateOpt, param) {
                var shouldValidate = shouldValidateOpt !== undefined ? shouldValidateOpt : true;
                if (shouldValidate) {
                  return send({
                              TAG: "FieldChangeValue",
                              _0: field,
                              _1: value
                            });
                } else {
                  return send({
                              TAG: "SetFieldValue",
                              _0: field,
                              _1: value
                            });
                }
              }),
            validateField: (function (field) {
                send({
                      TAG: "ValidateField",
                      _0: field
                    });
              }),
            validateForm: (function () {
                send({
                      TAG: "ValidateForm",
                      _0: false
                    });
              }),
            validateFields: validateFields,
            raiseSubmitFailed: raiseSubmitFailed
          };
  };
  return {
          ReSchema: ReSchema$1,
          Validation: undefined,
          getInitialFieldsState: getInitialFieldsState,
          formContext: formContext,
          useFormContext: useFormContext,
          useField: useField,
          Provider: Provider,
          Field: Field,
          use: use
        };
}

var Helpers;

export {
  Helpers ,
  Make ,
}
/* react Not a pure module */
