// Generated by ReScript, PLEASE EDIT WITH CARE


function bitAnd(a, b) {
  return (a & b);
}

function bitOr(a, b) {
  return (a | b);
}

export {
  bitAnd ,
  bitOr ,
}
/* No side effect */
