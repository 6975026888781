// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as ErrorUtils from "../utils/ErrorUtils.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

var CancelledByUser = /* @__PURE__ */Caml_exceptions.create("FirebaseWebAuthn.CancelledByUser");

var InvalidFunctionResponse = /* @__PURE__ */Caml_exceptions.create("FirebaseWebAuthn.InvalidFunctionResponse");

var NoOperationNeeded = /* @__PURE__ */Caml_exceptions.create("FirebaseWebAuthn.NoOperationNeeded");

function toFirebaseWebAuthnError(exn) {
  if (exn.RE_EXN_ID !== Js_exn.$$Error) {
    return exn;
  }
  var match = ErrorUtils.code(exn._1);
  if (match === undefined) {
    return exn;
  }
  switch (match) {
    case "firebaseWebAuthn/cancelled" :
        return {
                RE_EXN_ID: CancelledByUser
              };
    case "firebaseWebAuthn/invalid" :
        return {
                RE_EXN_ID: InvalidFunctionResponse
              };
    case "firebaseWebAuthn/no-op" :
        return {
                RE_EXN_ID: NoOperationNeeded
              };
    default:
      return exn;
  }
}

export {
  CancelledByUser ,
  InvalidFunctionResponse ,
  NoOperationNeeded ,
  toFirebaseWebAuthnError ,
}
/* No side effect */
