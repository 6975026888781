// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import UnauthenticatedTemplateModuleCss from "./UnauthenticatedTemplate.module.css";

var classes = UnauthenticatedTemplateModuleCss;

function UnauthenticatedTemplate(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: "Check.beer",
                      className: Styles.utility.srOnly
                    }),
                props.isOnline !== false ? null : JsxRuntime.jsx("p", {
                        children: "Vypadá to že jsme bez internetu. Přihlášení ani registrace asi nepůjde.",
                        className: Styles.messageBar.variantDanger
                      }),
                props.children
              ],
              "aria-hidden": props.loadingOverlay,
              className: Styles.page.centered + " " + classes.root + " " + Core__Option.getOr(props.className, "")
            });
}

var make = UnauthenticatedTemplate;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
