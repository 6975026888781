// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FormattedVolume from "../FormattedVolume/FormattedVolume.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function MeterKeg(props) {
  var keg = props.keg;
  var ariaLabelledby = props.ariaLabelledby;
  var ariaLabel = props.ariaLabel;
  var volume = keg.milliliters;
  return JsxRuntime.jsx(FormattedVolume.make, {
              children: (function (formattedNumber) {
                  return JsxRuntime.jsx("meter", {
                              children: formattedNumber,
                              "aria-label": ariaLabel,
                              "aria-labelledby": ariaLabelledby,
                              title: formattedNumber,
                              low: volume / 3 | 0,
                              max: volume.toString(),
                              min: "0",
                              value: (volume - keg.consumptionsSum | 0).toString()
                            });
                }),
              milliliters: Math.max(volume - keg.consumptionsSum | 0, 0)
            });
}

var make = MeterKeg;

export {
  make ,
}
/* FormattedVolume Not a pure module */
