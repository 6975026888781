// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rxjs from "rxjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as LogUtils from "../utils/LogUtils.bs.js";
import * as Reactfire from "reactfire";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__Dict from "@rescript/core/src/Core__Dict.bs.js";
import * as Core__Null from "@rescript/core/src/Core__Null.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Float from "@rescript/core/src/Core__Float.bs.js";
import * as ObjectUtils from "../utils/ObjectUtils.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Firestore from "rxfire/firestore";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Firestore$1 from "firebase/firestore";

function kegDoc(firestore, placeId, kegId) {
  return Firestore$1.doc(firestore, "places/" + placeId + "/kegs/" + kegId);
}

function placeCollection(firestore) {
  return Firestore$1.collection(firestore, "places");
}

function personsIndexDocument(firestore, placeId) {
  return Firestore$1.doc(firestore, "places/" + placeId + "/personsIndex/1");
}

function placeDocument(firestore, placeId) {
  return Firestore$1.doc(firestore, "places/" + placeId);
}

function placePersonsCollection(firestore, placeId) {
  return Firestore$1.collection(firestore, "places/" + placeId + "/persons");
}

function placePersonDocument(firestore, placeId, personId) {
  return Firestore$1.doc(firestore, "places/" + placeId + "/persons/" + personId);
}

function placeKegsCollection(firestore, placeId) {
  return Firestore$1.collection(firestore, "places/" + placeId + "/kegs");
}

function placeKegDocument(firestore, placeId, kegId) {
  return Firestore$1.doc(firestore, "places/" + placeId + "/kegs/" + kegId);
}

function webAuthnUsersDocument(firestore, userId) {
  return Firestore$1.doc(firestore, "webAuthnUsers/" + userId);
}

var removeLastUndefined = (tuple => tuple.at(-1) === undefined ? tuple.slice(0, -1) : tuple);

function personsAllRecordToTuple(param) {
  return removeLastUndefined([
              param.name,
              param.recentActivityAt,
              param.balance,
              param.userId,
              param.preferredTap
            ]);
}

function personsAllTupleToRecord(param) {
  return {
          balance: param[2],
          name: param[0],
          preferredTap: param[4],
          recentActivityAt: param[1],
          userId: param[3]
        };
}

function isPersonActive(p) {
  return p.preferredTap !== undefined;
}

function personsIndexConverter_fromFirestore(snapshot, options) {
  var match = snapshot.data(options);
  var allWithRecord = (function (__x) {
        return Js_dict.map(personsAllTupleToRecord, __x);
      })(match.all);
  return {
          all: allWithRecord
        };
}

function personsIndexConverter_toFirestore(place, param) {
  var allWithTuple = (function (__x) {
        return Js_dict.map(personsAllRecordToTuple, __x);
      })(place.all);
  return {
          all: allWithTuple
        };
}

var personsIndexConverter = {
  fromFirestore: personsIndexConverter_fromFirestore,
  toFirestore: personsIndexConverter_toFirestore
};

function personsIndexConverted(firestore, placeId) {
  return personsIndexDocument(firestore, placeId).withConverter(personsIndexConverter);
}

function formatKegSerial(serial) {
  return "#" + serial.toString().padStart(3, "0");
}

function kegConverter_fromFirestore(snapshot, options) {
  var keg = snapshot.data(options);
  var consumptionsSum = Core__Array.reduce(Js_dict.values(keg.consumptions), 0, (function (sum, consumption) {
          return sum + consumption.milliliters | 0;
        }));
  var serialFormatted = formatKegSerial(keg.serial);
  return {
          beer: keg.beer,
          consumptions: keg.consumptions,
          consumptionsSum: consumptionsSum,
          createdAt: keg.createdAt,
          donors: keg.donors,
          depletedAt: keg.depletedAt,
          milliliters: keg.milliliters,
          price: keg.price,
          recentConsumptionAt: keg.recentConsumptionAt,
          serial: keg.serial,
          serialFormatted: serialFormatted
        };
}

function kegConverter_toFirestore(keg, param) {
  return {
          beer: keg.beer,
          consumptions: keg.consumptions,
          createdAt: keg.createdAt,
          depletedAt: keg.depletedAt,
          donors: keg.donors,
          milliliters: keg.milliliters,
          price: keg.price,
          recentConsumptionAt: keg.recentConsumptionAt,
          serial: keg.serial
        };
}

var kegConverter = {
  fromFirestore: kegConverter_fromFirestore,
  toFirestore: kegConverter_toFirestore
};

function placeKegsCollectionConverted(firestore, placeId) {
  return placeKegsCollection(firestore, placeId).withConverter(kegConverter);
}

var getUid = (data => data?.uid);

function placesByUserIdRx(firestore, userId) {
  return Firestore.collectionData(Firestore$1.query(Firestore$1.collection(firestore, "places"), Firestore$1.where("accounts." + userId, "!=", null)), { "idField": "uid" });
}

var slidingWindowInMillis = 12 * 60 * 60 * 1000;

var slidingWindowRx = Rxjs.interval(3600000).pipe(Rxjs.startWith(0), Rxjs.map(function (param, param$1) {
          var slidingWindow = new Date();
          slidingWindow.setHours(slidingWindow.getHours() - 12 | 0, 0, 0, 0);
          return Firestore$1.Timestamp.fromDate(slidingWindow);
        }), Rxjs.shareReplay(1));

function recentlyFinishedKegsRx(firestore, placeId) {
  return slidingWindowRx.pipe(Rxjs.switchMap(function (slidingWindow) {
                  return Firestore.collectionData(Firestore$1.query(placeKegsCollectionConverted(firestore, placeId), Firestore$1.where("depletedAt", ">=", slidingWindow)), { "idField": "uid" });
                }));
}

function kegFirstConsumptionTimestamp(keg) {
  return Core__Option.flatMap(Core__Array.reduce(Object.keys(keg.consumptions), undefined, (function (min, timestampStr) {
                    if (min !== undefined && timestampStr.localeCompare(min) >= 0.0) {
                      return min;
                    } else {
                      return timestampStr;
                    }
                  })), (function (timestampStr) {
                return Core__Float.fromString(timestampStr);
              }));
}

function groupKegConsumptionsByUser(targetOpt, keg) {
  var target = targetOpt !== undefined ? Caml_option.valFromOption(targetOpt) : new Map();
  Js_dict.entries(keg.consumptions).forEach(function (param) {
        var consumption = param[1];
        var timestampStr = param[0];
        var userCons_kegId = getUid(keg);
        var userCons_beer = keg.beer;
        var userCons_milliliters = consumption.milliliters;
        var userCons_createdAt = new Date(Core__Option.getExn(Core__Float.fromString(timestampStr), undefined));
        var userCons = {
          consumptionId: timestampStr,
          kegId: userCons_kegId,
          beer: userCons_beer,
          milliliters: userCons_milliliters,
          createdAt: userCons_createdAt
        };
        var consumptions = target.get(consumption.person.id);
        if (consumptions !== undefined) {
          consumptions.push(userCons);
        } else {
          target.set(consumption.person.id, [userCons]);
        }
      });
  return target;
}

function allChargedKegsRx(firestore, placeId) {
  var chargedKegsQuery = Firestore$1.query(placeKegsCollectionConverted(firestore, placeId), Firestore$1.where("depletedAt", "==", null), Firestore$1.limit(50));
  return Firestore.collectionData(chargedKegsQuery, { "idField": "uid" }).pipe(Rxjs.map(function (kegs, param) {
                  kegs.sort(function (a, b) {
                        return a.serial - b.serial | 0;
                      });
                  return kegs;
                }));
}

function usePlacePersonDocumentStatus(options, placeId, personId) {
  var firestore = Reactfire.useFirestore();
  var personRef = placePersonDocument(firestore, placeId, personId);
  return Reactfire.useFirestoreDocData(personRef, options);
}

function useMostRecentKegStatus(placeId) {
  var firestore = Reactfire.useFirestore();
  return Reactfire.useFirestoreCollectionData(Firestore$1.query(placeKegsCollection(firestore, placeId), Firestore$1.orderBy("serial", "desc"), Firestore$1.limit(1)), { "idField": "uid" });
}

async function addConsumption(firestore, consumption, kegId, personId, placeId, tapName) {
  var now = Date.now();
  var kegRef = placeKegDocument(firestore, placeId, kegId);
  var updateKegData = ObjectUtils.setIn({
        recentConsumptionAt: Firestore$1.serverTimestamp()
      }, "consumptions." + now.toString(), consumption);
  var personsIndexRef = personsIndexDocument(firestore, placeId);
  var personsIndex = (await Firestore$1.getDocFromCache(personsIndexRef)).data({});
  var personRecord = (function (__x) {
        return personsAllTupleToRecord(__x);
      })(personsIndex.all[personId]);
  var personsIndexUpdateData = {};
  personsIndexUpdateData["all." + personId] = personsAllRecordToTuple({
        balance: personRecord.balance,
        name: personRecord.name,
        preferredTap: tapName,
        recentActivityAt: Firestore$1.Timestamp.now(),
        userId: personRecord.userId
      });
  return Firestore$1.writeBatch(firestore).update(kegRef, updateKegData).update(personsIndexRef, personsIndexUpdateData).commit();
}

function $$delete(firestore, placeId, kegId) {
  return Firestore$1.deleteDoc(kegDoc(firestore, placeId, kegId));
}

function deleteConsumption(firestore, placeId, kegId, consumptionId) {
  var kegRef = kegDoc(firestore, placeId, kegId);
  var updateData = ObjectUtils.setIn({}, "consumptions." + consumptionId, Firestore$1.deleteField());
  return Firestore$1.updateDoc(kegRef, updateData);
}

function finalizeGetUpdateObjects(keg, place, personsIndex) {
  var kegId = getUid(keg);
  var nowTimestamp = Firestore$1.Timestamp.now();
  var kegUpdataObject = {
    depletedAt: nowTimestamp
  };
  var personsUpdateObjects = new Map();
  var personsIndexUpdateObject = {};
  var placeUpdateObject = {};
  var kegOnTap = Js_dict.entries(place.taps).find(function (param) {
        return Core__Option.getOr(Core__Option.map(Caml_option.null_to_opt(param[1]), (function (kegRef) {
                          return kegRef.id === kegId;
                        })), false);
      });
  if (kegOnTap !== undefined) {
    placeUpdateObject["taps." + kegOnTap[0]] = null;
  }
  var donorsEntries = Js_dict.entries(keg.donors);
  var kegDonorPrice = Core__Array.reduce(donorsEntries, 0, (function (price, param) {
          return price + param[1] | 0;
        }));
  var kegPricePerMilliliter = kegDonorPrice / keg.consumptionsSum;
  var personsTransactions = new Map();
  groupKegConsumptionsByUser(undefined, keg).forEach(function (consumptions, personId) {
        var personConsumptionSum = Core__Array.reduce(consumptions, 0, (function (sum, consumption) {
                return sum + consumption.milliliters | 0;
              }));
        var priceShare = personConsumptionSum * kegPricePerMilliliter | 0;
        var financialTransaction_amount = Math.imul(-1, priceShare);
        var financialTransaction_keg = keg.serial;
        var financialTransaction_note = null;
        var financialTransaction_person = null;
        var financialTransaction = {
          amount: financialTransaction_amount,
          createdAt: nowTimestamp,
          keg: financialTransaction_keg,
          note: financialTransaction_note,
          person: financialTransaction_person
        };
        personsTransactions.set(personId, [financialTransaction]);
      });
  Js_dict.entries(keg.donors).forEach(function (param) {
        var personId = param[0];
        var financialTransaction_amount = param[1];
        var financialTransaction_keg = keg.serial;
        var financialTransaction_note = null;
        var financialTransaction_person = null;
        var financialTransaction = {
          amount: financialTransaction_amount,
          createdAt: nowTimestamp,
          keg: financialTransaction_keg,
          note: financialTransaction_note,
          person: financialTransaction_person
        };
        var transactions = personsTransactions.get(personId);
        if (transactions !== undefined) {
          transactions.push(financialTransaction);
        } else {
          personsTransactions.set(personId, [financialTransaction]);
        }
      });
  personsTransactions.forEach(function (transactions, personId) {
        personsUpdateObjects.set(personId, {
              transactions: Caml_splice_call.spliceApply(Firestore$1.arrayUnion, [transactions])
            });
        var transactiuonsSum = Core__Array.reduce(transactions, 0, (function (sum, transaction) {
                return sum + transaction.amount | 0;
              }));
        var personsAllRecord = Core__Option.getExn(Js_dict.get(personsIndex.all, personId), undefined);
        var newPersonsAllRecord_balance = personsAllRecord.balance + transactiuonsSum | 0;
        var newPersonsAllRecord_name = personsAllRecord.name;
        var newPersonsAllRecord_preferredTap = personsAllRecord.preferredTap;
        var newPersonsAllRecord_recentActivityAt = personsAllRecord.recentActivityAt;
        var newPersonsAllRecord_userId = personsAllRecord.userId;
        var newPersonsAllRecord = {
          balance: newPersonsAllRecord_balance,
          name: newPersonsAllRecord_name,
          preferredTap: newPersonsAllRecord_preferredTap,
          recentActivityAt: newPersonsAllRecord_recentActivityAt,
          userId: newPersonsAllRecord_userId
        };
        personsIndexUpdateObject["all." + personId] = personsAllRecordToTuple(newPersonsAllRecord);
      });
  return [
          kegUpdataObject,
          personsUpdateObjects,
          placeUpdateObject,
          personsIndexUpdateObject
        ];
}

async function finalize(firestore, placeId, kegId) {
  var kegRef = kegDoc(firestore, placeId, kegId).withConverter(kegConverter);
  var keg = (await Firestore$1.getDocFromCache(kegRef)).data({});
  var placeRef = placeDocument(firestore, placeId);
  var place = (await Firestore$1.getDocFromCache(placeRef)).data({});
  var personsIndexRef = personsIndexDocument(firestore, placeId);
  var personsIndex = (await Firestore$1.getDocFromCache(personsIndexRef.withConverter(personsIndexConverter))).data({});
  var match = finalizeGetUpdateObjects(keg, place, personsIndex);
  var batch = Firestore$1.writeBatch(firestore);
  match[1].forEach(function (personUpdateObject, personId) {
        batch.update(placePersonDocument(firestore, placeId, personId), personUpdateObject);
      });
  return await batch.update(placeRef, match[2]).update(personsIndexRef, match[3]).update(kegRef, match[0]).commit();
}

var Keg = {
  addConsumption: addConsumption,
  $$delete: $$delete,
  deleteConsumption: deleteConsumption,
  finalizeGetUpdateObjects: finalizeGetUpdateObjects,
  finalize: finalize
};

async function add(firestore, personName, placeName, userId) {
  var placeDoc = Firestore$1.doc(Firestore$1.collection(firestore, "places"));
  var personDoc = Firestore$1.doc(placePersonsCollection(firestore, placeDoc.id));
  var personsIndexDoc = personsIndexDocument(firestore, placeDoc.id);
  var defaultTapName = "Pípa";
  var now = Firestore$1.Timestamp.now();
  var personTuple = personsAllRecordToTuple({
        balance: 0,
        name: personName,
        preferredTap: defaultTapName,
        recentActivityAt: now,
        userId: userId
      });
  try {
    await Firestore$1.writeBatch(firestore).set(placeDoc, {
                accounts: Object.fromEntries([[
                        userId,
                        [
                          100,
                          0
                        ]
                      ]]),
                consumptionSymbols: null,
                createdAt: now,
                currency: "CZK",
                name: placeName,
                taps: Object.fromEntries([[
                        defaultTapName,
                        null
                      ]])
              }, {}).set(personDoc, {
              createdAt: now,
              transactions: []
            }, {}).set(personsIndexDoc, {
            all: Object.fromEntries([[
                    personDoc.id,
                    personTuple
                  ]])
          }, {}).commit();
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Js_exn.$$Error) {
      var e$1 = e._1;
      LogUtils.captureException(e$1);
      throw Caml_js_exceptions.internalToOCamlException(e$1);
    }
    throw e;
  }
  return placeDoc;
}

function $$delete$1(firestore, placeId) {
  return Firestore$1.deleteDoc(placeDocument(firestore, placeId));
}

async function changePersonRole(firestore, placeId, personId, role) {
  var personsIndexRef = personsIndexDocument(firestore, placeId);
  var personsIndex = (await Firestore$1.getDocFromCache(personsIndexRef)).data({});
  var personTuple = Core__Option.getExn(Js_dict.get(personsIndex.all, personId), undefined);
  var personRecord = personsAllTupleToRecord(personTuple);
  var personUserId = Core__Option.getExn(Caml_option.null_to_opt(personRecord.userId), undefined);
  var placeRef = placeDocument(firestore, placeId);
  var place = (await Firestore$1.getDocFromCache(placeRef)).data({});
  var match = place.accounts[personUserId];
  var updateObject = {};
  updateObject["accounts." + personUserId] = [
    role,
    match[1]
  ];
  return await Firestore$1.updateDoc(placeRef, updateObject);
}

function tapAdd(firestore, placeId, tapName) {
  var placeRef = placeDocument(firestore, placeId);
  var updateObject = {};
  updateObject["taps." + tapName] = null;
  return Firestore$1.updateDoc(placeRef, updateObject);
}

function tapDelete(firestore, placeId, tapName) {
  var placeRef = placeDocument(firestore, placeId);
  var updateObject = {};
  updateObject["taps." + tapName] = Firestore$1.deleteField();
  return Firestore$1.updateDoc(placeRef, updateObject);
}

async function tapRename(firestore, placeId, currentName, newName) {
  var personsIndexRef = personsIndexConverted(firestore, placeId);
  var personsIndex = (await Firestore$1.getDocFromCache(personsIndexRef)).data({});
  var placeRef = placeDocument(firestore, placeId);
  var place = (await Firestore$1.getDocFromCache(placeRef)).data({});
  var currentValue = place.taps[currentName];
  var placeUpdateObject = {};
  var personsIndexUpdateObject = {};
  var newTaps = Object.assign({}, place.taps);
  Core__Dict.$$delete(newTaps, currentName);
  newTaps[newName] = currentValue;
  placeUpdateObject["taps"] = newTaps;
  Object.entries(personsIndex.all).forEach(function (param) {
        var person = param[1];
        var preferredTap = person.preferredTap;
        if (preferredTap !== undefined && preferredTap === currentName) {
          personsIndexUpdateObject["all." + param[0]] = personsAllRecordToTuple({
                balance: person.balance,
                name: person.name,
                preferredTap: newName,
                recentActivityAt: person.recentActivityAt,
                userId: person.userId
              });
          return ;
        }
        
      });
  return await Firestore$1.writeBatch(firestore).update(placeRef, placeUpdateObject).update(personsIndexRef, personsIndexUpdateObject).commit();
}

function tapKegOff(firestore, placeId, tapName) {
  var placeRef = placeDocument(firestore, placeId);
  var updateObject = {};
  updateObject["taps." + tapName] = null;
  return Firestore$1.updateDoc(placeRef, updateObject);
}

function tapKegOn(firestore, placeId, tapName, kegId) {
  var placeRef = placeDocument(firestore, placeId);
  var kegRef = kegDoc(firestore, placeId, kegId);
  var updateObject = {};
  updateObject["taps." + tapName] = kegRef;
  return Firestore$1.updateDoc(placeRef, updateObject);
}

function update(firestore, placeId, createdAt, name) {
  var placeDoc = placeDocument(firestore, placeId);
  return Firestore$1.updateDoc(placeDoc, {
              createdAt: createdAt,
              name: name
            });
}

var Place = {
  add: add,
  $$delete: $$delete$1,
  changePersonRole: changePersonRole,
  tapAdd: tapAdd,
  tapDelete: tapDelete,
  tapRename: tapRename,
  tapKegOff: tapKegOff,
  tapKegOn: tapKegOn,
  update: update
};

async function add$1(firestore, placeId, personName) {
  var placeSnapshot = await Firestore$1.getDocFromCache(placeDocument(firestore, placeId));
  var place = placeSnapshot.data({});
  var firstTap = Belt_Array.getExn(Object.keys(place.taps), 0);
  var now = Firestore$1.Timestamp.now();
  var personDoc = Firestore$1.doc(placePersonsCollection(firestore, placeId));
  var newPerson_transactions = [];
  var newPerson = {
    createdAt: now,
    transactions: newPerson_transactions
  };
  var newPersonsAllRecord_preferredTap = firstTap;
  var newPersonsAllRecord_userId = null;
  var newPersonsAllRecord = {
    balance: 0,
    name: personName,
    preferredTap: newPersonsAllRecord_preferredTap,
    recentActivityAt: now,
    userId: newPersonsAllRecord_userId
  };
  var updatePersonsIndexData = ObjectUtils.setIn({}, "all." + personDoc.id, personsAllRecordToTuple(newPersonsAllRecord));
  return await Firestore$1.writeBatch(firestore).set(personDoc, newPerson, {}).update(personsIndexDocument(firestore, placeId), updatePersonsIndexData).commit();
}

async function addFinancialTransaction(firestore, placeId, personId, counterPartyId, transaction) {
  var personsIndexRef = personsIndexDocument(firestore, placeId);
  var personsIndex = (await Firestore$1.getDocFromCache(personsIndexRef)).data({});
  var personsAllTuple = Core__Option.getExn(Js_dict.get(personsIndex.all, personId), undefined);
  var personsAllRecord = personsAllTupleToRecord(personsAllTuple);
  var newPersonsAllRecord_balance = personsAllRecord.balance + transaction.amount | 0;
  var newPersonsAllRecord_name = personsAllRecord.name;
  var newPersonsAllRecord_preferredTap = personsAllRecord.preferredTap;
  var newPersonsAllRecord_recentActivityAt = personsAllRecord.recentActivityAt;
  var newPersonsAllRecord_userId = personsAllRecord.userId;
  var newPersonsAllRecord = {
    balance: newPersonsAllRecord_balance,
    name: newPersonsAllRecord_name,
    preferredTap: newPersonsAllRecord_preferredTap,
    recentActivityAt: newPersonsAllRecord_recentActivityAt,
    userId: newPersonsAllRecord_userId
  };
  var updatePersonsIndexData = {};
  updatePersonsIndexData["all." + personId] = personsAllRecordToTuple(newPersonsAllRecord);
  var counterPartyTuple = Core__Option.getExn(Js_dict.get(personsIndex.all, counterPartyId), undefined);
  var counterPartyRecord = personsAllTupleToRecord(counterPartyTuple);
  var newCounterPartyRecord_balance = counterPartyRecord.balance - transaction.amount | 0;
  var newCounterPartyRecord_name = counterPartyRecord.name;
  var newCounterPartyRecord_preferredTap = counterPartyRecord.preferredTap;
  var newCounterPartyRecord_recentActivityAt = counterPartyRecord.recentActivityAt;
  var newCounterPartyRecord_userId = counterPartyRecord.userId;
  var newCounterPartyRecord = {
    balance: newCounterPartyRecord_balance,
    name: newCounterPartyRecord_name,
    preferredTap: newCounterPartyRecord_preferredTap,
    recentActivityAt: newCounterPartyRecord_recentActivityAt,
    userId: newCounterPartyRecord_userId
  };
  updatePersonsIndexData["all." + counterPartyId] = personsAllRecordToTuple(newCounterPartyRecord);
  var counterPartyTransaction_amount = Math.imul(-1, transaction.amount);
  var counterPartyTransaction_createdAt = transaction.createdAt;
  var counterPartyTransaction_keg = null;
  var counterPartyTransaction_note = transaction.note;
  var counterPartyTransaction = {
    amount: counterPartyTransaction_amount,
    createdAt: counterPartyTransaction_createdAt,
    keg: counterPartyTransaction_keg,
    note: counterPartyTransaction_note,
    person: personId
  };
  return await Firestore$1.writeBatch(firestore).update(placePersonDocument(firestore, placeId, personId), {
                    transactions: Firestore$1.arrayUnion(transaction)
                  }).update(placePersonDocument(firestore, placeId, counterPartyId), {
                  transactions: Firestore$1.arrayUnion(counterPartyTransaction)
                }).update(personsIndexRef, updatePersonsIndexData).commit();
}

async function $$delete$2(firestore, placeId, personId) {
  var personRef = placePersonDocument(firestore, placeId, personId);
  var personsIndexRef = personsIndexDocument(firestore, placeId);
  var personsIndex = (await Firestore$1.getDocFromCache(personsIndexRef)).data({});
  var personTuple = Core__Option.getExn(Js_dict.get(personsIndex.all, personId), undefined);
  var personRecord = personsAllTupleToRecord(personTuple);
  var updatePersonIndexData = {};
  updatePersonIndexData["all." + personId] = Firestore$1.deleteField();
  var writeBatch = Firestore$1.writeBatch(firestore).delete(personRef).update(personsIndexRef, updatePersonIndexData);
  var userId = personRecord.userId;
  if (userId !== null) {
    var placeRef = placeDocument(firestore, placeId);
    var updatePlaceData = {};
    updatePlaceData["users." + userId] = Firestore$1.deleteField();
    writeBatch.update(placeRef, updatePlaceData);
  }
  return await writeBatch.commit();
}

var Person = {
  add: add$1,
  addFinancialTransaction: addFinancialTransaction,
  $$delete: $$delete$2
};

function allEntriesSortedRx(firestore, placeId) {
  var personsIndexRef = personsIndexConverted(firestore, placeId);
  return Firestore.docData(personsIndexRef, { "idField": "uid" }).pipe(Rxjs.map(function (maybePersonsIndex, param) {
                  if (maybePersonsIndex === undefined) {
                    return [];
                  }
                  var personsAllEntries = Js_dict.entries(maybePersonsIndex.all);
                  personsAllEntries.sort(function (param, param$1) {
                        return param[1].name.localeCompare(param$1[1].name);
                      });
                  return personsAllEntries;
                }));
}

function update$1(firestore, placeId, personsChanges) {
  var personsIndexRef = personsIndexDocument(firestore, placeId);
  var updateObject = {};
  personsChanges.forEach(function (param) {
        updateObject["all." + param[0]] = personsAllRecordToTuple(param[1]);
      });
  return Firestore$1.updateDoc(personsIndexRef, updateObject);
}

var PersonsIndex = {
  allEntriesSortedRx: allEntriesSortedRx,
  update: update$1
};

function collection(firestore) {
  return Firestore$1.collection(firestore, "shareLinks");
}

function $$document(firestore, linkId) {
  return Firestore$1.doc(firestore, "shareLinks/" + linkId);
}

function $$delete$3(firestore, linkId) {
  return Firestore$1.deleteDoc($$document(firestore, linkId));
}

async function upsert(firestore, placeId, personId, role) {
  if (role === 100) {
    Js_exn.raiseError("Changing owner is currently not supported");
  }
  var shareLinkCollection = Firestore$1.collection(firestore, "shareLinks");
  var shareLinkQuery = Firestore$1.query(shareLinkCollection, Firestore$1.where("person", "==", personId), Firestore$1.where("place", "==", placeId), Firestore$1.limit(1));
  var shareLinks = await Firestore$1.getDocs(shareLinkQuery);
  var match = shareLinks.docs;
  if (match.length !== 1) {
    return (await Firestore$1.addDoc(shareLinkCollection, {
                  createdAt: Firestore$1.Timestamp.now(),
                  person: personId,
                  place: placeId,
                  role: role
                })).id;
  }
  var shareLinkSnapshot = match[0];
  await Firestore$1.updateDoc(shareLinkSnapshot.ref, {
        createdAt: Firestore$1.Timestamp.now(),
        role: role
      });
  return shareLinkSnapshot.id;
}

function acceptInvitation(firestore, linkId, userId) {
  var shareLinkDocument = $$document(firestore, linkId);
  return Firestore$1.runTransaction(firestore, (async function (transaction) {
                var shareLinkSnapshot = await transaction.get(shareLinkDocument);
                if (!shareLinkSnapshot.exists()) {
                  Js_exn.raiseError("Share link does not exist");
                }
                var match = shareLinkSnapshot.data({});
                var place = match.place;
                var person = match.person;
                var placeIndexDocument = personsIndexConverted(firestore, place);
                var placeIndex = (await transaction.get(placeIndexDocument)).data({});
                var userAlreadyInPlace = Object.values(placeIndex.all).some(function (p) {
                      return Core__Null.mapOr(p.userId, false, (function (id) {
                                    return id === userId;
                                  }));
                    });
                if (userAlreadyInPlace) {
                  Js_exn.raiseError("User already in place");
                }
                var personRecord = Core__Option.getExn(Js_dict.get(placeIndex.all, person), undefined);
                if (personRecord.userId !== null) {
                  Js_exn.raiseError("Person already has a connected user account");
                }
                var newPersonRecord_balance = personRecord.balance;
                var newPersonRecord_name = personRecord.name;
                var newPersonRecord_preferredTap = personRecord.preferredTap;
                var newPersonRecord_recentActivityAt = personRecord.recentActivityAt;
                var newPersonRecord = {
                  balance: newPersonRecord_balance,
                  name: newPersonRecord_name,
                  preferredTap: newPersonRecord_preferredTap,
                  recentActivityAt: newPersonRecord_recentActivityAt,
                  userId: userId
                };
                var personsIndexUpdateData = {};
                personsIndexUpdateData["all." + person] = personsAllRecordToTuple(newPersonRecord);
                var placeUpdateData = {};
                placeUpdateData["accounts." + userId] = [
                  match.role,
                  0
                ];
                transaction.update(placeDocument(firestore, place), placeUpdateData);
                transaction.update(placeIndexDocument, personsIndexUpdateData);
                transaction.delete(shareLinkDocument);
              }));
}

var ShareLink = {
  collection: collection,
  $$document: $$document,
  $$delete: $$delete$3,
  upsert: upsert,
  acceptInvitation: acceptInvitation
};

var slidingWindowInHours = 12;

export {
  kegDoc ,
  placeCollection ,
  personsIndexDocument ,
  placeDocument ,
  placePersonsCollection ,
  placePersonDocument ,
  placeKegsCollection ,
  placeKegDocument ,
  webAuthnUsersDocument ,
  removeLastUndefined ,
  personsAllRecordToTuple ,
  personsAllTupleToRecord ,
  isPersonActive ,
  personsIndexConverter ,
  personsIndexConverted ,
  formatKegSerial ,
  kegConverter ,
  placeKegsCollectionConverted ,
  getUid ,
  placesByUserIdRx ,
  slidingWindowInHours ,
  slidingWindowInMillis ,
  slidingWindowRx ,
  recentlyFinishedKegsRx ,
  kegFirstConsumptionTimestamp ,
  groupKegConsumptionsByUser ,
  allChargedKegsRx ,
  usePlacePersonDocumentStatus ,
  useMostRecentKegStatus ,
  Keg ,
  Place ,
  Person ,
  PersonsIndex ,
  ShareLink ,
}
/* slidingWindowRx Not a pure module */
