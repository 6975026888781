// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import InputWrapperModuleCss from "./InputWrapper.module.css";

var classes = InputWrapperModuleCss;

function InputWrapper$ErrorMessage(props) {
  return JsxRuntime.jsx("p", {
              children: props.message,
              className: classes.errorMessage,
              id: props.id,
              role: "alert"
            });
}

var ErrorMessage = {
  make: InputWrapper$ErrorMessage
};

function InputWrapper(props) {
  var unitSlot = props.unitSlot;
  var inputName = props.inputName;
  var inputError = props.inputError;
  var unitId = unitSlot !== undefined ? inputName + "_unit" : "";
  var hasError = inputError !== undefined;
  var errorId = hasError ? inputName + "_error" : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("label", {
                      children: props.labelSlot,
                      htmlFor: inputName
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                React.cloneElement(props.inputSlot, {
                                      "aria-describedby": errorId + " " + unitId,
                                      "aria-invalid": hasError ? "true" : "false",
                                      id: inputName,
                                      name: inputName
                                    }),
                                unitSlot !== undefined ? JsxRuntime.jsx("p", {
                                        children: Caml_option.valFromOption(unitSlot),
                                        className: classes.description,
                                        id: unitId
                                      }) : null
                              ]
                            }),
                        inputError !== undefined ? JsxRuntime.jsx(InputWrapper$ErrorMessage, {
                                id: errorId,
                                message: inputError
                              }) : null
                      ]
                    })
              ],
              className: "inputWrapper " + Core__Option.getOr(props.className, "")
            });
}

var make = InputWrapper;

export {
  classes ,
  ErrorMessage ,
  make ,
}
/* classes Not a pure module */
