// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__List from "@rescript/core/src/Core__List.bs.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function joinPath(path) {
  return "/" + Core__List.toArray(path).join("/");
}

function resolveRelativePath(pathname) {
  if (!pathname.startsWith(".")) {
    return pathname;
  }
  var pathnameSegments = Core__Array.reduceRight(pathname.split("/"), {
        subtract: 0,
        segments: /* [] */0
      }, (function (param, segment) {
          var segments = param.segments;
          var subtract = param.subtract;
          switch (segment) {
            case "" :
            case "." :
                return {
                        subtract: subtract,
                        segments: segments
                      };
            case ".." :
                return {
                        subtract: subtract + 1 | 0,
                        segments: segments
                      };
            default:
              return {
                      subtract: subtract,
                      segments: Core__List.add(segments, segment)
                    };
          }
        }));
  var currentPath = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path;
  var pathPrefix = Core__List.take(currentPath, Core__List.length(currentPath) - pathnameSegments.subtract | 0);
  var newPath = pathPrefix !== undefined ? Core__List.concat(pathPrefix, pathnameSegments.segments) : pathnameSegments.segments;
  return joinPath(newPath);
}

function handleLinkClick(handler) {
  return function ($$event) {
    if ($$event.isDefaultPrevented() || $$event.button !== 0 || $$event.metaKey || $$event.altKey || $$event.ctrlKey || $$event.shiftKey) {
      return ;
    } else {
      $$event.preventDefault();
      return handler();
    }
  };
}

function createLinkClickHandler(replaceOpt, pathname) {
  var replace = replaceOpt !== undefined ? replaceOpt : false;
  return handleLinkClick(function () {
              if (replace) {
                return RescriptReactRouter.replace(pathname);
              } else {
                return RescriptReactRouter.push(pathname);
              }
            });
}

function createAnchorProps(replaceOpt, pathname) {
  var replace = replaceOpt !== undefined ? replaceOpt : false;
  var resolvedPath = resolveRelativePath(pathname);
  return {
          href: resolvedPath,
          onClick: createLinkClickHandler(replace, resolvedPath)
        };
}

function createShareLink(shareLinkId) {
  var origin = window.location.origin;
  return origin + "/s/" + shareLinkId;
}

var fromHomepageHash = "from_homepage";

function isFromHomepage(url) {
  return url.hash === fromHomepageHash;
}

export {
  joinPath ,
  resolveRelativePath ,
  handleLinkClick ,
  createLinkClickHandler ,
  createAnchorProps ,
  createShareLink ,
  fromHomepageHash ,
  isFromHomepage ,
}
/* RescriptReactRouter Not a pure module */
