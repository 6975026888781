// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as JsxRuntime from "react/jsx-runtime";

function FormattedPercent(props) {
  return React.cloneElement(JsxRuntime.jsx(ReactIntl.FormattedNumber, {
                  value: props.value,
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1
                }), {
              style: "unit",
              unit: "percent"
            });
}

var make = FormattedPercent;

export {
  make ,
}
/* react Not a pure module */
