// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import PersonAddPlaceModuleCss from "./PersonAddPlace.module.css";

var classes = PersonAddPlaceModuleCss;

function get(state, field) {
  return state.name;
}

function set(_state, field, value) {
  return {
          name: value
        };
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function PersonAddPlace(props) {
  var onSubmit = props.onSubmit;
  var onMoveToActive = props.onMoveToActive;
  var existingInactive = props.existingInactive;
  var existingActive = props.existingActive;
  var form = Form.use({
        name: ""
      }, Validators$1.schema([
            Validators$1.required("Name"),
            Validators$1.notIn(existingActive, "Takové jméno již evidujeme", "Name"),
            Validators$1.notIn(existingInactive, "Takové jméno již evidujeme", "Name")
          ]), (function (param) {
          onSubmit(param.state.values);
        }), undefined, undefined, "OnDemand", undefined);
  var datalist = React.useMemo((function () {
          return JsxRuntime.jsx("datalist", {
                      children: existingInactive.map(function (name) {
                            return JsxRuntime.jsx("option", {
                                        value: name
                                      }, name);
                          }),
                      id: "inactiveNames"
                    });
        }), [
        existingActive,
        existingInactive
      ]);
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba";
  }
  return JsxRuntime.jsx(DialogForm.make, {
              children: JsxRuntime.jsxs(Form.Provider.make, {
                    value: form,
                    children: [
                      JsxRuntime.jsx("form", {
                            children: JsxRuntime.jsx("fieldset", {
                                  children: JsxRuntime.jsx(Form.Field.make, {
                                        field: "Name",
                                        render: (function (field) {
                                            return JsxRuntime.jsx(InputWrapper.make, {
                                                        inputError: field.error,
                                                        inputName: "name",
                                                        inputSlot: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                              children: [
                                                                JsxRuntime.jsx("input", {
                                                                      list: "inactiveNames",
                                                                      type: "text",
                                                                      value: field.value,
                                                                      onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                    }),
                                                                datalist
                                                              ]
                                                            }),
                                                        labelSlot: "Jméno"
                                                      });
                                          })
                                      }),
                                  className: "reset"
                                }),
                            id: "addPerson",
                            onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                          }),
                      JsxRuntime.jsx(Form.Field.make, {
                            field: "Name",
                            render: (function (field) {
                                var name = field.value;
                                if (existingInactive.includes(name)) {
                                  return JsxRuntime.jsxs("p", {
                                              children: [
                                                "Toto jméno evidujeme u osob v nepřítomnosti, můžete ",
                                                JsxRuntime.jsx("button", {
                                                      children: "přenést tuto osobu do pivního zápisníku",
                                                      className: Styles.link.base,
                                                      type: "button",
                                                      onClick: (function (param) {
                                                          onMoveToActive(name);
                                                        })
                                                    }),
                                                "."
                                              ]
                                            });
                                } else {
                                  return null;
                                }
                              })
                          }),
                      tmp
                    ]
                  }),
              className: classes.root,
              formId: "addPerson",
              heading: "Nový host",
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = PersonAddPlace;

export {
  classes ,
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* classes Not a pure module */
