// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as HtmlEntities from "../../utils/HtmlEntities.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as FirebaseError from "../../backend/FirebaseError.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as UnauthenticatedTemplate from "./UnauthenticatedTemplate.bs.js";

function get(state, field) {
  switch (field) {
    case "Email" :
        return state.email;
    case "Password" :
        return state.password;
    case "PasswordConfirmation" :
        return state.passwordConfirmation;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case "Email" :
        return {
                email: value,
                password: state.password,
                passwordConfirmation: state.passwordConfirmation
              };
    case "Password" :
        return {
                email: state.email,
                password: value,
                passwordConfirmation: state.passwordConfirmation
              };
    case "PasswordConfirmation" :
        return {
                email: state.email,
                password: state.password,
                passwordConfirmation: value
              };
    
  }
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function SignUpForm(props) {
  var onGoBack = props.onGoBack;
  var onSubmit = props.onSubmit;
  var form = Form.use({
        email: "",
        password: "",
        passwordConfirmation: ""
      }, Validators$1.schema([
            Validators$1.required("Email"),
            Validators$1.email("Email"),
            Validators$1.required("Password"),
            Validators$1.password("Password"),
            Validators$1.matchField("Password", "Hesla se neshodují", "PasswordConfirmation")
          ]), (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var match = param.state.values;
          Core__Promise.$$catch(onSubmit({
                    email: match.email,
                    password: match.password
                  }), (function (error) {
                  var match = FirebaseError.toFirebaseError(error);
                  var errorMessage;
                  if (match.RE_EXN_ID === FirebaseError.EmailExists) {
                    errorMessage = "Tento e" + HtmlEntities.nbhp + "mail už je zaregistrovaný.";
                  } else {
                    var exn = Core__Option.getExn(Caml_js_exceptions.as_js_exn(error), undefined);
                    LogUtils.captureException(exn);
                    var msg = exn.message;
                    errorMessage = msg !== undefined ? "Chyba: " + msg : "Neznámá chyba";
                  }
                  raiseSubmitFailed(errorMessage);
                  return Promise.resolve();
                }));
        }), undefined, undefined, "OnDemand", undefined);
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba";
  }
  return JsxRuntime.jsxs(UnauthenticatedTemplate.make, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Registrace"
                    }),
                JsxRuntime.jsx(Form.Provider.make, {
                      value: form,
                      children: JsxRuntime.jsxs("form", {
                            children: [
                              JsxRuntime.jsxs("fieldset", {
                                    children: [
                                      JsxRuntime.jsx(Form.Field.make, {
                                            field: "Email",
                                            render: (function (field) {
                                                return JsxRuntime.jsx(InputWrapper.make, {
                                                            inputError: field.error,
                                                            inputName: "email",
                                                            inputSlot: JsxRuntime.jsx("input", {
                                                                  autoComplete: "username email",
                                                                  type: "email",
                                                                  value: field.value,
                                                                  onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                }),
                                                            labelSlot: "E" + HtmlEntities.nbhp + "mail"
                                                          });
                                              })
                                          }),
                                      JsxRuntime.jsx(Form.Field.make, {
                                            field: "Password",
                                            render: (function (field) {
                                                return JsxRuntime.jsx(InputWrapper.make, {
                                                            inputError: field.error,
                                                            inputName: "password",
                                                            inputSlot: JsxRuntime.jsx("input", {
                                                                  autoComplete: "new-password",
                                                                  type: "password",
                                                                  value: field.value,
                                                                  onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                }),
                                                            labelSlot: "Heslo"
                                                          });
                                              })
                                          }),
                                      JsxRuntime.jsx(Form.Field.make, {
                                            field: "PasswordConfirmation",
                                            render: (function (field) {
                                                return JsxRuntime.jsx(InputWrapper.make, {
                                                            inputError: field.error,
                                                            inputName: "passwordConfirmation",
                                                            inputSlot: JsxRuntime.jsx("input", {
                                                                  autoComplete: "new-password",
                                                                  type: "password",
                                                                  value: field.value,
                                                                  onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                }),
                                                            labelSlot: "Heslo znovu"
                                                          });
                                              })
                                          })
                                    ],
                                    className: "reset " + Styles.fieldset.grid
                                  }),
                              tmp,
                              JsxRuntime.jsx("button", {
                                    children: "Zaregistrovat se",
                                    className: Styles.button.base,
                                    type: "submit"
                                  })
                            ],
                            className: Styles.stack.base,
                            onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                          })
                    }),
                JsxRuntime.jsxs("p", {
                      children: [
                        "Jste tu omylem? ",
                        JsxRuntime.jsx("button", {
                              children: "Zpět na přihlášení.",
                              className: Styles.link.base,
                              type: "button",
                              onClick: (function (param) {
                                  onGoBack();
                                })
                            })
                      ]
                    })
              ],
              isOnline: props.isOnline
            });
}

var make = SignUpForm;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
