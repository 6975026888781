// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var keyThrustDevice = "thrustDevice";

function getThrustDevice() {
  return Caml_option.null_to_opt(localStorage.getItem(keyThrustDevice));
}

function useLocalStorage(key) {
  var match = React.useState(function () {
        return Caml_option.null_to_opt(localStorage.getItem(key));
      });
  var setInternalValue = match[1];
  var setValue = function (maybeValue) {
    setInternalValue(function (param) {
          return maybeValue;
        });
    if (maybeValue !== undefined) {
      localStorage.setItem(key, maybeValue);
    } else {
      localStorage.removeItem(key);
    }
  };
  React.useEffect((function () {
          var listener = function (param) {
            setInternalValue(function (param) {
                  return Caml_option.null_to_opt(localStorage.getItem(key));
                });
          };
          window.addEventListener("storage", listener);
          return (function () {
                    window.removeEventListener("storage", listener);
                  });
        }), []);
  return [
          match[0],
          setValue
        ];
}

var keyWebAuthn = "webAuthn";

export {
  keyThrustDevice ,
  keyWebAuthn ,
  getThrustDevice ,
  useLocalStorage ,
}
/* react Not a pure module */
