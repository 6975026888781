// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as React from "react";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/src/Core__Float.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as HtmlEntities from "../../utils/HtmlEntities.bs.js";
import * as DialogCycling from "../../components/DialogCycling/DialogCycling.bs.js";
import * as FormattedVolume from "../../components/FormattedVolume/FormattedVolume.bs.js";
import * as FormattedPercent from "../../components/FormattedPercent/FormattedPercent.bs.js";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as FormattedDateTime from "../../components/FormattedDataTime/FormattedDateTime.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DialogConfirmation from "../../components/DialogConfirmation/DialogConfirmation.bs.js";
import KegDetailModuleCss from "./KegDetail.module.css";
import * as FormattedDateTimeShort from "../../components/FormattedDataTime/FormattedDateTimeShort.bs.js";
import TableConsumptionsModuleCss from "../../components/TableConsumptions/TableConsumptions.module.css";

var classes = KegDetailModuleCss;

var tableConsumptionsClasses = TableConsumptionsModuleCss;

function KegDetail(props) {
  var onFinalizeKeg = props.onFinalizeKeg;
  var onDismiss = props.onDismiss;
  var onDeleteKeg = props.onDeleteKeg;
  var onDeleteConsumption = props.onDeleteConsumption;
  var personsAllById = props.personsAllById;
  var keg = props.keg;
  var formatConsumption = props.formatConsumption;
  var consumptionsByTimestampDesc = React.useMemo((function () {
          var consumptionEntries = Js_dict.entries(keg.consumptions);
          consumptionEntries.sort(function (a, b) {
                return b[0].localeCompare(a[0]);
              });
          return consumptionEntries;
        }), [keg.consumptions]);
  var firstConsumption = Db.kegFirstConsumptionTimestamp(keg);
  var priceLargeBeer = keg.price / keg.milliliters * 500.0 | 0;
  var effectivity = keg.consumptionsSum / keg.milliliters;
  var kegId = Db.getUid(keg);
  var kegName = keg.serialFormatted + " " + keg.beer;
  var maybeTapName = Core__Option.map(Js_dict.entries(props.place.taps).find(function (param) {
            return Core__Option.getOr(Core__Option.map(Caml_option.null_to_opt(param[1]), (function (kegRef) {
                              return kegRef.id === kegId;
                            })), false);
          }), (function (param) {
          return param[0];
        }));
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDialog = match[1];
  var depletedAt = keg.depletedAt;
  var tmp;
  switch (match[0]) {
    case "Hidden" :
        tmp = null;
        break;
    case "ConfirmDelete" :
        tmp = JsxRuntime.jsx(DialogConfirmation.make, {
              children: JsxRuntime.jsxs("p", {
                    children: [
                      "Chystáte se odstranit sud ",
                      JsxRuntime.jsx("b", {
                            children: kegName
                          }),
                      " z aplikace. Chcete pokračovat?"
                    ]
                  }),
              className: DialogConfirmation.classes.deleteConfirmation,
              heading: "Odstranit sud ?",
              onConfirm: (function () {
                  onDismiss();
                  onDeleteKeg();
                }),
              onDismiss: (function () {
                  setDialog(function (param) {
                        return "Hidden";
                      });
                }),
              visible: true
            });
        break;
    case "ConfirmFinalize" :
        tmp = JsxRuntime.jsxs(DialogConfirmation.make, {
              children: [
                JsxRuntime.jsxs("dl", {
                      children: [
                        JsxRuntime.jsx("dt", {
                              children: "Název sudu"
                            }),
                        JsxRuntime.jsx("dd", {
                              children: kegName
                            }),
                        JsxRuntime.jsx("dt", {
                              children: "Celkem vytočeno"
                            }),
                        JsxRuntime.jsxs("dd", {
                              children: [
                                JsxRuntime.jsx(FormattedVolume.make, {
                                      milliliters: keg.consumptionsSum
                                    }),
                                " z ",
                                JsxRuntime.jsx(FormattedVolume.make, {
                                      milliliters: keg.milliliters
                                    }),
                                " (efektivita ",
                                JsxRuntime.jsx(FormattedPercent.make, {
                                      value: effectivity * 100.0
                                    }),
                                ")"
                              ]
                            }),
                        JsxRuntime.jsx("dt", {
                              children: "Výsledná cena velkého piva" + HtmlEntities.nbsp + "*"
                            }),
                        JsxRuntime.jsx("dd", {
                              children: JsxRuntime.jsx(FormattedCurrency.make, {
                                    value: priceLargeBeer / effectivity | 0
                                  })
                            })
                      ],
                      className: Styles.descriptionList.hyphen
                    }),
                JsxRuntime.jsx("p", {
                      children: "* Mezi konzumenty se rozpočítává cena sudu, výslednou cenu velkého piva tak ovlivňuje\n          efektivita výtoče."
                    }),
                effectivity > 0.7 ? JsxRuntime.jsx("p", {
                        children: "Rozúčtování je nevratná operace. Ujistěte se, že máte správny sud.",
                        className: Styles.messageBar.base,
                        role: "alert"
                      }) : JsxRuntime.jsxs("p", {
                        children: [
                          JsxRuntime.jsx("b", {
                                children: "Efektivita výtoče je velmi nízká."
                              }),
                          " Rozúčtování je nevratná operace. Ujistěte se, že máte správny sud."
                        ],
                        className: Styles.messageBar.variantDanger,
                        role: "alert"
                      })
              ],
              className: DialogConfirmation.classes.deleteConfirmation + " " + classes.dialogCloseKeg,
              heading: "Chystáte se rozúčtovat sud",
              onConfirm: (function () {
                  onDismiss();
                  onFinalizeKeg();
                }),
              onDismiss: (function () {
                  setDialog(function (param) {
                        return "Hidden";
                      });
                }),
              visible: true
            });
        break;
    
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(DialogCycling.make, {
                      children: [
                        JsxRuntime.jsxs("dl", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("dt", {
                                              children: "objem"
                                            }),
                                        JsxRuntime.jsx("dd", {
                                              children: JsxRuntime.jsx(FormattedVolume.make, {
                                                    milliliters: keg.milliliters
                                                  })
                                            })
                                      ]
                                    }),
                                keg.depletedAt !== null || keg.consumptionsSum === 0 ? null : JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("dt", {
                                                children: "zbýbá"
                                              }),
                                          JsxRuntime.jsx("dd", {
                                                children: JsxRuntime.jsx(FormattedVolume.make, {
                                                      milliliters: keg.milliliters - keg.consumptionsSum | 0
                                                    })
                                              })
                                        ]
                                      }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("dt", {
                                              children: "naskladněno dne"
                                            }),
                                        JsxRuntime.jsx("dd", {
                                              children: JsxRuntime.jsx(FormattedDateTime.make, {
                                                    value: keg.createdAt.toDate()
                                                  })
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("dt", {
                                              children: "za"
                                            }),
                                        JsxRuntime.jsx("dd", {
                                              children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                    value: keg.price
                                                  })
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("dt", {
                                              children: "t.j. velké pivo za"
                                            }),
                                        JsxRuntime.jsx("dd", {
                                              children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                    value: priceLargeBeer
                                                  })
                                            })
                                      ]
                                    }),
                                firstConsumption !== undefined ? JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("dt", {
                                                children: "první výtoč"
                                              }),
                                          JsxRuntime.jsx("dd", {
                                                children: JsxRuntime.jsx(FormattedDateTime.make, {
                                                      value: new Date(firstConsumption)
                                                    })
                                              })
                                        ]
                                      }) : null,
                                depletedAt !== null ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("dt", {
                                                        children: "dopito"
                                                      }),
                                                  JsxRuntime.jsx("dd", {
                                                        children: JsxRuntime.jsx(FormattedDateTime.make, {
                                                              value: depletedAt.toDate()
                                                            })
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("dt", {
                                                        children: "ze sudu se vytočilo"
                                                      }),
                                                  JsxRuntime.jsx("dd", {
                                                        children: JsxRuntime.jsx(FormattedVolume.make, {
                                                              milliliters: keg.consumptionsSum
                                                            })
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("dt", {
                                                        children: "efektivita"
                                                      }),
                                                  JsxRuntime.jsx("dd", {
                                                        children: JsxRuntime.jsx(FormattedPercent.make, {
                                                              value: effectivity * 100.0
                                                            })
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("dt", {
                                                        children: "výsledná cena za velké pivo"
                                                      }),
                                                  JsxRuntime.jsx("dd", {
                                                        children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                              value: priceLargeBeer / effectivity | 0
                                                            })
                                                      })
                                                ]
                                              })
                                        ]
                                      }) : null
                              ],
                              className: Styles.descriptionList.inline
                            }),
                        maybeTapName !== undefined ? JsxRuntime.jsxs("p", {
                                children: [
                                  "Sud je naražen na pípu ",
                                  JsxRuntime.jsx("b", {
                                        children: maybeTapName
                                      })
                                ],
                                className: Styles.messageBar.base
                              }) : null,
                        keg.depletedAt !== null || !props.isUserAuthorized(80) || consumptionsByTimestampDesc.length === 0 ? null : JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("button", {
                                      children: "Odepsat ze skladu a rozúčtovat",
                                      className: Styles.button.variantDanger,
                                      disabled: consumptionsByTimestampDesc.length === 0,
                                      type: "button",
                                      onClick: (function (param) {
                                          setDialog(function (param) {
                                                return "ConfirmFinalize";
                                              });
                                        })
                                    }),
                                className: classes.actions
                              }),
                        consumptionsByTimestampDesc.length !== 0 ? JsxRuntime.jsxs("table", {
                                children: [
                                  JsxRuntime.jsx("caption", {
                                        children: "Vytočená piva"
                                      }),
                                  JsxRuntime.jsx("thead", {
                                        children: JsxRuntime.jsxs("tr", {
                                              children: [
                                                JsxRuntime.jsx("th", {
                                                      children: "Jméno",
                                                      scope: "col"
                                                    }),
                                                JsxRuntime.jsx("th", {
                                                      children: "Kdy",
                                                      scope: "col"
                                                    }),
                                                JsxRuntime.jsx("th", {
                                                      children: "Objem",
                                                      scope: "col"
                                                    }),
                                                JsxRuntime.jsx("th", {
                                                      children: JsxRuntime.jsx("span", {
                                                            children: "Akce",
                                                            className: Styles.utility.srOnly
                                                          }),
                                                      scope: "col"
                                                    })
                                              ]
                                            })
                                      }),
                                  JsxRuntime.jsx("tbody", {
                                        children: consumptionsByTimestampDesc.map(function (param) {
                                              var consumption = param[1];
                                              var timestampStr = param[0];
                                              var person = personsAllById[consumption.person.id];
                                              var createdData = new Date(Core__Option.getExn(Core__Float.fromString(timestampStr), undefined));
                                              return JsxRuntime.jsxs("tr", {
                                                          children: [
                                                            JsxRuntime.jsx("th", {
                                                                  children: person.name,
                                                                  scope: "row"
                                                                }),
                                                            JsxRuntime.jsx("td", {
                                                                  children: JsxRuntime.jsx(FormattedDateTimeShort.make, {
                                                                        value: createdData
                                                                      })
                                                                }),
                                                            JsxRuntime.jsx("td", {
                                                                  children: formatConsumption(consumption.milliliters)
                                                                }),
                                                            JsxRuntime.jsx("td", {
                                                                  children: keg.depletedAt !== null ? null : JsxRuntime.jsx("button", {
                                                                          children: "🗑️ Smáznout",
                                                                          className: Styles.button.sizeExtraSmall + " " + tableConsumptionsClasses.deleteButton,
                                                                          type: "button",
                                                                          onClick: (function (param) {
                                                                              onDeleteConsumption(timestampStr);
                                                                            })
                                                                        })
                                                                })
                                                          ]
                                                        }, timestampStr);
                                            })
                                      })
                                ],
                                className: Styles.table.inDialog + " " + tableConsumptionsClasses.table
                              }) : JsxRuntime.jsxs("p", {
                                children: [
                                  "Ze sudu zatím neevidujeme čepování.",
                                  maybeTapName !== undefined ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                          children: [
                                            " Pokud jste sud přidali omylem můžete ho ",
                                            JsxRuntime.jsx("button", {
                                                  children: "odebrat z aplikace",
                                                  className: Styles.link.base,
                                                  type: "button",
                                                  onClick: (function (param) {
                                                      setDialog(function (param) {
                                                            return "ConfirmDelete";
                                                          });
                                                    })
                                                }),
                                            "."
                                          ]
                                        })
                                ]
                              })
                      ],
                      className: classes.root,
                      hasNext: props.hasNext,
                      hasPrevious: props.hasPrevious,
                      header: kegName,
                      onDismiss: onDismiss,
                      onNext: props.onNextKeg,
                      onPrevious: props.onPreviousKeg,
                      visible: true
                    }),
                tmp
              ]
            });
}

var make = KegDetail;

export {
  classes ,
  tableConsumptionsClasses ,
  make ,
}
/* classes Not a pure module */
