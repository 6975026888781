// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_math from "rescript/lib/es6/js_math.js";
import * as DateUtils from "../../utils/DateUtils.bs.js";
import * as ReactIntl from "react-intl";
import * as JsxRuntime from "react/jsx-runtime";

function FormattedDateTimeShort(props) {
  var value = props.value;
  var now = new Date();
  var todayEOD = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1.0);
  var differenceInDays = Js_math.floor_int((todayEOD.getTime() - value.getTime()) / DateUtils.dayInMilliseconds);
  switch (differenceInDays) {
    case 0 :
        return JsxRuntime.jsx(ReactIntl.FormattedTime, {
                    value: value,
                    hour: "numeric",
                    minute: "numeric"
                  });
    case 1 :
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      "včera\xA0",
                      JsxRuntime.jsx(ReactIntl.FormattedTime, {
                            value: value,
                            hour: "numeric",
                            minute: "numeric"
                          })
                    ]
                  });
    case 2 :
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      "předevčírem\xA0",
                      JsxRuntime.jsx(ReactIntl.FormattedTime, {
                            value: value,
                            hour: "numeric",
                            minute: "numeric"
                          })
                    ]
                  });
    case 3 :
    case 4 :
    case 5 :
    case 6 :
        break;
    default:
      return JsxRuntime.jsx(ReactIntl.FormattedDate, {
                  value: value,
                  weekday: "short",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric"
                });
  }
  return JsxRuntime.jsx(ReactIntl.FormattedDate, {
              value: value,
              weekday: "long",
              hour: "numeric",
              minute: "numeric"
            });
}

var make = FormattedDateTimeShort;

export {
  make ,
}
/* react-intl Not a pure module */
