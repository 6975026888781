// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactIntl from "react-intl";
import * as RouterUtils from "../../utils/RouterUtils.bs.js";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as SectionWithHeader from "../../components/SectionWithHeader/SectionWithHeader.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import PlaceStatsModuleCss from "./PlaceStats.module.css";

var classes = PlaceStatsModuleCss;

function PlaceStats(props) {
  var tmp;
  if (props.isUserAuthorized(80)) {
    var newrecord = Caml_obj.obj_dup(RouterUtils.createAnchorProps(undefined, "./osob"));
    tmp = JsxRuntime.jsx("a", (newrecord.type = "button", newrecord.className = Styles.button.base, newrecord.children = "Osobní účty", newrecord));
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(SectionWithHeader.make, {
              children: JsxRuntime.jsxs("dl", {
                    children: [
                      JsxRuntime.jsx("dt", {
                            children: "Počet hostů"
                          }),
                      JsxRuntime.jsx("dd", {
                            children: JsxRuntime.jsx(ReactIntl.FormattedNumber, {
                                  value: props.personsCount
                                })
                          }),
                      JsxRuntime.jsx("dt", {
                            children: "Sudy na skladě"
                          }),
                      JsxRuntime.jsx("dd", {
                            children: JsxRuntime.jsx(FormattedCurrency.make, {
                                  value: props.chargedKegsValue
                                })
                          })
                    ],
                    className: classes.descriptionList
                  }),
              buttonsSlot: tmp,
              headerId: "accounting_overview",
              headerSlot: "Účetnictví"
            });
}

var make = PlaceStats;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
