// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as OnboardingTemplate from "./OnboardingTemplate.bs.js";

function get(state, field) {
  return state.name;
}

function set(_state, field, value) {
  return {
          name: value
        };
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function OnboardingNickName(props) {
  var onSubmit = props.onSubmit;
  var form = Form.use({
        name: props.initialName
      }, Validators$1.schema([Validators$1.required("Name")]), (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          Core__Promise.$$catch(onSubmit(param.state.values), (function (error) {
                  var errorMessage;
                  if (error.RE_EXN_ID === Js_exn.$$Error) {
                    var msg = error._1.message;
                    errorMessage = msg !== undefined ? "Chyba: " + msg : "Neznámá chyba";
                  } else {
                    errorMessage = "Neznámá chyba";
                  }
                  raiseSubmitFailed(errorMessage);
                  return Promise.resolve();
                }));
        }), undefined, undefined, "OnDemand", undefined);
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba";
  }
  return JsxRuntime.jsxs(OnboardingTemplate.make, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Jak ti říkají?"
                    }),
                JsxRuntime.jsxs(Form.Provider.make, {
                      value: form,
                      children: [
                        JsxRuntime.jsx("form", {
                              children: JsxRuntime.jsxs("fieldset", {
                                    children: [
                                      JsxRuntime.jsx("p", {
                                            children: "Tvoje přezdívka se použije pro místa, která založíš.",
                                            className: Styles.fieldset.gridSpan
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: "Tam, kde jsi hostem můžeš mít jiné jméno, to určuje správce každého místa.",
                                            className: Styles.fieldset.gridSpan
                                          }),
                                      JsxRuntime.jsx(Form.Field.make, {
                                            field: "Name",
                                            render: (function (field) {
                                                return JsxRuntime.jsx(InputWrapper.make, {
                                                            inputError: field.error,
                                                            inputName: "name",
                                                            inputSlot: JsxRuntime.jsx("input", {
                                                                  autoComplete: "name",
                                                                  type: "text",
                                                                  value: field.value,
                                                                  onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                }),
                                                            labelSlot: "Přezdívka"
                                                          });
                                              })
                                          }),
                                      JsxRuntime.jsx("button", {
                                            children: "Dokončit registraci",
                                            className: Styles.button.base + " " + Styles.fieldset.gridSpan,
                                            type: "submit"
                                          })
                                    ],
                                    className: "reset " + Styles.fieldset.grid
                                  }),
                              onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                            }),
                        tmp
                      ]
                    })
              ]
            });
}

var make = OnboardingNickName;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
