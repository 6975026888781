// Generated by ReScript, PLEASE EDIT WITH CARE


function LoadingInline(props) {
  return "přemýšlím 🤔";
}

var make = LoadingInline;

export {
  make ,
}
/* No side effect */
