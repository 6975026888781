// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import InputToggleModuleCss from "./InputToggle.module.css";

var classes = InputToggleModuleCss;

function InputToggle(props) {
  return JsxRuntime.jsx("input", {
              "aria-invalid": props.ariaInvalid,
              "aria-describedby": props.ariaDescribedby,
              className: classes.inputToggle,
              id: props.id,
              role: "switch",
              checked: props.checked,
              name: props.name,
              type: "checkbox",
              onChange: props.onChange
            });
}

var make = InputToggle;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
