// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function get(state, field) {
  if (field === "PersonName") {
    return state.personName;
  } else {
    return state.placeName;
  }
}

function set(state, field, value) {
  if (field === "PersonName") {
    return {
            personName: value,
            placeName: state.placeName
          };
  } else {
    return {
            personName: state.personName,
            placeName: value
          };
  }
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function PlaceAdd(props) {
  var onSubmit = props.onSubmit;
  var form = Form.use({
        personName: props.initialPersonName,
        placeName: ""
      }, Validators$1.schema([
            Validators$1.required("PlaceName"),
            Validators$1.required("PersonName")
          ]), (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          Core__Promise.$$catch(onSubmit(param.state.values), (function (error) {
                  var errorMessage;
                  if (error.RE_EXN_ID === Js_exn.$$Error) {
                    var msg = error._1.message;
                    errorMessage = msg !== undefined ? "Chyba: " + msg : "Neznámá chyba";
                  } else {
                    errorMessage = "Neznámá chyba";
                  }
                  raiseSubmitFailed(errorMessage);
                  return Promise.resolve();
                }));
        }), undefined, undefined, "OnDemand", undefined);
  return JsxRuntime.jsx(DialogForm.make, {
              children: JsxRuntime.jsx(Form.Provider.make, {
                    value: form,
                    children: JsxRuntime.jsx("form", {
                          children: JsxRuntime.jsxs("fieldset", {
                                children: [
                                  JsxRuntime.jsx(Form.Field.make, {
                                        field: "PlaceName",
                                        render: (function (field) {
                                            return JsxRuntime.jsx(InputWrapper.make, {
                                                        inputError: field.error,
                                                        inputName: "placeName",
                                                        inputSlot: JsxRuntime.jsx("input", {
                                                              type: "text",
                                                              value: field.value,
                                                              onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                            }),
                                                        labelSlot: "Název místa"
                                                      });
                                          })
                                      }),
                                  JsxRuntime.jsx(Form.Field.make, {
                                        field: "PersonName",
                                        render: (function (field) {
                                            return JsxRuntime.jsx(InputWrapper.make, {
                                                        inputError: field.error,
                                                        inputName: "personName",
                                                        inputSlot: JsxRuntime.jsx("input", {
                                                              type: "text",
                                                              value: field.value,
                                                              onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                            }),
                                                        labelSlot: "Moje přezdívka"
                                                      });
                                          })
                                      })
                                ],
                                className: "reset " + Styles.fieldset.grid
                              }),
                          id: "add_place",
                          autoComplete: "off",
                          onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                        })
                  }),
              formId: "add_place",
              heading: "Nové místo",
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = PlaceAdd;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
