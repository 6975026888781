// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as ReactDom from "react-dom";
import * as RouterUtils from "../../utils/RouterUtils.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import HomepageModuleCss from "./Homepage.module.css";

var classes = HomepageModuleCss;

var supportsTransitionApi = (typeof document.startViewTransition === 'function');

function Homepage(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("header", {
                            children: [
                              JsxRuntime.jsx("h1", {
                                    "aria-label": "Check Beerk",
                                    className: "text-center"
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: "Pivní zápisník"
                                  })
                            ]
                          }),
                      JsxRuntime.jsx("main", {
                            children: JsxRuntime.jsx("a", {
                                  children: "Otevřít",
                                  className: Styles.button.sizeLarge,
                                  href: "/misto",
                                  onClick: RouterUtils.handleLinkClick(function () {
                                        var navigate = function () {
                                          RescriptReactRouter.push("/misto#" + RouterUtils.fromHomepageHash);
                                        };
                                        if (supportsTransitionApi) {
                                          document.startViewTransition(function () {
                                                ReactDom.flushSync(navigate);
                                              });
                                          return ;
                                        } else {
                                          return navigate();
                                        }
                                      })
                                })
                          })
                    ],
                    className: Styles.page.centered
                  }),
              className: classes.root
            });
}

var make = Homepage;

export {
  classes ,
  supportsTransitionApi ,
  make ,
}
/* classes Not a pure module */
