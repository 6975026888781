// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function unionByPreferringLast(comparator, overlapOpt, arr1, arr2) {
  var overlap = overlapOpt !== undefined ? overlapOpt : 3;
  var result = arr2.slice();
  var arr1LastIdx = arr1.length - 1 | 0;
  var stopCheck = overlap === 0 ? 0 : arr1LastIdx - overlap | 0;
  for(var i = arr1LastIdx; i >= 0; --i){
    var a1 = Core__Option.getExn(arr1[i], undefined);
    var duplicate = i > stopCheck && arr2.some((function(a1){
        return function (i) {
          return comparator(a1, i);
        }
        }(a1)));
    if (!duplicate) {
      result.unshift(a1);
    }
    
  }
  return result;
}

export {
  unionByPreferringLast ,
}
/* No side effect */
