// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as React from "react";
import * as Reactfire from "reactfire";
import * as ArrayUtils from "../../utils/ArrayUtils.bs.js";
import * as Core__Null from "@rescript/core/src/Core__Null.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactUpdate from "rescript-react-update/src/ReactUpdate.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Firestore from "rxfire/firestore";
import * as Firestore$1 from "firebase/firestore";

var initialState = {
  continueWith: undefined,
  data: undefined,
  error: undefined,
  pending: false
};

function getConstraints(limit, startAfter) {
  var basicConstraints = [
    Firestore$1.where("depletedAt", "!=", null),
    Firestore$1.orderBy("depletedAt", "desc"),
    Firestore$1.limit(limit + 1 | 0)
  ];
  if (startAfter !== undefined) {
    return basicConstraints.concat([Firestore$1.startAfter(Caml_option.valFromOption(startAfter))]);
  } else {
    return basicConstraints;
  }
}

function compareKegs(keg1, keg2) {
  if (keg1.serial === keg2.serial) {
    return Core__Null.map(keg1.depletedAt, (function (prim) {
                  return prim.toMillis();
                })) === Core__Null.map(keg2.depletedAt, (function (prim) {
                  return prim.toMillis();
                }));
  } else {
    return false;
  }
}

function concatenateResponse(extra, extra$1, extra$2) {
  return ArrayUtils.unionByPreferringLast(compareKegs, extra, extra$1, extra$2);
}

function kegQuerySnapshotToKegs(kegsSnapshot) {
  return kegsSnapshot.docs.map(function (prim) {
              return Firestore.snapToData(prim, { "idField": "uid" });
            });
}

function use(limitOpt, placeId) {
  var limit = limitOpt !== undefined ? limitOpt : 20;
  var firestore = Reactfire.useFirestore();
  var kegsCollectionRef = Db.placeKegsCollectionConverted(firestore, placeId);
  var match = ReactUpdate.useReducer((function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchMore") {
              var lastKeg = state.continueWith;
              if (lastKeg !== undefined && state.data !== undefined && !state.pending) {
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          continueWith: state.continueWith,
                          data: state.data,
                          error: state.error,
                          pending: true
                        },
                        _1: (function (param) {
                            var send = param.send;
                            var constraints = getConstraints(limit, lastKeg);
                            Core__Promise.$$catch(Firestore$1.getDocs(Caml_splice_call.spliceApply(Firestore$1.query, [
                                            kegsCollectionRef,
                                            constraints
                                          ])).then(function (kegsSnapshot) {
                                      var data = kegQuerySnapshotToKegs(kegsSnapshot);
                                      send({
                                            TAG: "FetchMoreSuccess",
                                            _0: data
                                          });
                                      return Promise.resolve();
                                    }), (function (error) {
                                    send({
                                          TAG: "LoadingError",
                                          _0: error
                                        });
                                    return Promise.resolve();
                                  }));
                          })
                      };
              }
              
            } else {
              var exit = 0;
              if (state.continueWith !== undefined || state.data !== undefined) {
                exit = 2;
              } else {
                if (!state.pending) {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            continueWith: state.continueWith,
                            data: state.data,
                            error: state.error,
                            pending: true
                          },
                          _1: (function (param) {
                              var send = param.send;
                              var constraints = getConstraints(limit, undefined);
                              Core__Promise.$$catch(Firestore$1.getDocs(Caml_splice_call.spliceApply(Firestore$1.query, [
                                              kegsCollectionRef,
                                              constraints
                                            ])).then(function (kegsSnapshot) {
                                        var data = kegQuerySnapshotToKegs(kegsSnapshot);
                                        send({
                                              TAG: "InitialLoadSuccess",
                                              _0: data
                                            });
                                        return Promise.resolve();
                                      }), (function (error) {
                                      send({
                                            TAG: "LoadingError",
                                            _0: error
                                          });
                                      return Promise.resolve();
                                    }));
                            })
                        };
                }
                exit = 2;
              }
              if (exit === 2) {
                if (state.data !== undefined) {
                  if (!state.pending) {
                    return "NoUpdate";
                  }
                  
                } else if (state.pending) {
                  return "NoUpdate";
                }
                
              }
              
            }
          } else {
            switch (action.TAG) {
              case "FetchMoreSuccess" :
                  var previousData = state.data;
                  if (previousData !== undefined) {
                    var newData = action._0;
                    return {
                            TAG: "Update",
                            _0: {
                              continueWith: newData[limit],
                              data: concatenateResponse(undefined, previousData, newData),
                              error: undefined,
                              pending: false
                            }
                          };
                  }
                  break;
              case "InitialLoadSuccess" :
                  var data = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            continueWith: data[limit],
                            data: data,
                            error: undefined,
                            pending: false
                          }
                        };
              case "LoadingError" :
                  return {
                          TAG: "Update",
                          _0: {
                            continueWith: state.continueWith,
                            data: state.data,
                            error: action._0,
                            pending: false
                          }
                        };
              
            }
          }
          console.log("unhandled state and action", {
                state: state,
                action: action
              });
          return "NoUpdate";
        }), initialState);
  var paginatedDataSend = match[1];
  var paginatedDataState = match[0];
  React.useEffect((function () {
          paginatedDataSend("InitialLoad");
        }), []);
  if (paginatedDataState.continueWith !== undefined) {
    var data = paginatedDataState.data;
    if (data !== undefined) {
      return [
              data.slice(0, -1),
              (function () {
                  paginatedDataSend("FetchMore");
                })
            ];
    } else {
      return [
              undefined,
              undefined
            ];
    }
  }
  var data$1 = paginatedDataState.data;
  if (data$1 !== undefined) {
    return [
            data$1,
            undefined
          ];
  } else {
    return [
            undefined,
            undefined
          ];
  }
}

export {
  initialState ,
  getConstraints ,
  compareKegs ,
  concatenateResponse ,
  kegQuerySnapshotToKegs ,
  use ,
}
/* Db Not a pure module */
