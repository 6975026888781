// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReSchema from "../reform/ReSchema.bs.js";
import * as Core__Float from "@rescript/core/src/Core__Float.bs.js";

function CustomValidators(Lenses) {
  var ReSchema$1 = ReSchema.Make(Lenses);
  var custom = ReSchema$1.Validation.custom;
  var email = function (extra) {
    return Curry._2(ReSchema$1.Validation.email, "Neplatný email", extra);
  };
  var equals = function (expected, error, field) {
    return ReSchema$1.Validation.custom((function (lensState) {
                  var value = Lenses.get(lensState, field);
                  if (Caml_obj.equal(value, expected)) {
                    return "Valid";
                  } else {
                    return {
                            TAG: "Error",
                            _0: error
                          };
                  }
                }), field);
  };
  var $$float = ReSchema$1.Validation.$$float;
  var $$int = ReSchema$1.Validation.$$int;
  var intNonZero = function (errorOpt, field) {
    var error = errorOpt !== undefined ? errorOpt : "Nesmí být nula";
    return ReSchema$1.Validation.custom((function (lensState) {
                  var value = Lenses.get(lensState, field);
                  if (value !== 0) {
                    return "Valid";
                  } else {
                    return {
                            TAG: "Error",
                            _0: error
                          };
                  }
                }), field);
  };
  var isNumeric = function (errorOpt, field) {
    var error = errorOpt !== undefined ? errorOpt : "Musí být číslo";
    return ReSchema$1.Validation.custom((function (lensState) {
                  var value = Lenses.get(lensState, field);
                  var match = Core__Float.fromString(value);
                  if (match !== undefined) {
                    return "Valid";
                  } else {
                    return {
                            TAG: "Error",
                            _0: error
                          };
                  }
                }), field);
  };
  var matchField = function (secondField, error, field) {
    return ReSchema$1.Validation.custom((function (lensState) {
                  var value = Lenses.get(lensState, field);
                  var secondFieldValue = Lenses.get(lensState, secondField);
                  if (Caml_obj.equal(value, secondFieldValue)) {
                    return "Valid";
                  } else {
                    return {
                            TAG: "Error",
                            _0: error
                          };
                  }
                }), field);
  };
  var notIn = function (haystack, error, field) {
    return ReSchema$1.Validation.custom((function (lensState) {
                  var value = Lenses.get(lensState, field);
                  if (haystack.includes(value)) {
                    return {
                            TAG: "Error",
                            _0: error
                          };
                  } else {
                    return "Valid";
                  }
                }), field);
  };
  var oneOf = function (haystack, error, field) {
    return ReSchema$1.Validation.custom((function (lensState) {
                  var value = Lenses.get(lensState, field);
                  if (haystack.includes(value)) {
                    return "Valid";
                  } else {
                    return {
                            TAG: "Error",
                            _0: error
                          };
                  }
                }), field);
  };
  var password = function (field) {
    return ReSchema$1.Validation.custom((function (lensState) {
                  var value = Lenses.get(lensState, field);
                  if (value.length < 6) {
                    return {
                            TAG: "Error",
                            _0: "Heslo musí mít alespoň 6 znaků"
                          };
                  } else {
                    return "Valid";
                  }
                }), field);
  };
  var required = function (extra) {
    return Curry._3(ReSchema$1.Validation.regExp, "Bez tohoto to nepůjde", "[^\s]", extra);
  };
  var schema = ReSchema$1.Validation.schema;
  return {
          ReSchema: ReSchema$1,
          Validation: undefined,
          custom: custom,
          email: email,
          equals: equals,
          $$float: $$float,
          $$int: $$int,
          intNonZero: intNonZero,
          isNumeric: isNumeric,
          matchField: matchField,
          notIn: notIn,
          oneOf: oneOf,
          password: password,
          required: required,
          schema: schema
        };
}

export {
  CustomValidators ,
}
/* No side effect */
