// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as MinorUnitsJs from "./minorUnits.js";
import * as JsxRuntime from "react/jsx-runtime";
import FormattedCurrencyModuleCss from "./FormattedCurrency.module.css";

var classes = FormattedCurrencyModuleCss;

var context = React.createContext("CZK");

var minorUnits = MinorUnitsJs.minorUnits;

function getMinorUnit(currency) {
  return Core__Option.getOr(minorUnits[currency], 100.0);
}

function useCurrency() {
  var currency = React.useContext(context);
  var minorUnit = getMinorUnit(currency);
  return {
          currency: currency,
          minorUnit: minorUnit
        };
}

function FormattedCurrency(props) {
  var value = props.value;
  var match = useCurrency();
  return JsxRuntime.jsx(ReactIntl.FormattedNumber, {
              value: value / match.minorUnit,
              style: "currency",
              currency: match.currency,
              children: Core__Option.map(props.format, (function (fn) {
                      return fn(value);
                    }))
            });
}

var make = context.Provider;

var Provider = {
  make: make
};

function formatAccounting(value) {
  return function (formattedNumber) {
    var negative = value < 0;
    return React.cloneElement(JsxRuntime.jsx("span", {}), {
                className: classes.accounting,
                children: formattedNumber,
                "data-negative": PervasivesU.string_of_bool(negative)
              });
  };
}

var make$1 = FormattedCurrency;

export {
  classes ,
  context ,
  minorUnits ,
  getMinorUnit ,
  useCurrency ,
  make$1 as make,
  Provider ,
  formatAccounting ,
}
/* classes Not a pure module */
