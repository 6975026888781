// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Rxjs from "../../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as DateUtils from "../../utils/DateUtils.bs.js";
import * as UserRoles from "../../backend/UserRoles.bs.js";
import * as Reactfire from "reactfire";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PlaceHeader from "../../components/PlaceHeader/PlaceHeader.bs.js";
import * as Auth from "rxfire/auth";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as PersonDetail from "./PersonDetail.bs.js";
import * as Firestore from "rxfire/firestore";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as SectionWithHeader from "../../components/SectionWithHeader/SectionWithHeader.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FormattedRelativeTime from "../../components/FormattedRelativeTime/FormattedRelativeTime.bs.js";
import * as PersonAddPersonsSetting from "./PersonAddPersonsSetting.bs.js";
import PlacePersonsSettingModuleCss from "./PlacePersonsSetting.module.css";

var classes = PlacePersonsSettingModuleCss;

function pageDataRx(auth, firestore, placeId) {
  var placeRef = Db.placeDocument(firestore, placeId);
  var placeRx = Rxjs.op(Firestore.docData(placeRef, { "idField": "uid" }), Rxjs.keepSome);
  var allChargedKegsRx = Db.allChargedKegsRx(firestore, placeId);
  var unfinishedConsumptionsByUserRx = Rxjs.op(allChargedKegsRx, Rxjs$1.map(function (chargedKegs, param) {
            var consumptionsByUser = new Map();
            chargedKegs.forEach(function (keg) {
                  Db.groupKegConsumptionsByUser(Caml_option.some(consumptionsByUser), keg);
                });
            consumptionsByUser.forEach(function (consumptions) {
                  consumptions.sort(function (a, b) {
                        return DateUtils.compare(a.createdAt, b.createdAt);
                      });
                });
            return consumptionsByUser;
          }));
  var pendingTransactionsByUserRx = Rxjs.op(allChargedKegsRx, Rxjs$1.map(function (chargedKegs, param) {
            var kegByDonor = new Map();
            chargedKegs.forEach(function (keg) {
                  Js_dict.entries(keg.donors).forEach(function (param) {
                        var personId = param[0];
                        var transaction_amount = param[1];
                        var transaction_createdAt = keg.createdAt;
                        var transaction_keg = keg.serial;
                        var transaction_note = null;
                        var transaction_person = null;
                        var transaction = {
                          amount: transaction_amount,
                          createdAt: transaction_createdAt,
                          keg: transaction_keg,
                          note: transaction_note,
                          person: transaction_person
                        };
                        var kegs = kegByDonor.get(personId);
                        if (kegs !== undefined) {
                          kegs.push(transaction);
                        } else {
                          kegByDonor.set(personId, [transaction]);
                        }
                      });
                });
            return kegByDonor;
          }));
  var personsAllRx = Db.PersonsIndex.allEntriesSortedRx(firestore, placeId);
  var currentUserRx = Rxjs.op(Auth.user(auth), Rxjs.keepMap(function (prim) {
            if (prim === null) {
              return ;
            } else {
              return Caml_option.some(prim);
            }
          }));
  return Rxjs$1.combineLatest(placeRx, personsAllRx, unfinishedConsumptionsByUserRx, pendingTransactionsByUserRx, currentUserRx);
}

function PlacePersonsSetting(props) {
  var placeId = props.placeId;
  var auth = Reactfire.useAuth();
  var firestore = Reactfire.useFirestore();
  var pageDataStatus = Reactfire.useObservable("Page_PlacePersonsSetting_" + placeId, pageDataRx(auth, firestore, placeId));
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDialog = match[1];
  var dialogState = match[0];
  var hideDialog = function (param) {
    setDialog(function (param) {
          return "Hidden";
        });
  };
  var match$1 = pageDataStatus.data;
  if (match$1 === undefined) {
    return null;
  }
  var personsAll = match$1[1];
  var place = match$1[0];
  var match$2 = Core__Option.getExn(place.accounts[match$1[4].uid], undefined);
  if (!UserRoles.isAuthorized(match$2[0], 80)) {
    Js_exn.raiseError("Insufficient permissions to view this page");
  }
  var tmp;
  if (typeof dialogState !== "object") {
    tmp = dialogState === "Hidden" ? null : JsxRuntime.jsx(PersonAddPersonsSetting.make, {
            existingNames: personsAll.map(function (param) {
                  return param[1].name;
                }),
            onDismiss: hideDialog,
            onSubmit: (function (values) {
                Db.Person.add(firestore, placeId, values.name);
                setDialog(function (param) {
                      return "Hidden";
                    });
              })
          });
  } else {
    var personId = dialogState.personId;
    var currentIdx = personsAll.findIndex(function (param) {
          return param[0] === personId;
        });
    if (currentIdx === -1) {
      setDialog(function (param) {
            return "Hidden";
          });
      tmp = null;
    } else {
      var hasNext = currentIdx < (personsAll.length - 1 | 0);
      var hasPrevious = currentIdx > 0;
      var handleCycle = function (increase) {
        var allowed = increase ? hasNext : hasPrevious;
        if (!allowed) {
          return ;
        }
        var nextIdx = currentIdx + (
          increase ? 1 : -1
        ) | 0;
        var match = Belt_Array.getExn(personsAll, nextIdx);
        var nextPerson = match[1];
        var nextPersonId = match[0];
        setDialog(function (param) {
              return {
                      TAG: "PersonDetail",
                      personId: nextPersonId,
                      person: nextPerson
                    };
            });
      };
      var unfinishedConsumptions = Core__Option.getOr(match$1[2].get(personId), []);
      tmp = JsxRuntime.jsx(PersonDetail.make, {
            hasNext: hasNext,
            hasPrevious: hasPrevious,
            onDeletePerson: (function () {
                Db.Person.$$delete(firestore, placeId, personId);
              }),
            onDismiss: hideDialog,
            onNextPerson: (function () {
                handleCycle(true);
              }),
            onPreviousPerson: (function () {
                handleCycle(false);
              }),
            pendingTransactions: Core__Option.getOr(match$1[3].get(personId), []),
            person: dialogState.person,
            personsAll: personsAll,
            personId: personId,
            place: place,
            placeId: placeId,
            unfinishedConsumptions: unfinishedConsumptions
          });
    }
  }
  return JsxRuntime.jsx(FormattedCurrency.Provider.make, {
              value: place.currency,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(PlaceHeader.make, {
                            buttonRightSlot: null,
                            createdTimestamp: place.createdAt,
                            placeName: place.name
                          }),
                      JsxRuntime.jsx("main", {
                            children: JsxRuntime.jsx(SectionWithHeader.make, {
                                  children: JsxRuntime.jsxs("table", {
                                        children: [
                                          JsxRuntime.jsx("thead", {
                                                children: JsxRuntime.jsxs("tr", {
                                                      children: [
                                                        JsxRuntime.jsx("th", {
                                                              children: "Host",
                                                              scope: "col"
                                                            }),
                                                        JsxRuntime.jsx("th", {
                                                              children: "Naposledy",
                                                              scope: "col"
                                                            }),
                                                        JsxRuntime.jsx("th", {
                                                              children: "Role",
                                                              scope: "col"
                                                            }),
                                                        JsxRuntime.jsx("th", {
                                                              children: "Konto",
                                                              scope: "col"
                                                            })
                                                      ]
                                                    })
                                              }),
                                          JsxRuntime.jsx("tbody", {
                                                children: personsAll.map(function (param) {
                                                      var person = param[1];
                                                      var personId = param[0];
                                                      return JsxRuntime.jsxs("tr", {
                                                                  children: [
                                                                    JsxRuntime.jsx("th", {
                                                                          children: person.name,
                                                                          scope: "row"
                                                                        }),
                                                                    JsxRuntime.jsx("td", {
                                                                          children: JsxRuntime.jsx(FormattedRelativeTime.make, {
                                                                                dateTime: person.recentActivityAt.toDate()
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx("td", {
                                                                          children: Core__Option.mapOr(Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(Caml_option.null_to_opt(person.userId), (function (userId) {
                                                                                              return place.accounts[userId];
                                                                                            })), (function (param) {
                                                                                          return UserRoles.roleFromInt(param[0]);
                                                                                        })), UserRoles.roleI18n), null, (function (prim) {
                                                                                  return prim;
                                                                                }))
                                                                        }),
                                                                    JsxRuntime.jsx("td", {
                                                                          children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                                                value: person.balance,
                                                                                format: FormattedCurrency.formatAccounting
                                                                              })
                                                                        })
                                                                  ],
                                                                  onClick: (function (param) {
                                                                      setDialog(function (param) {
                                                                            return {
                                                                                    TAG: "PersonDetail",
                                                                                    personId: personId,
                                                                                    person: person
                                                                                  };
                                                                          });
                                                                    })
                                                                }, personId);
                                                    })
                                              })
                                        ],
                                        "aria-labelledby": "persons_accounts",
                                        className: Styles.table.stretch + " " + classes.table
                                      }),
                                  buttonsSlot: JsxRuntime.jsx("button", {
                                        children: "Přidat hosta",
                                        className: Styles.button.base,
                                        type: "button",
                                        onClick: (function (param) {
                                            setDialog(function (param) {
                                                  return "AddPerson";
                                                });
                                          })
                                      }),
                                  headerId: "persons_accounts",
                                  headerSlot: "Účetnictví"
                                })
                          }),
                      tmp
                    ],
                    className: Styles.page.narrow
                  })
            });
}

var make = PlacePersonsSetting;

export {
  classes ,
  pageDataRx ,
  make ,
}
/* classes Not a pure module */
