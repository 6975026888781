// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UnauthenticatedTemplate from "./UnauthenticatedTemplate.bs.js";

function ForgottenPasswordSent(props) {
  var onGoBack = props.onGoBack;
  return JsxRuntime.jsxs(UnauthenticatedTemplate.make, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Zapomenuté heslo"
                    }),
                JsxRuntime.jsxs("p", {
                      children: [
                        "Poslali jsme vám odkaz na změnu hesla. Zkontrolujte poštu na adrese ",
                        JsxRuntime.jsx("b", {
                              children: props.email
                            })
                      ]
                    }),
                JsxRuntime.jsx("button", {
                      children: "Zpět na přihlášení",
                      className: Styles.button.base,
                      type: "button",
                      onClick: (function (param) {
                          onGoBack();
                        })
                    })
              ]
            });
}

var make = ForgottenPasswordSent;

export {
  make ,
}
/* Styles Not a pure module */
