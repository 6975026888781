// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Reactfire from "reactfire";
import * as Analytics from "firebase/analytics";

import * as Sentry from "@sentry/react";
;

var initSentry = (() => {
  if (import.meta.env.PROD && window.location.host === 'check.beer') {
    Sentry.init({
      environment: import.meta.env.MODE,
      dsn: "https://85aeb6b971b04c4cb49af3a52f2ad81e@o4505561027903488.ingest.sentry.io/4505561029607424",
    });
  }
});

var captureException = (import.meta.env.PROD && window.location.host === 'check.beer' ? Sentry.captureException : console.error.bind(console));

var captureMessage = (import.meta.env.PROD && window.location.host === 'check.beer' ? Sentry.captureMessage : console.log.bind(console));

function logEvent(analytics, eventName, eventParams) {
  if ((import.meta.env.PROD && window.location.host === 'check.beer')) {
    Analytics.logEvent(analytics, eventName, eventParams);
  } else {
    console.log("%clogEvent", "color: blue", eventName, eventParams);
  }
}

var lastPageView = {
  contents: undefined
};

function usePageView(screenName) {
  var analytics = Reactfire.useAnalytics();
  if (lastPageView.contents === screenName) {
    return ;
  } else {
    logEvent(analytics, "page_view", {
          screen_name: screenName
        });
    lastPageView.contents = screenName;
    return ;
  }
}

export {
  initSentry ,
  captureException ,
  captureMessage ,
  logEvent ,
  lastPageView ,
  usePageView ,
}
/*  Not a pure module */
