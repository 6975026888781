// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as FirebaseConfig from "./firebaseConfig";

var firebaseConfig = FirebaseConfig.firebaseConfig;

var AnalyticsProvider = {};

var AuthProvider = {};

var FirebaseAppProvider = {};

var FunctionsProvider = {};

var messagingContext = React.createContext(undefined);

var make = messagingContext.Provider;

var MessagingProvider = {
  make: make
};

function useMessaging() {
  return Core__Option.getExn(React.useContext(messagingContext), undefined);
}

var FirestoreProvider = {};

export {
  firebaseConfig ,
  AnalyticsProvider ,
  AuthProvider ,
  FirebaseAppProvider ,
  FunctionsProvider ,
  messagingContext ,
  MessagingProvider ,
  useMessaging ,
  FirestoreProvider ,
}
/* firebaseConfig Not a pure module */
