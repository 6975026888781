// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as UserRoles from "../../backend/UserRoles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function SectionRoleDescription(props) {
  var roleOptions = [
    10,
    20,
    50,
    80
  ];
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Popis rolí",
                      id: "role_description"
                    }),
                JsxRuntime.jsx("dl", {
                      children: roleOptions.map(function (role) {
                            var name = UserRoles.roleI18n(role);
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("dt", {
                                                children: JsxRuntime.jsx("dfn", {
                                                      children: name
                                                    })
                                              }),
                                          JsxRuntime.jsx("dd", {
                                                children: UserRoles.roleDescription(role)
                                              })
                                        ]
                                      }, name);
                          }),
                      className: Styles.descriptionList.hyphen
                    })
              ],
              "aria-labelledby": "role_description"
            });
}

var make = SectionRoleDescription;

export {
  make ,
}
/* Styles Not a pure module */
