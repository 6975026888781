// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as FirebaseError from "../../backend/FirebaseError.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function get(state, field) {
  switch (field) {
    case "NewPassword" :
        return state.newPassword;
    case "NewPasswordConfirmation" :
        return state.newPasswordConfirmation;
    case "OldPassword" :
        return state.oldPassword;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case "NewPassword" :
        return {
                newPassword: value,
                newPasswordConfirmation: state.newPasswordConfirmation,
                oldPassword: state.oldPassword
              };
    case "NewPasswordConfirmation" :
        return {
                newPassword: state.newPassword,
                newPasswordConfirmation: value,
                oldPassword: state.oldPassword
              };
    case "OldPassword" :
        return {
                newPassword: state.newPassword,
                newPasswordConfirmation: state.newPasswordConfirmation,
                oldPassword: value
              };
    
  }
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function ChangePassword(props) {
  var onSubmit = props.onSubmit;
  var form = Form.use({
        newPassword: "",
        newPasswordConfirmation: "",
        oldPassword: ""
      }, Validators$1.schema([
            Validators$1.required("OldPassword"),
            Validators$1.required("NewPassword"),
            Validators$1.password("NewPassword"),
            Validators$1.matchField("NewPassword", "Hesla se neshodují", "NewPasswordConfirmation")
          ]), (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          var match = state.values;
          Core__Promise.$$catch(onSubmit({
                    oldPassword: match.oldPassword,
                    newPassword: match.newPassword
                  }), (function (error) {
                  var match = FirebaseError.toFirebaseError(error);
                  if (match.RE_EXN_ID === FirebaseError.InvalidPassword) {
                    var newFieldsState = state.fieldsState.map(function (tuple) {
                          var field = tuple[0];
                          if (Caml_obj.notequal(field, {
                                  TAG: "Field",
                                  _0: "OldPassword"
                                })) {
                            return tuple;
                          } else {
                            return [
                                    field,
                                    {
                                      TAG: "Error",
                                      _0: "Nesprávné heslo"
                                    }
                                  ];
                          }
                        });
                    send({
                          TAG: "SetFieldsState",
                          _0: newFieldsState
                        });
                  } else {
                    var exn = Core__Option.getExn(Caml_js_exceptions.as_js_exn(error), undefined);
                    LogUtils.captureException(exn);
                    var msg = exn.message;
                    var errorMessage = msg !== undefined ? "Chyba: " + msg : "Neznámá chyba";
                    raiseSubmitFailed(errorMessage);
                  }
                  return Promise.resolve();
                }));
        }), undefined, undefined, "OnDemand", undefined);
  var submitting = form.state.formState === "Submitting";
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = JsxRuntime.jsx("p", {
          children: maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba",
          className: Styles.messageBar.variantDanger
        });
  }
  return JsxRuntime.jsx(DialogForm.make, {
              children: JsxRuntime.jsx(Form.Provider.make, {
                    value: form,
                    children: JsxRuntime.jsxs("form", {
                          children: [
                            JsxRuntime.jsxs("fieldset", {
                                  children: [
                                    JsxRuntime.jsx(Form.Field.make, {
                                          field: "OldPassword",
                                          render: (function (field) {
                                              return JsxRuntime.jsx(InputWrapper.make, {
                                                          inputError: field.error,
                                                          inputName: "oldPassword",
                                                          inputSlot: JsxRuntime.jsx("input", {
                                                                type: "password",
                                                                value: field.value,
                                                                onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                              }),
                                                          labelSlot: "Nynější heslo"
                                                        });
                                            })
                                        }),
                                    JsxRuntime.jsx(Form.Field.make, {
                                          field: "NewPassword",
                                          render: (function (field) {
                                              return JsxRuntime.jsx(InputWrapper.make, {
                                                          inputError: field.error,
                                                          inputName: "newPassword",
                                                          inputSlot: JsxRuntime.jsx("input", {
                                                                type: "password",
                                                                value: field.value,
                                                                onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                              }),
                                                          labelSlot: "Nové heslo"
                                                        });
                                            })
                                        }),
                                    JsxRuntime.jsx(Form.Field.make, {
                                          field: "NewPasswordConfirmation",
                                          render: (function (field) {
                                              return JsxRuntime.jsx(InputWrapper.make, {
                                                          inputError: field.error,
                                                          inputName: "newPasswordConfirmation",
                                                          inputSlot: JsxRuntime.jsx("input", {
                                                                type: "password",
                                                                value: field.value,
                                                                onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                              }),
                                                          labelSlot: "Heslo znovu"
                                                        });
                                            })
                                        })
                                  ],
                                  className: "reset " + Styles.fieldset.grid,
                                  disabled: submitting
                                }),
                            tmp
                          ],
                          className: Styles.stack.base,
                          id: "change_password",
                          onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                        })
                  }),
              formId: "change_password",
              heading: "Změna hesla",
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = ChangePassword;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
