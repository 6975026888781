// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import OnboardingTemplateModuleCss from "./OnboardingTemplate.module.css";

var classes = OnboardingTemplateModuleCss;

function OnboardingTemplate(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: "Check.beer",
                      className: Styles.utility.srOnly
                    }),
                props.children
              ],
              "aria-hidden": props.loadingOverlay,
              className: Styles.page.centered + " " + classes.root
            });
}

var make = OnboardingTemplate;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
