// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as AppStorage from "../../utils/AppStorage.bs.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as ChangePassword from "./ChangePassword.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as InputThrustDevice from "../../components/InputThrustDevice/InputThrustDevice.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function get(state, field) {
  return state.name;
}

function set(_state, field, value) {
  return {
          name: value
        };
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function EditUser(props) {
  var onChangePassword = props.onChangePassword;
  var onSubmit = props.onSubmit;
  React.useEffect((function () {
          var initialThrustDevide = AppStorage.getThrustDevice() !== undefined;
          return (function () {
                    var unmountThrustDevice = AppStorage.getThrustDevice() !== undefined;
                    if (initialThrustDevide !== unmountThrustDevice) {
                      window.location.reload();
                      return ;
                    }
                    
                  });
        }), []);
  var form = Form.use({
        name: props.initialName
      }, Validators$1.schema([Validators$1.required("Name")]), (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          Core__Promise.$$catch(onSubmit(param.state.values), (function (error) {
                  var errorMessage;
                  if (error.RE_EXN_ID === Js_exn.$$Error) {
                    var msg = error._1.message;
                    errorMessage = msg !== undefined ? "Chyba: " + msg : "Neznámá chyba";
                  } else {
                    errorMessage = "Neznámá chyba";
                  }
                  raiseSubmitFailed(errorMessage);
                  return Promise.resolve();
                }));
        }), undefined, undefined, "OnDemand", undefined);
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDialogState = match[1];
  var hideDialog = function (param) {
    setDialogState(function (param) {
          return "Hidden";
        });
  };
  var tmp;
  tmp = match[0] === "Hidden" ? null : JsxRuntime.jsx(ChangePassword.make, {
          onDismiss: hideDialog,
          onSubmit: (function (values) {
              return onChangePassword(values).then(function () {
                          setDialogState(function (param) {
                                return "Hidden";
                              });
                          return Promise.resolve();
                        });
            })
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DialogForm.make, {
                      children: JsxRuntime.jsx(Form.Provider.make, {
                            value: form,
                            children: JsxRuntime.jsx("form", {
                                  children: JsxRuntime.jsxs("fieldset", {
                                        children: [
                                          JsxRuntime.jsx(Form.Field.make, {
                                                field: "Name",
                                                render: (function (field) {
                                                    return JsxRuntime.jsx(InputWrapper.make, {
                                                                inputError: field.error,
                                                                inputName: "name",
                                                                inputSlot: JsxRuntime.jsx("input", {
                                                                      type: "text",
                                                                      value: field.value,
                                                                      onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                    }),
                                                                labelSlot: "Přezdívka"
                                                              });
                                                  })
                                              }),
                                          JsxRuntime.jsx(InputWrapper.make, {
                                                inputName: "email",
                                                inputSlot: JsxRuntime.jsx("input", {
                                                      disabled: true,
                                                      type: "text",
                                                      value: props.connectedEmail
                                                    }),
                                                labelSlot: "E-mail"
                                              }),
                                          JsxRuntime.jsx("button", {
                                                children: "Změnit heslo",
                                                className: Styles.button.base + " " + Styles.fieldset.gridSpan,
                                                type: "button",
                                                onClick: (function (param) {
                                                    setDialogState(function (param) {
                                                          return "ChangePassword";
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsx(InputThrustDevice.make, {})
                                        ],
                                        className: "reset " + Styles.fieldset.grid
                                      }),
                                  id: "edit_user",
                                  onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                                })
                          }),
                      formId: "edit_user",
                      heading: "Úprava uživatele",
                      onDismiss: props.onDismiss,
                      visible: true
                    }),
                tmp
              ]
            });
}

var make = EditUser;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
