// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dialog from "../Dialog/Dialog.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function DialogForm(props) {
  var onDismiss = props.onDismiss;
  return JsxRuntime.jsxs(Dialog.make, {
              children: [
                JsxRuntime.jsx("header", {
                      children: JsxRuntime.jsx("h3", {
                            children: props.heading
                          })
                    }),
                props.children,
                JsxRuntime.jsxs("footer", {
                      children: [
                        JsxRuntime.jsx("button", {
                              children: "Uložit",
                              className: Styles.button.variantPrimary,
                              form: props.formId,
                              type: "submit"
                            }),
                        JsxRuntime.jsx("button", {
                              children: "Zrušit",
                              className: Styles.button.base,
                              type: "button",
                              onClick: (function (param) {
                                  onDismiss();
                                })
                            })
                      ]
                    })
              ],
              className: props.className,
              visible: props.visible
            });
}

var make = DialogForm;

export {
  make ,
}
/* Dialog Not a pure module */
