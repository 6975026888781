// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as OnboardingTemplate from "./OnboardingTemplate.bs.js";

function BiometricAuthn(props) {
  var onSkip = props.onSkip;
  var onSetupAuthn = props.onSetupAuthn;
  return JsxRuntime.jsxs(OnboardingTemplate.make, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Přihlášení bez hesla"
                    }),
                JsxRuntime.jsx("p", {
                      children: "Tvoje zařízení umožňuje přihlásit se bez hesla."
                    }),
                Core__Option.mapOr(props.setupError, null, (function (param) {
                        return JsxRuntime.jsx("p", {
                                    children: "Nastavení se nezdařilo. Zkus to znovu nebo tento krok přeskoč.",
                                    className: Styles.messageBar.variantDanger
                                  });
                      })),
                JsxRuntime.jsx("button", {
                      children: "Nastavit",
                      className: Styles.button.base,
                      type: "button",
                      onClick: (function (param) {
                          onSetupAuthn();
                        })
                    }),
                JsxRuntime.jsx("button", {
                      children: "Přeskočit",
                      className: Styles.link.base,
                      type: "button",
                      onClick: (function (param) {
                          onSkip();
                        })
                    })
              ],
              loadingOverlay: props.loadingOverlay
            });
}

var make = BiometricAuthn;

export {
  make ,
}
/* Styles Not a pure module */
