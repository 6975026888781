// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReForm from "../../reform/ReForm.bs.js";
import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as UserRoles from "../../backend/UserRoles.bs.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SectionRoleDescription from "../../components/SectionRoleDescription/SectionRoleDescription.bs.js";

function get(state, field) {
  return state.role;
}

function set(_state, field, value) {
  return {
          role: value
        };
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function ChangeRole(props) {
  var onSubmit = props.onSubmit;
  var currentRole = props.currentRole;
  var roleOptions = [
    10,
    20,
    50,
    80
  ];
  var form = Form.use({
        role: currentRole
      }, Validators$1.schema([]), (function (param) {
          onSubmit(param.state.values);
        }), undefined, undefined, "OnDemand", undefined);
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba";
  }
  return JsxRuntime.jsx(DialogForm.make, {
              children: JsxRuntime.jsxs(Form.Provider.make, {
                    value: form,
                    children: [
                      JsxRuntime.jsxs("p", {
                            children: [
                              JsxRuntime.jsx("b", {
                                    children: props.personName
                                  }),
                              " je aktuálně ",
                              JsxRuntime.jsx("b", {
                                    children: UserRoles.roleI18n(currentRole)
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs("form", {
                            children: [
                              JsxRuntime.jsx("fieldset", {
                                    children: JsxRuntime.jsx(Form.Field.make, {
                                          field: "Role",
                                          render: (function (field) {
                                              return JsxRuntime.jsx(InputWrapper.make, {
                                                          inputError: field.error,
                                                          inputName: "role",
                                                          inputSlot: JsxRuntime.jsx("select", {
                                                                children: roleOptions.map(function (role) {
                                                                      var label = UserRoles.roleI18n(role);
                                                                      var value = role.toString();
                                                                      return JsxRuntime.jsx("option", {
                                                                                  children: label,
                                                                                  value: value
                                                                                }, value);
                                                                    }),
                                                                value: field.value.toString(),
                                                                onChange: (function ($$event) {
                                                                    var roleString = $$event.target.value;
                                                                    var role = Core__Option.getExn(Core__Option.flatMap(Core__Int.fromString(roleString, undefined), UserRoles.roleFromInt), undefined);
                                                                    field.handleChange(role);
                                                                  })
                                                              }),
                                                          labelSlot: "Nová role"
                                                        });
                                            })
                                        }),
                                    className: "reset"
                                  }),
                              tmp
                            ],
                            id: "change_role",
                            onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                          }),
                      JsxRuntime.jsx(SectionRoleDescription.make, {})
                    ]
                  }),
              formId: "change_role",
              heading: "Změnit roli",
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = ChangeRole;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
