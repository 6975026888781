// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Nullable from "@rescript/core/src/Core__Nullable.bs.js";

function getFormatConsumption(consumptionSymbols) {
  var symbolMap = Core__Nullable.getOr(consumptionSymbols, {
        "300": "I",
        "500": "X"
      });
  return function (consumptionMilliliters) {
    if (consumptionMilliliters < 400) {
      return symbolMap[300];
    } else {
      return symbolMap[500];
    }
  };
}

var smallBeerInMilliliters = 300;

var largeBeerInMilliliters = 500;

export {
  smallBeerInMilliliters ,
  largeBeerInMilliliters ,
  getFormatConsumption ,
}
/* No side effect */
