// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as FormattedVolume from "../../components/FormattedVolume/FormattedVolume.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function get(state, field) {
  return state.keg;
}

function set(_state, field, value) {
  return {
          keg: value
        };
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function TapKegOn(props) {
  var onSubmit = props.onSubmit;
  var options = Belt_Array.map(props.untappedChargedKegs, (function (keg) {
          return {
                  text: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          keg.serialFormatted + " " + keg.beer + " (",
                          JsxRuntime.jsx(FormattedVolume.make, {
                                milliliters: keg.milliliters
                              }),
                          ")"
                        ]
                      }),
                  value: Db.getUid(keg)
                };
        }));
  var form = Form.use({
        keg: Core__Option.getOr(Core__Option.map(options[0], (function (o) {
                    return o.value;
                  })), "")
      }, Validators$1.schema([Validators$1.required("Keg")]), (function (param) {
          onSubmit(param.state.values);
        }), undefined, undefined, "OnDemand", undefined);
  return JsxRuntime.jsx(DialogForm.make, {
              children: JsxRuntime.jsx(Form.Provider.make, {
                    value: form,
                    children: JsxRuntime.jsx("form", {
                          children: JsxRuntime.jsx("fieldset", {
                                children: JsxRuntime.jsx(Form.Field.make, {
                                      field: "Keg",
                                      render: (function (field) {
                                          return JsxRuntime.jsx(InputWrapper.make, {
                                                      inputName: "keg",
                                                      inputSlot: JsxRuntime.jsx("select", {
                                                            children: options.map(function (param) {
                                                                  var value = param.value;
                                                                  return JsxRuntime.jsx("option", {
                                                                              children: param.text,
                                                                              value: value
                                                                            }, value);
                                                                }),
                                                            value: field.value,
                                                            onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                          }),
                                                      labelSlot: "Sud"
                                                    });
                                        })
                                    }),
                                className: "reset"
                              }),
                          id: "tapKegOn",
                          onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                        })
                  }),
              formId: "tapKegOn",
              heading: "Narazit pípu: " + props.tapName,
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = TapKegOn;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
