// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Header from "../Header/Header.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactIntl from "react-intl";
import * as RouterUtils from "../../utils/RouterUtils.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function PlaceHeader(props) {
  var createdDate = props.createdTimestamp.toDate();
  var newrecord = Caml_obj.obj_dup(RouterUtils.createAnchorProps(undefined, "../"));
  return JsxRuntime.jsx(Header.make, {
              buttonLeftSlot: JsxRuntime.jsxs("a", (newrecord.className = Header.classes.buttonLeft, newrecord.children = [
                      JsxRuntime.jsx("span", {
                            children: "↩️"
                          }),
                      JsxRuntime.jsx("span", {
                            children: "Zpět"
                          })
                    ], newrecord)),
              buttonRightSlot: props.buttonRightSlot,
              className: props.className,
              headingSlot: props.placeName,
              subheadingSlot: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "Place.established",
                    defaultMessage: "Založeno {time}",
                    values: {
                      time: JsxRuntime.jsx("time", {
                            children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                  value: createdDate
                                }),
                            dateTime: createdDate.toISOString()
                          })
                    }
                  })
            });
}

var make = PlaceHeader;

export {
  make ,
}
/* Header Not a pure module */
