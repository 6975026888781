// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as React from "react";
import * as Assets from "../../assets/Assets.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Core__Null from "@rescript/core/src/Core__Null.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as SectionWithHeader from "../../components/SectionWithHeader/SectionWithHeader.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import BeerListModuleCss from "./BeerList.module.css";

var classes = BeerListModuleCss;

function BeerList$PersonCell(props) {
  var onAddConsumption = props.onAddConsumption;
  var isUserAuthorized = props.isUserAuthorized;
  var formatConsumption = props.formatConsumption;
  var consumptions = props.consumptions;
  var rootNodeRef = React.useRef(null);
  var consumptionsStr = consumptions.map(function (c) {
          return formatConsumption(c.milliliters);
        }).join("");
  var recentConsumptionTimestamp = Core__Option.map(consumptions.at(-1), (function (c) {
          return c.createdAt.getTime();
        }));
  var prevRecentConsumptionTimestampRef = React.useRef(recentConsumptionTimestamp);
  React.useEffect((function () {
          var prevRecentConsumptionTimestamp = prevRecentConsumptionTimestampRef.current;
          prevRecentConsumptionTimestampRef.current = recentConsumptionTimestamp;
          var listElement = rootNodeRef.current;
          if (!(listElement == null)) {
            var initialEffect = recentConsumptionTimestamp === prevRecentConsumptionTimestamp;
            if (!initialEffect) {
              var changedDueToExpiredConsumptions = recentConsumptionTimestamp === undefined && Core__Option.mapOr(prevRecentConsumptionTimestamp, false, (function (t) {
                      return Date.now() - t > Db.slidingWindowInMillis;
                    }));
              if (!changedDueToExpiredConsumptions) {
                listElement.animate({
                      backgroundColor: "var(--surface-warning)"
                    }, {
                      duration: 500,
                      iterations: 3,
                      direction: "reverse"
                    });
              }
              
            }
            
          }
          
        }), [recentConsumptionTimestamp]);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: props.personName
                    }),
                isUserAuthorized(50) || props.isCurrent && isUserAuthorized(20) ? JsxRuntime.jsx("button", {
                        className: Styles.utility.breakout,
                        title: "Detail konzumace",
                        type: "button",
                        onClick: (function (param) {
                            onAddConsumption();
                          })
                      }) : null,
                JsxRuntime.jsx("div", {
                      children: consumptionsStr,
                      className: classes.consumption
                    })
              ],
              ref: Caml_option.some(rootNodeRef),
              className: classes.cellConsumption,
              role: "gridcell"
            });
}

var PersonCell = {
  make: BeerList$PersonCell
};

function BeerList(props) {
  var recentConsumptionsByUser = props.recentConsumptionsByUser;
  var personEntries = props.personEntries;
  var onTogglePersonVisibility = props.onTogglePersonVisibility;
  var onAddPerson = props.onAddPerson;
  var onAddConsumption = props.onAddConsumption;
  var isUserAuthorized = props.isUserAuthorized;
  var formatConsumption = props.formatConsumption;
  var currentUserUid = props.currentUserUid;
  var match = React.useState(function () {
        return false;
      });
  var setShowAll = match[1];
  var showAll = match[0];
  var personsToShow = React.useMemo((function () {
          if (showAll) {
            return personEntries;
          } else {
            return personEntries.filter(function (param) {
                        return Db.isPersonActive(param[1]);
                      });
          }
        }), [
        personEntries,
        showAll
      ]);
  return JsxRuntime.jsx(SectionWithHeader.make, {
              children: !showAll && personsToShow.length === 0 ? JsxRuntime.jsxs("p", {
                      children: [
                        JsxRuntime.jsx("span", {
                              children: "Všichni jsou schovaní, koukni na ně přes očičko"
                            }),
                        JsxRuntime.jsx("span", {
                              children: "⤴"
                            })
                      ],
                      className: SectionWithHeader.classes.emptyMessage + " " + classes.emptyMessage
                    }) : JsxRuntime.jsx("div", {
                      children: personsToShow.map(function (personEntry) {
                            var person = personEntry[1];
                            var personId = personEntry[0];
                            var consumptions = Core__Option.getOr(recentConsumptionsByUser.get(personId), []);
                            var isCurrent = Core__Null.mapOr(person.userId, false, (function (userId) {
                                    return userId === currentUserUid;
                                  }));
                            var personVisible = !showAll || Db.isPersonActive(person);
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(BeerList$PersonCell, {
                                                consumptions: consumptions,
                                                formatConsumption: formatConsumption,
                                                isCurrent: isCurrent,
                                                isUserAuthorized: isUserAuthorized,
                                                onAddConsumption: (function () {
                                                    onAddConsumption(personEntry);
                                                  }),
                                                personName: person.name
                                              }, personId),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsxs("label", {
                                                      children: [
                                                        JsxRuntime.jsx("input", {
                                                              role: "switch",
                                                              checked: personVisible,
                                                              type: "checkbox",
                                                              onChange: (function (param) {
                                                                  onTogglePersonVisibility(personEntry);
                                                                })
                                                            }),
                                                        JsxRuntime.jsx("img", {
                                                              src: personVisible ? Assets.eyeShow : Assets.eyeHidden
                                                            })
                                                      ]
                                                    }),
                                                className: classes.cellToggleVisibility,
                                                role: "gridcell"
                                              })
                                        ],
                                        "aria-current": isCurrent ? "true" : "false",
                                        role: "row"
                                      }, personId);
                          }),
                      className: classes.gridBeer,
                      role: "grid"
                    }),
              buttonsSlot: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      isUserAuthorized(50) ? JsxRuntime.jsx("button", {
                              children: "Nový host",
                              className: Styles.button.base,
                              type: "button",
                              onClick: (function (param) {
                                  onAddPerson();
                                })
                            }) : null,
                      React.cloneElement(JsxRuntime.jsxs("label", {
                                children: [
                                  "👁️",
                                  JsxRuntime.jsx("span", {
                                        children: "Zobrazit všechny hosty",
                                        className: Styles.utility.srOnly
                                      }),
                                  JsxRuntime.jsx("input", {
                                        role: "switch",
                                        checked: showAll,
                                        type: "checkbox",
                                        onChange: (function (e) {
                                            var target = e.target;
                                            var checked = target.checked;
                                            setShowAll(function (param) {
                                                  return checked;
                                                });
                                          })
                                      })
                                ],
                                className: classes.labelShowAll + " " + Styles.button.base + " " + Styles.button.iconOnly + " " + Styles.button.variantStealth
                              }), {
                            "data-checked": showAll ? "true" : "false"
                          })
                    ]
                  }),
              className: classes.root + " " + (
                showAll ? classes.showAll : ""
              ),
              headerId: "active_persons",
              headerSlot: "Lístek"
            });
}

var make = BeerList;

export {
  classes ,
  PersonCell ,
  make ,
}
/* classes Not a pure module */
