// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as ReactIntl from "react-intl";
import * as FormattedVolume from "../../components/FormattedVolume/FormattedVolume.bs.js";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as SectionWithHeader from "../../components/SectionWithHeader/SectionWithHeader.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import ChargedKegsModuleCss from "./ChargedKegs.module.css";

var classes = ChargedKegsModuleCss;

function ChargedKegs(props) {
  var onKegDetail = props.onKegDetail;
  var onAddNewKeg = props.onAddNewKeg;
  var chargedKegs = props.chargedKegs;
  return JsxRuntime.jsx(SectionWithHeader.make, {
              children: chargedKegs.length !== 0 ? JsxRuntime.jsxs("table", {
                      children: [
                        JsxRuntime.jsx("thead", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("th", {
                                            children: "No.",
                                            scope: "col"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Pivo",
                                            scope: "col"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Naskladněno",
                                            scope: "col"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Cena",
                                            scope: "col"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Objem",
                                            scope: "col"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Zbývá",
                                            scope: "col"
                                          })
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx("tbody", {
                              children: chargedKegs.map(function (keg) {
                                    var kegId = Db.getUid(keg);
                                    var volume = keg.milliliters;
                                    return JsxRuntime.jsxs("tr", {
                                                children: [
                                                  JsxRuntime.jsx("th", {
                                                        children: keg.serialFormatted,
                                                        scope: "row"
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: keg.beer
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                              value: keg.createdAt.toDate()
                                                            })
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                              value: keg.price
                                                            })
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsx(FormattedVolume.make, {
                                                              milliliters: volume
                                                            })
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsx(FormattedVolume.make, {
                                                              milliliters: volume - keg.consumptionsSum | 0
                                                            })
                                                      })
                                                ],
                                                onClick: (function (param) {
                                                    onKegDetail(kegId);
                                                  })
                                              }, kegId);
                                  })
                            })
                      ],
                      className: classes.table
                    }) : JsxRuntime.jsx("div", {
                      children: "Naskladni sudy tlačítkem ⤴",
                      className: classes.emptyTableMessage
                    }),
              buttonsSlot: JsxRuntime.jsx("button", {
                    children: "Přidat sud",
                    className: Styles.button.base,
                    type: "button",
                    onClick: (function (param) {
                        onAddNewKeg();
                      })
                  }),
              headerId: "charged_kegs_setting",
              headerSlot: "Sudy na skladě"
            });
}

var make = ChargedKegs;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
