// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../utils/Hooks.bs.js";
import * as React from "react";
import * as DomUtils from "../../utils/DomUtils.bs.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as Reactfire from "reactfire";
import * as AppStorage from "../../utils/AppStorage.bs.js";
import * as SignInForm from "./SignInForm.bs.js";
import * as SignUpForm from "./SignUpForm.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Auth from "firebase/auth";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Functions from "firebase/functions";
import * as ForgottenPasswordForm from "./ForgottenPasswordForm.bs.js";
import * as ForgottenPasswordSent from "./ForgottenPasswordSent.bs.js";
import * as Browser from "@firebase-web-authn/browser";

function Unauthenticated(props) {
  var app = Reactfire.useFirebaseApp();
  var auth = Reactfire.useAuth();
  var match = React.useState(function () {
        return "SignIn";
      });
  var setView = match[1];
  var view = match[0];
  var match$1 = Hooks.usePromise(function () {
        var functions = Functions.getFunctions(app, "us-central1");
        return Browser.signInWithPasskey(auth, functions);
      });
  var runSignInWithPasskey = match$1[1];
  var isOnlineStatus = DomUtils.useIsOnline();
  var isOnline = isOnlineStatus.data;
  var supportsWebAuthn = DomUtils.useSuportsPlatformWebauthn();
  var webAuthn = AppStorage.useLocalStorage(AppStorage.keyWebAuthn)[0];
  var webAuthnReady = supportsWebAuthn && webAuthn === "1";
  var startWebAuthnAutomatically = React.useRef(isOnline === true);
  React.useEffect((function () {
          if (startWebAuthnAutomatically.current) {
            startWebAuthnAutomatically.current = false;
            if (webAuthnReady) {
              runSignInWithPasskey();
            }
            
          }
          
        }), []);
  if (typeof view !== "object") {
    if (view === "SignIn") {
      return JsxRuntime.jsx(SignInForm.make, {
                  isOnline: isOnline,
                  loadingOverlay: match$1[0].state === "pending",
                  onForgottenPassword: (function (email) {
                      setView(function (param) {
                            return {
                                    TAG: "ForgotPassword",
                                    _0: email
                                  };
                          });
                    }),
                  onSignInWithGoogle: (function () {
                      Core__Promise.$$catch(Auth.signInWithRedirect(auth, new Auth.GoogleAuthProvider()), (function (error) {
                              var exn = Core__Option.getExn(Caml_js_exceptions.as_js_exn(error), undefined);
                              LogUtils.captureException(exn);
                              return Promise.reject(error);
                            }));
                    }),
                  onSignInWithPasskey: webAuthnReady ? runSignInWithPasskey : undefined,
                  onSignInWithPassword: (function (param) {
                      return Auth.signInWithEmailAndPassword(auth, param.email, param.password).then(function (param) {
                                  
                                });
                    }),
                  onSignUp: (function () {
                      setView(function (param) {
                            return "SignUp";
                          });
                    })
                });
    } else {
      return JsxRuntime.jsx(SignUpForm.make, {
                  isOnline: isOnline,
                  onSubmit: (function (param) {
                      return Auth.createUserWithEmailAndPassword(auth, param.email, param.password).then(function (param) {
                                  
                                });
                    }),
                  onGoBack: (function () {
                      setView(function (param) {
                            return "SignIn";
                          });
                    })
                });
    }
  } else if (view.TAG === "ForgotPassword") {
    return JsxRuntime.jsx(ForgottenPasswordForm.make, {
                initialEmail: view._0,
                isOnline: isOnline,
                onGoBack: (function () {
                    setView(function (param) {
                          return "SignIn";
                        });
                  }),
                onSubmit: (function (values) {
                    return Auth.sendPasswordResetEmail(auth, values.email).then(function () {
                                setView(function (param) {
                                      return {
                                              TAG: "ForgotPasswordSent",
                                              _0: values.email
                                            };
                                    });
                                return Promise.resolve();
                              });
                  })
              });
  } else {
    return JsxRuntime.jsx(ForgottenPasswordSent.make, {
                email: view._0,
                onGoBack: (function () {
                    setView(function (param) {
                          return "SignIn";
                        });
                  })
              });
  }
}

var make = Unauthenticated;

export {
  make ,
}
/* Hooks Not a pure module */
