// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Reactfire from "reactfire";
import * as Onboarding from "../../pages/Onboarding/Onboarding.bs.js";
import * as Unauthenticated from "../../pages/Unauthenticated/Unauthenticated.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FirebaseFirestoreProvider from "../FirebaseFirestoreProvider/FirebaseFirestoreProvider.bs.js";

function SignInWrapper(props) {
  var signInStatus = Reactfire.useSigninCheck();
  var match = signInStatus.data;
  if (match === undefined) {
    return null;
  }
  var maybeUser = match.user;
  if (maybeUser !== null && !maybeUser.isAnonymous) {
    return JsxRuntime.jsx(FirebaseFirestoreProvider.make, {
                children: JsxRuntime.jsx(Onboarding.make, {
                      children: props.children,
                      user: maybeUser
                    })
              });
  } else {
    return JsxRuntime.jsx(Unauthenticated.make, {});
  }
}

var make = SignInWrapper;

export {
  make ,
}
/* reactfire Not a pure module */
