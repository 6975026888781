// Generated by ReScript, PLEASE EDIT WITH CARE


var dayInSeconds = 86400;

var weekInSeconds = Math.imul(7, dayInSeconds);

var monthInSeconds = Math.imul(31, dayInSeconds);

var hourInMilliseconds = 3600000;

var dayInMilliseconds = Math.imul(dayInSeconds, 1000);

var weekInMilliseconds = Math.imul(weekInSeconds, 1000);

var monthInMilliseconds = Math.imul(monthInSeconds, 1000);

function toIsoDateString(date) {
  return date.toISOString().substring(0, 10);
}

var dateIsoRe = /^\d{4,4}-\d{2,2}-\d{2,2}$/;

function fromIsoDateString(isoDateString) {
  if (dateIsoRe.test(isoDateString)) {
    return new Date(isoDateString + "T00:00");
  }
  throw {
        RE_EXN_ID: "Invalid_argument",
        _1: "fromIsoDateString",
        Error: new Error()
      };
}

function compare(a, b) {
  return a.getTime() - b.getTime();
}

var hourInSeconds = 3600;

export {
  hourInSeconds ,
  dayInSeconds ,
  weekInSeconds ,
  monthInSeconds ,
  hourInMilliseconds ,
  dayInMilliseconds ,
  weekInMilliseconds ,
  monthInMilliseconds ,
  toIsoDateString ,
  dateIsoRe ,
  fromIsoDateString ,
  compare ,
}
/* No side effect */
