// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactIntl from "react-intl";
import * as JsxRuntime from "react/jsx-runtime";

function FormattedDateTime(props) {
  return JsxRuntime.jsx(ReactIntl.FormattedDate, {
              value: props.value,
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            });
}

var make = FormattedDateTime;

export {
  make ,
}
/* react-intl Not a pure module */
