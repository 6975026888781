// Generated by ReScript, PLEASE EDIT WITH CARE


var quot = "\u0022";

var amp = "&";

var apos = "\u0027";

var lt = "<";

var gt = ">";

var nbsp = "\u00A0";

var nbhp = "\u2011";

var iexcl = "\u00A1";

var cent = "\u00A2";

var pound = "\u00A3";

var curren = "\u00A4";

var yen = "\u00A5";

var brvbar = "\u00A6";

var sect = "\u00A7";

var uml = "\u00A8";

var copy = "\u00A9";

var ordf = "\u00AA";

var laquo = "\u00AB";

var not = "\u00AC";

var shy = "\u00AD";

var reg = "\u00AE";

var macr = "\u00AF";

var deg = "\u00B0";

var plusmn = "\u00B1";

var sup2 = "\u00B2";

var sup3 = "\u00B3";

var acute = "\u00B4";

var micro = "\u00B5";

var para = "\u00B6";

var middot = "\u00B7";

var cedil = "\u00B8";

var sup1 = "\u00B9";

var ordm = "\u00BA";

var raquo = "\u00BB";

var frac14 = "\u00BC";

var frac12 = "\u00BD";

var frac34 = "\u00BE";

var iquest = "\u00BF";

var _Agrave = "\u00C0";

var _Aacute = "\u00C1";

var _Acirc = "\u00C2";

var _Atilde = "\u00C3";

var _Auml = "\u00C4";

var _Aring = "\u00C5";

var _AElig = "\u00C6";

var _Ccedil = "\u00C7";

var _Egrave = "\u00C8";

var _Eacute = "\u00C9";

var _Ecirc = "\u00CA";

var _Euml = "\u00CB";

var _Igrave = "\u00CC";

var _Iacute = "\u00CD";

var _Icirc = "\u00CE";

var _Iuml = "\u00CF";

var _ETH = "\u00D0";

var _Ntilde = "\u00D1";

var _Ograve = "\u00D2";

var _Oacute = "\u00D3";

var _Ocirc = "\u00D4";

var _Otilde = "\u00D5";

var _Ouml = "\u00D6";

var times = "\u00D7";

var _Oslash = "\u00D8";

var _Ugrave = "\u00D9";

var _Uacute = "\u00DA";

var _Ucirc = "\u00DB";

var _Uuml = "\u00DC";

var _Yacute = "\u00DD";

var _THORN = "\u00DE";

var szlig = "\u00DF";

var agrave = "\u00E0";

var aacute = "\u00E1";

var acirc = "\u00E2";

var atilde = "\u00E3";

var auml = "\u00E4";

var aring = "\u00E5";

var aelig = "\u00E6";

var ccedil = "\u00E7";

var egrave = "\u00E8";

var eacute = "\u00E9";

var ecirc = "\u00EA";

var euml = "\u00EB";

var igrave = "\u00EC";

var iacute = "\u00ED";

var icirc = "\u00EE";

var iuml = "\u00EF";

var eth = "\u00F0";

var ntilde = "\u00F1";

var ograve = "\u00F2";

var oacute = "\u00F3";

var ocirc = "\u00F4";

var otilde = "\u00F5";

var ouml = "\u00F6";

var divide = "\u00F7";

var oslash = "\u00F8";

var ugrave = "\u00F9";

var uacute = "\u00FA";

var ucirc = "\u00FB";

var uuml = "\u00FC";

var yacute = "\u00FD";

var thorn = "\u00FE";

var yuml = "\u00FF";

var _OElig = "\u0152";

var oelig = "\u0153";

var _Scaron = "\u0160";

var scaron = "\u0161";

var _Yuml = "\u0178";

var fnof = "\u0192";

var circ = "\u02C6";

var tilde = "\u02DC";

var _Alpha = "\u0391";

var _Beta = "\u0392";

var _Gamma = "\u0393";

var _Delta = "\u0394";

var _Epsilon = "\u0395";

var _Zeta = "\u0396";

var _Eta = "\u0397";

var _Theta = "\u0398";

var _Iota = "\u0399";

var _Kappa = "\u039A";

var _Lambda = "\u039B";

var _Mu = "\u039C";

var _Nu = "\u039D";

var _Xi = "\u039E";

var _Omicron = "\u039F";

var _Pi = "\u03A0";

var _Rho = "\u03A1";

var _Sigma = "\u03A3";

var _Tau = "\u03A4";

var _Upsilon = "\u03A5";

var _Phi = "\u03A6";

var _Chi = "\u03A7";

var _Psi = "\u03A8";

var _Omega = "\u03A9";

var alpha = "\u03B1";

var beta = "\u03B2";

var gamma = "\u03B3";

var delta = "\u03B4";

var epsilon = "\u03B5";

var zeta = "\u03B6";

var eta = "\u03B7";

var theta = "\u03B8";

var iota = "\u03B9";

var kappa = "\u03BA";

var lambda = "\u03BB";

var mu = "\u03BC";

var nu = "\u03BD";

var xi = "\u03BE";

var omicron = "\u03BF";

var pi = "\u03C0";

var rho = "\u03C1";

var sigmaf = "\u03C2";

var sigma = "\u03C3";

var tau = "\u03C4";

var upsilon = "\u03C5";

var phi = "\u03C6";

var chi = "\u03C7";

var psi = "\u03C8";

var omega = "\u03C9";

var thetasym = "\u03D1";

var upsih = "\u03D2";

var piv = "\u03D6";

var ensp = "\u2002";

var emsp = "\u2003";

var thinsp = "\u2009";

var zwnj = "\u200C";

var zwj = "\u200D";

var lrm = "\u200E";

var rlm = "\u200F";

var ndash = "\u2013";

var mdash = "\u2014";

var lsquo = "\u2018";

var rsquo = "\u2019";

var sbquo = "\u201A";

var ldquo = "\u201C";

var rdquo = "\u201D";

var bdquo = "\u201E";

var dagger = "\u2020";

var _Dagger = "\u2021";

var bull = "\u2022";

var hellip = "\u2026";

var permil = "\u2030";

var prime = "\u2032";

var _Prime = "\u2033";

var lsaquo = "\u2039";

var rsaquo = "\u203A";

var oline = "\u203E";

var frasl = "\u2044";

var euro = "\u20AC";

var image = "\u2111";

var weierp = "\u2118";

var real = "\u211C";

var trade = "\u2122";

var alefsym = "\u2135";

var larr = "\u2190";

var uarr = "\u2191";

var rarr = "\u2192";

var darr = "\u2193";

var harr = "\u2194";

var crarr = "\u21B5";

var lArr = "\u21D0";

var uArr = "\u21D1";

var rArr = "\u21D2";

var dArr = "\u21D3";

var hArr = "\u21D4";

var forall = "\u2200";

var part = "\u2202";

var exist = "\u2203";

var empty = "\u2205";

var nabla = "\u2207";

var isin = "\u2208";

var notin = "\u2209";

var ni = "\u220B";

var prod = "\u220F";

var sum = "\u2211";

var minus = "\u2212";

var lowast = "\u2217";

var radic = "\u221A";

var prop = "\u221D";

var infin = "\u221E";

var ang = "\u2220";

var and_ = "\u2227";

var or_ = "\u2228";

var cap = "\u2229";

var cup = "\u222A";

var $$int = "\u222B";

var there4 = "\u2234";

var sim = "\u223C";

var cong = "\u2245";

var asymp = "\u2248";

var ne = "\u2260";

var equiv = "\u2261";

var le = "\u2264";

var ge = "\u2265";

var sub = "\u2282";

var sup = "\u2283";

var nsub = "\u2284";

var sube = "\u2286";

var supe = "\u2287";

var oplus = "\u2295";

var otimes = "\u2297";

var perp = "\u22A5";

var sdot = "\u22C5";

var lceil = "\u2308";

var rceil = "\u2309";

var lfloor = "\u230A";

var rfloor = "\u230B";

var lang = "\u2329";

var rang = "\u232A";

var loz = "\u25CA";

var spades = "\u2660";

var clubs = "\u2663";

var hearts = "\u2665";

var diams = "\u2666";

export {
  quot ,
  amp ,
  apos ,
  lt ,
  gt ,
  nbsp ,
  nbhp ,
  iexcl ,
  cent ,
  pound ,
  curren ,
  yen ,
  brvbar ,
  sect ,
  uml ,
  copy ,
  ordf ,
  laquo ,
  not ,
  shy ,
  reg ,
  macr ,
  deg ,
  plusmn ,
  sup2 ,
  sup3 ,
  acute ,
  micro ,
  para ,
  middot ,
  cedil ,
  sup1 ,
  ordm ,
  raquo ,
  frac14 ,
  frac12 ,
  frac34 ,
  iquest ,
  _Agrave ,
  _Aacute ,
  _Acirc ,
  _Atilde ,
  _Auml ,
  _Aring ,
  _AElig ,
  _Ccedil ,
  _Egrave ,
  _Eacute ,
  _Ecirc ,
  _Euml ,
  _Igrave ,
  _Iacute ,
  _Icirc ,
  _Iuml ,
  _ETH ,
  _Ntilde ,
  _Ograve ,
  _Oacute ,
  _Ocirc ,
  _Otilde ,
  _Ouml ,
  times ,
  _Oslash ,
  _Ugrave ,
  _Uacute ,
  _Ucirc ,
  _Uuml ,
  _Yacute ,
  _THORN ,
  szlig ,
  agrave ,
  aacute ,
  acirc ,
  atilde ,
  auml ,
  aring ,
  aelig ,
  ccedil ,
  egrave ,
  eacute ,
  ecirc ,
  euml ,
  igrave ,
  iacute ,
  icirc ,
  iuml ,
  eth ,
  ntilde ,
  ograve ,
  oacute ,
  ocirc ,
  otilde ,
  ouml ,
  divide ,
  oslash ,
  ugrave ,
  uacute ,
  ucirc ,
  uuml ,
  yacute ,
  thorn ,
  yuml ,
  _OElig ,
  oelig ,
  _Scaron ,
  scaron ,
  _Yuml ,
  fnof ,
  circ ,
  tilde ,
  _Alpha ,
  _Beta ,
  _Gamma ,
  _Delta ,
  _Epsilon ,
  _Zeta ,
  _Eta ,
  _Theta ,
  _Iota ,
  _Kappa ,
  _Lambda ,
  _Mu ,
  _Nu ,
  _Xi ,
  _Omicron ,
  _Pi ,
  _Rho ,
  _Sigma ,
  _Tau ,
  _Upsilon ,
  _Phi ,
  _Chi ,
  _Psi ,
  _Omega ,
  alpha ,
  beta ,
  gamma ,
  delta ,
  epsilon ,
  zeta ,
  eta ,
  theta ,
  iota ,
  kappa ,
  lambda ,
  mu ,
  nu ,
  xi ,
  omicron ,
  pi ,
  rho ,
  sigmaf ,
  sigma ,
  tau ,
  upsilon ,
  phi ,
  chi ,
  psi ,
  omega ,
  thetasym ,
  upsih ,
  piv ,
  ensp ,
  emsp ,
  thinsp ,
  zwnj ,
  zwj ,
  lrm ,
  rlm ,
  ndash ,
  mdash ,
  lsquo ,
  rsquo ,
  sbquo ,
  ldquo ,
  rdquo ,
  bdquo ,
  dagger ,
  _Dagger ,
  bull ,
  hellip ,
  permil ,
  prime ,
  _Prime ,
  lsaquo ,
  rsaquo ,
  oline ,
  frasl ,
  euro ,
  image ,
  weierp ,
  real ,
  trade ,
  alefsym ,
  larr ,
  uarr ,
  rarr ,
  darr ,
  harr ,
  crarr ,
  lArr ,
  uArr ,
  rArr ,
  dArr ,
  hArr ,
  forall ,
  part ,
  exist ,
  empty ,
  nabla ,
  isin ,
  notin ,
  ni ,
  prod ,
  sum ,
  minus ,
  lowast ,
  radic ,
  prop ,
  infin ,
  ang ,
  and_ ,
  or_ ,
  cap ,
  cup ,
  $$int ,
  there4 ,
  sim ,
  cong ,
  asymp ,
  ne ,
  equiv ,
  le ,
  ge ,
  sub ,
  sup ,
  nsub ,
  sube ,
  supe ,
  oplus ,
  otimes ,
  perp ,
  sdot ,
  lceil ,
  rceil ,
  lfloor ,
  rfloor ,
  lang ,
  rang ,
  loz ,
  spades ,
  clubs ,
  hearts ,
  diams ,
}
/* No side effect */
