// Generated by ReScript, PLEASE EDIT WITH CARE


function default_false_() {
  return "This value should be false";
}

function default_true_() {
  return "This value should be true";
}

function default_intMin(_value, min) {
  return "This value must be greater than or equal to " + String(min);
}

function default_intMax(_value, max) {
  return "This value must be less than or equal to " + String(max);
}

function default_floatMin(_value, min) {
  return "This value must be greater than or equal to " + min.toString();
}

function default_floatMax(_value, max) {
  return "This value must be less than or equal to " + max.toString();
}

function default_email(value) {
  return value + " is not a valid email";
}

function default_stringNonEmpty(param) {
  return "String must not be empty";
}

function default_stringRegExp(_value, pattern) {
  return "This value must match the following: /" + pattern + "/";
}

function default_stringMin(_value, min) {
  return "This value must be at least " + String(min) + " characters";
}

function default_stringMax(_value, max) {
  return "This value must be at most " + String(max) + " characters";
}

var $$default = {
  false_: default_false_,
  true_: default_true_,
  intMin: default_intMin,
  intMax: default_intMax,
  floatMin: default_floatMin,
  floatMax: default_floatMax,
  email: default_email,
  stringNonEmpty: default_stringNonEmpty,
  stringRegExp: default_stringRegExp,
  stringMin: default_stringMin,
  stringMax: default_stringMax
};

export {
  $$default as default,
}
/* No side effect */
