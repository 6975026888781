// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as JsxRuntime from "react/jsx-runtime";

function FormattedVolume(props) {
  var liters = props.milliliters / 1000.0;
  return React.cloneElement(JsxRuntime.jsx(ReactIntl.FormattedNumber, {
                  value: liters,
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  children: props.children
                }), {
              style: "unit",
              unit: "liter"
            });
}

var make = FormattedVolume;

export {
  make ,
}
/* react Not a pure module */
