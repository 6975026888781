// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as OnboardingTemplate from "./OnboardingTemplate.bs.js";

function OnboardingThrustDevice(props) {
  var onThrust = props.onThrust;
  var onSkip = props.onSkip;
  return JsxRuntime.jsxs(OnboardingTemplate.make, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Ukládání dat"
                    }),
                JsxRuntime.jsxs("p", {
                      children: [
                        "Aplikace umí pracovat i bez internetu",
                        props.mentionWebAuthn ? " nebo přihlašování bez hesla, například otiskem prstu nebo obličejem." : ".",
                        " K tomu potřebuje ukládat data do zařízení."
                      ],
                      className: Styles.fieldset.gridSpan
                    }),
                JsxRuntime.jsx("p", {
                      children: "Pokud serfuješ na cizím počítači a nechceš aby se k datům dostal někdo cizí, tento krok přeskoč."
                    }),
                JsxRuntime.jsx("button", {
                      children: "Tomuto zařízení důvěřuji",
                      className: Styles.button.base,
                      type: "button",
                      onClick: (function (param) {
                          onThrust();
                        })
                    }),
                JsxRuntime.jsx("button", {
                      children: "Na tomto zařízení nechci ukládat svá data",
                      className: Styles.link.base,
                      type: "button",
                      onClick: (function (param) {
                          onSkip();
                        })
                    })
              ]
            });
}

var make = OnboardingThrustDevice;

export {
  make ,
}
/* Styles Not a pure module */
