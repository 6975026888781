// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dialog from "../Dialog/Dialog.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import DialogCyclingModuleCss from "./DialogCycling.module.css";

var classes = DialogCyclingModuleCss;

function DialogCycling(props) {
  var onPrevious = props.onPrevious;
  var onNext = props.onNext;
  var onDismiss = props.onDismiss;
  return JsxRuntime.jsxs(Dialog.make, {
              children: [
                JsxRuntime.jsxs("header", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: props.header
                            }),
                        JsxRuntime.jsx("button", {
                              children: "✖️",
                              className: Styles.button.iconOnly,
                              title: "Zavřít dialog",
                              onClick: (function (param) {
                                  onDismiss();
                                })
                            })
                      ]
                    }),
                JsxRuntime.jsx(Dialog.DialogBody.make, {
                      children: props.children
                    }),
                JsxRuntime.jsxs("footer", {
                      children: [
                        JsxRuntime.jsx("button", {
                              children: "⬅️ Předchozí",
                              className: Styles.button.base,
                              disabled: !props.hasPrevious,
                              type: "button",
                              onClick: (function (param) {
                                  onPrevious();
                                })
                            }),
                        JsxRuntime.jsx("button", {
                              children: "Následující ➡️",
                              className: Styles.button.base,
                              disabled: !props.hasNext,
                              type: "button",
                              onClick: (function (param) {
                                  onNext();
                                })
                            })
                      ]
                    })
              ],
              className: classes.root + " " + Core__Option.getOr(props.className, ""),
              visible: props.visible
            });
}

var make = DialogCycling;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
