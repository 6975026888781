// Generated by ReScript, PLEASE EDIT WITH CARE


function handleChange(handleChange$1) {
  return function ($$event) {
    return handleChange$1($$event.target.value);
  };
}

function handleSubmit(handleSubmit$1) {
  return function ($$event) {
    $$event.preventDefault();
    return handleSubmit$1();
  };
}

export {
  handleChange ,
  handleSubmit ,
}
/* No side effect */
