// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as JsxRuntime from "react/jsx-runtime";

var make = React.memo(function (props) {
      var timeDiff = (props.dateTime.getTime() - Date.now()) / 1000.0;
      return JsxRuntime.jsx(ReactIntl.FormattedRelativeTime, {
                  value: timeDiff,
                  numeric: "auto",
                  updateIntervalInSeconds: 1.0
                });
    });

export {
  make ,
}
/* make Not a pure module */
