// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UserRolesTs from "./UserRoles.ts";

var userRole = UserRolesTs.UserRole;

function roleFromInt(role) {
  if (role >= 50) {
    if (role !== 80) {
      if (role !== 100) {
        if (role >= 51) {
          return ;
        } else {
          return 50;
        }
      } else {
        return 100;
      }
    } else {
      return 80;
    }
  } else if (role !== 10) {
    if (role !== 20) {
      return ;
    } else {
      return 20;
    }
  } else {
    return 10;
  }
}

function roleI18n(role) {
  switch (role) {
    case 10 :
        return "Pozorovatel";
    case 20 :
        return "Kumpán";
    case 50 :
        return "Výčepní";
    case 80 :
        return "Správce";
    case 100 :
        return "Vlastník";
    
  }
}

function roleDescription(role) {
  switch (role) {
    case 10 :
        return "může sledovat lístek, ale nemůže psát čárky.";
    case 20 :
        return "může sledovat lístek a psát čárky sám sobě.";
    case 50 :
        return "může psát čárky komukoliv, může přidávat hosty a naskladňovat \n  nebo přerážet sudy. Nemůže ale provádět nevratné peněžní operace, jako je\n  dopití a rozúčtování sudu nebo zadávat platby.";
    case 80 :
        return "může dělat všechno, kromě úprav účtu vlastníka.";
    case 100 :
        return "může dělat úplně všechno, včetně převodu vlastnictví místa.";
    
  }
}

function isAuthorized(userRole, requiredRole) {
  return userRole >= requiredRole;
}

export {
  userRole ,
  roleFromInt ,
  roleI18n ,
  roleDescription ,
  isAuthorized ,
}
/* userRole Not a pure module */
