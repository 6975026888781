// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "./Db.bs.js";
import * as React from "react";
import * as Reactfire from "reactfire";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as BitwiseUtils from "../utils/BitwiseUtils.bs.js";
import * as Functions from "firebase/functions";

function useGetSubscibedUsers(currentUserUid, $$event, place) {
  return React.useMemo((function () {
                return Core__Array.filterMap(Object.entries(place.accounts), (function (param) {
                              var uid = param[0];
                              if (uid === currentUserUid || BitwiseUtils.bitAnd(param[1][1], $$event) === 0) {
                                return ;
                              } else {
                                return uid;
                              }
                            }));
              }), [
              currentUserUid,
              $$event,
              place
            ]);
}

function useDispatchFreeTableNotification(currentUserUid, place, recentConsumptionsByUser) {
  var firestore = Reactfire.useFirestore();
  var functions = Reactfire.useFunctions();
  var dispatchNotification = Functions.httpsCallable(functions, "dispatchNotification");
  var subsciredUsers = useGetSubscibedUsers(currentUserUid, 1, place);
  var freeTableSituation = React.useMemo((function () {
          if (subsciredUsers.length > 0) {
            return Array.from(recentConsumptionsByUser.values()).every(function (consumptions) {
                        return consumptions.length === 0;
                      });
          } else {
            return false;
          }
        }), [
        recentConsumptionsByUser,
        subsciredUsers
      ]);
  return function () {
    if (!freeTableSituation) {
      return ;
    }
    var placeRef = Db.placeDocument(firestore, Db.getUid(place));
    dispatchNotification({
          TAG: 1,
          place: placeRef.path,
          users: subsciredUsers
        });
  };
}

function useDispatchFreshKegNotification(currentUserUid, place) {
  var firestore = Reactfire.useFirestore();
  var functions = Reactfire.useFunctions();
  var dispatchNotification = Functions.httpsCallable(functions, "dispatchNotification");
  var subsciredUsers = useGetSubscibedUsers(currentUserUid, 2, place);
  return function (keg) {
    var freshKegSituation = subsciredUsers.length > 0 && keg.consumptionsSum === 0;
    if (!freshKegSituation) {
      return ;
    }
    var kegRef = Db.kegDoc(firestore, Db.getUid(place), Db.getUid(keg));
    dispatchNotification({
          TAG: 2,
          keg: kegRef.path,
          users: subsciredUsers
        });
  };
}

function useUpdateNotificationToken() {
  var functions = Reactfire.useFunctions();
  var updateDeviceToken = Functions.httpsCallable(functions, "updateNotificationToken");
  return function (deviceToken) {
    return updateDeviceToken({
                deviceToken: deviceToken
              });
  };
}

export {
  useGetSubscibedUsers ,
  useDispatchFreeTableNotification ,
  useDispatchFreshKegNotification ,
  useUpdateNotificationToken ,
}
/* Db Not a pure module */
