// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as HtmlEntities from "../../utils/HtmlEntities.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as FirebaseError from "../../backend/FirebaseError.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as UnauthenticatedTemplate from "./UnauthenticatedTemplate.bs.js";

function get(state, field) {
  return state.email;
}

function set(_state, field, value) {
  return {
          email: value
        };
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function ForgottenPasswordForm(props) {
  var onSubmit = props.onSubmit;
  var onGoBack = props.onGoBack;
  var form = Form.use({
        email: props.initialEmail
      }, Validators$1.schema([
            Validators$1.required("Email"),
            Validators$1.email("Email")
          ]), (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          Core__Promise.$$catch(onSubmit(param.state.values), (function (error) {
                  FirebaseError.toFirebaseError(error);
                  var exn = Core__Option.getExn(Caml_js_exceptions.as_js_exn(error), undefined);
                  LogUtils.captureException(exn);
                  var msg = exn.message;
                  var errorMessage = msg !== undefined ? "Chyba: " + msg : "Neznámá chyba";
                  raiseSubmitFailed(errorMessage);
                  return Promise.resolve();
                }));
        }), undefined, undefined, "OnDemand", undefined);
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba";
  }
  return JsxRuntime.jsxs(UnauthenticatedTemplate.make, {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Zapomenuté heslo"
                    }),
                JsxRuntime.jsxs(Form.Provider.make, {
                      value: form,
                      children: [
                        JsxRuntime.jsxs("form", {
                              children: [
                                JsxRuntime.jsx("fieldset", {
                                      children: JsxRuntime.jsx(Form.Field.make, {
                                            field: "Email",
                                            render: (function (field) {
                                                return JsxRuntime.jsx(InputWrapper.make, {
                                                            inputError: field.error,
                                                            inputName: "email",
                                                            inputSlot: JsxRuntime.jsx("input", {
                                                                  type: "email",
                                                                  value: field.value,
                                                                  onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                }),
                                                            labelSlot: "E" + HtmlEntities.nbhp + "mail"
                                                          });
                                              })
                                          }),
                                      className: "reset " + Styles.fieldset.grid
                                    }),
                                tmp,
                                JsxRuntime.jsx("button", {
                                      children: "Resetovat heslo",
                                      className: Styles.button.base,
                                      type: "submit"
                                    })
                              ],
                              className: Styles.stack.base,
                              onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                            }),
                        JsxRuntime.jsxs("p", {
                              children: [
                                "Jste tu omylem? ",
                                JsxRuntime.jsx("button", {
                                      children: "Zpět na přihlášení.",
                                      className: Styles.link.base,
                                      type: "button",
                                      onClick: (function (param) {
                                          onGoBack();
                                        })
                                    })
                              ]
                            })
                      ]
                    })
              ],
              isOnline: props.isOnline
            });
}

var make = ForgottenPasswordForm;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
