// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import DialogPolyfill from "dialog-polyfill";
import * as JsxRuntime from "react/jsx-runtime";
import DialogModuleCss from "./Dialog.module.css";
import * as Webapi__Dom__HtmlElement from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__HtmlElement.bs.js";

var classes = DialogModuleCss;

function Dialog(props) {
  var visible = props.visible;
  var onClickOutside = props.onClickOutside;
  var match = React.useState(function () {
        
      });
  var setDialogNode = match[1];
  var maybeDialogNode = match[0];
  var visibilityDeps = [
    maybeDialogNode,
    visible
  ];
  React.useEffect((function () {
          if (maybeDialogNode !== undefined) {
            var dialog = Caml_option.valFromOption(maybeDialogNode);
            if (visible) {
              dialog.showModal();
            } else {
              dialog.close();
            }
          }
          
        }), visibilityDeps);
  var onClickOutsideRef = React.useRef(onClickOutside);
  onClickOutsideRef.current = onClickOutside;
  var lightDismissibleDeps_1 = onClickOutside !== undefined;
  var lightDismissibleDeps = [
    maybeDialogNode,
    lightDismissibleDeps_1
  ];
  React.useEffect((function () {
          if (maybeDialogNode === undefined) {
            return ;
          }
          if (!lightDismissibleDeps_1) {
            return ;
          }
          var dialogElement = Webapi__Dom__HtmlElement.ofElement(Caml_option.valFromOption(maybeDialogNode));
          if (dialogElement === undefined) {
            return ;
          }
          var dialogElement$1 = Caml_option.valFromOption(dialogElement);
          var handler = function ($$event) {
            var targetIsDialog = $$event.target.nodeName === "DIALOG";
            var match = onClickOutsideRef.current;
            if (targetIsDialog && match !== undefined) {
              return match();
            }
            
          };
          dialogElement$1.addEventListener("click", handler);
          return (function () {
                    dialogElement$1.removeEventListener("click", handler);
                  });
        }), lightDismissibleDeps);
  return JsxRuntime.jsx("dialog", {
              children: props.children,
              ref: Caml_option.some(function (node) {
                    setDialogNode(function (param) {
                          if (!(node == null)) {
                            DialogPolyfill.registerDialog(node);
                            return Caml_option.some(node);
                          }
                          
                        });
                  }),
              className: classes.root + " " + Core__Option.getOr(props.className, "")
            });
}

function Dialog$DialogBody(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: classes.scrollContent
            });
}

var DialogBody = {
  make: Dialog$DialogBody
};

var make = Dialog;

export {
  classes ,
  make ,
  DialogBody ,
}
/* classes Not a pure module */
