// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AppStorage from "../../utils/AppStorage.bs.js";
import * as InputToggle from "../InputToggle/InputToggle.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import InputThrustDeviceModuleCss from "./InputThrustDevice.module.css";

var classes = InputThrustDeviceModuleCss;

function InputThrustDevice(props) {
  var match = AppStorage.useLocalStorage(AppStorage.keyThrustDevice);
  var setThrustDevice = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: "Důvěřovat tomuto zařízení",
                              htmlFor: "thrust_device"
                            }),
                        JsxRuntime.jsx("p", {
                              children: "Aplikace pak funguje i bez internetu."
                            })
                      ]
                    }),
                JsxRuntime.jsx(InputToggle.make, {
                      checked: match[0] !== undefined,
                      id: "thrust_device",
                      name: "thrust_device",
                      onChange: (function ($$event) {
                          var target = $$event.target;
                          var checked = target.checked;
                          setThrustDevice(checked ? "1" : undefined);
                        })
                    })
              ],
              className: classes.root
            });
}

var make = InputThrustDevice;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
