// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Core__Dict from "@rescript/core/src/Core__Dict.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as InputWrapper from "../InputWrapper/InputWrapper.bs.js";
import * as FormattedCurrency from "../FormattedCurrency/FormattedCurrency.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import InputDonorsModuleCss from "./InputDonors.module.css";

var classes = InputDonorsModuleCss;

function InputDonors(props) {
  var onChange = props.onChange;
  var value = props.value;
  var persons = props.persons;
  var legendSlot = props.legendSlot;
  var errorMessage = props.errorMessage;
  var match = FormattedCurrency.useCurrency();
  var minorUnit = match.minorUnit;
  var match$1 = React.useMemo((function () {
          var entries = Object.entries(value);
          var unused = Array.from(persons.entries()).filter(function (param) {
                return value[param[0]] === undefined;
              });
          var sum = Core__Array.reduce(entries, 0, (function (acc, param) {
                  return acc + param[1] | 0;
                }));
          return [
                  entries,
                  unused,
                  sum
                ];
        }), [value]);
  var unusedPersons = match$1[1];
  return JsxRuntime.jsxs("fieldset", {
              children: [
                legendSlot !== undefined ? JsxRuntime.jsx("legend", {
                        children: Caml_option.valFromOption(legendSlot)
                      }) : null,
                JsxRuntime.jsxs("table", {
                      children: [
                        JsxRuntime.jsx("thead", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("th", {
                                            children: "Jméno",
                                            scope: "col"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: "Částka",
                                            scope: "col"
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx("span", {
                                                  children: "Akce",
                                                  className: Styles.utility.srOnly
                                                }),
                                            scope: "col"
                                          })
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx("tbody", {
                              children: match$1[0].map(function (param) {
                                    var personId = param[0];
                                    return JsxRuntime.jsxs("tr", {
                                                children: [
                                                  JsxRuntime.jsx("th", {
                                                        children: Core__Option.getExn(persons.get(personId), undefined),
                                                        scope: "row"
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsx("input", {
                                                              min: "0",
                                                              step: 1,
                                                              type: "number",
                                                              value: (param[1] / minorUnit).toString(),
                                                              onChange: (function (e) {
                                                                  var target = e.target;
                                                                  var newAmount = target.valueAsNumber;
                                                                  var newAmountInt = newAmount * minorUnit | 0;
                                                                  var newValue = Object.assign({}, value);
                                                                  newValue[personId] = newAmountInt;
                                                                  onChange(newValue);
                                                                })
                                                            })
                                                      }),
                                                  JsxRuntime.jsx("td", {
                                                        children: JsxRuntime.jsx("button", {
                                                              children: "❌",
                                                              className: Styles.button.iconOnly + " " + Styles.button.sizeExtraSmall,
                                                              title: "Odebrat vkladatele",
                                                              type: "button",
                                                              onClick: (function (param) {
                                                                  var newValue = Object.assign({}, value);
                                                                  Core__Dict.$$delete(newValue, personId);
                                                                  onChange(newValue);
                                                                })
                                                            })
                                                      })
                                                ]
                                              }, personId);
                                  })
                            }),
                        JsxRuntime.jsx("tbody", {
                              children: JsxRuntime.jsx("tr", {
                                    children: JsxRuntime.jsx("td", {
                                          children: JsxRuntime.jsxs("select", {
                                                children: [
                                                  JsxRuntime.jsx("option", {
                                                        children: "Přidat vkladatele",
                                                        disabled: true,
                                                        value: ""
                                                      }),
                                                  unusedPersons.map(function (param) {
                                                        var personId = param[0];
                                                        return JsxRuntime.jsx("option", {
                                                                    children: param[1],
                                                                    value: personId
                                                                  }, personId);
                                                      })
                                                ],
                                                disabled: unusedPersons.length === 0,
                                                value: "",
                                                onChange: (function ($$event) {
                                                    var person = $$event.target.value;
                                                    if (person === "") {
                                                      return ;
                                                    }
                                                    var newValue = Object.assign({}, value);
                                                    newValue[person] = 0;
                                                    onChange(newValue);
                                                  })
                                              }),
                                          colSpan: 2
                                        })
                                  })
                            }),
                        JsxRuntime.jsx("tfoot", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("th", {
                                            children: "Celkem",
                                            scope: "row"
                                          }),
                                      JsxRuntime.jsx("td", {
                                            children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                  value: match$1[2]
                                                }),
                                            colSpan: 2
                                          })
                                    ]
                                  })
                            })
                      ]
                    }),
                errorMessage !== undefined ? JsxRuntime.jsx(InputWrapper.ErrorMessage.make, {
                        message: errorMessage
                      }) : null
              ],
              className: "reset " + classes.root
            });
}

var make = InputDonors;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
