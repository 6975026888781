// Generated by ReScript, PLEASE EDIT WITH CARE


var omitUndefined = (data => {
  const result = {}
  for (const [key, value] of Object.entries(data)) {
    if (value !== undefined) {
      result[key] = value
    }
  }
  return result
});

function setIn(obj, key, value) {
  var result = Object.assign({}, obj);
  result[key] = value;
  return result;
}

function setInD(dict, key, value) {
  var result = Object.assign({}, dict);
  result[key] = value;
  return result;
}

export {
  omitUndefined ,
  setIn ,
  setInD ,
}
/* No side effect */
