// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";

function usePromise(fn) {
  var match = React.useState(function () {
        return {
                state: "idle",
                data: undefined,
                error: undefined
              };
      });
  var setResult = match[1];
  var run = function () {
    setResult(function (prevResult) {
          return {
                  state: "pending",
                  data: prevResult.data,
                  error: undefined
                };
        });
    Core__Promise.$$catch(fn().then(function (data) {
              setResult(function (param) {
                    return {
                            state: "fulfilled",
                            data: Caml_option.some(data),
                            error: undefined
                          };
                  });
              return Promise.resolve();
            }), (function (error) {
            setResult(function (param) {
                  return {
                          state: "rejected",
                          data: undefined,
                          error: error
                        };
                });
            return Promise.resolve();
          }));
  };
  return [
          match[0],
          run
        ];
}

export {
  usePromise ,
}
/* react Not a pure module */
