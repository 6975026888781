// Generated by ReScript, PLEASE EDIT WITH CARE

import BoxModuleCss from "./box.module.css";
import LinkModuleCss from "./link.module.css";
import ListModuleCss from "./list.module.css";
import PageModuleCss from "./page.module.css";
import RadioModuleCss from "./radio.module.css";
import StackModuleCss from "./stack.module.css";
import TableModuleCss from "./table.module.css";
import ButtonModuleCss from "./button.module.css";
import UtilityModuleCss from "./utility.module.css";
import CheckboxModuleCss from "./checkbox.module.css";
import FieldsetModuleCss from "./fieldset.module.css";
import MessageBarModuleCss from "./messageBar.module.css";
import DescriptionListModuleCss from "./descriptionList.module.css";

var box = BoxModuleCss;

var button = ButtonModuleCss;

var checkbox = CheckboxModuleCss;

var descriptionList = DescriptionListModuleCss;

var fieldset = FieldsetModuleCss;

var link = LinkModuleCss;

var list = ListModuleCss;

var messageBar = MessageBarModuleCss;

var page = PageModuleCss;

var radio = RadioModuleCss;

var stack = StackModuleCss;

var table = TableModuleCss;

var utility = UtilityModuleCss;

export {
  box ,
  button ,
  checkbox ,
  descriptionList ,
  fieldset ,
  link ,
  list ,
  messageBar ,
  page ,
  radio ,
  stack ,
  table ,
  utility ,
}
/* box Not a pure module */
