// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReForm from "../../reform/ReForm.bs.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import PersonAddPersonsSettingModuleCss from "./PersonAddPersonsSetting.module.css";

var classes = PersonAddPersonsSettingModuleCss;

function get(state, field) {
  return state.name;
}

function set(_state, field, value) {
  return {
          name: value
        };
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function PersonAddPersonsSetting(props) {
  var onSubmit = props.onSubmit;
  var form = Form.use({
        name: ""
      }, Validators$1.schema([
            Validators$1.required("Name"),
            Validators$1.notIn(props.existingNames, "Takové jméno již evidujeme", "Name")
          ]), (function (param) {
          onSubmit(param.state.values);
        }), undefined, undefined, "OnDemand", undefined);
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba";
  }
  return JsxRuntime.jsx(DialogForm.make, {
              children: JsxRuntime.jsxs(Form.Provider.make, {
                    value: form,
                    children: [
                      JsxRuntime.jsx("form", {
                            children: JsxRuntime.jsx("fieldset", {
                                  children: JsxRuntime.jsx(Form.Field.make, {
                                        field: "Name",
                                        render: (function (field) {
                                            return JsxRuntime.jsx(InputWrapper.make, {
                                                        inputError: field.error,
                                                        inputName: "name",
                                                        inputSlot: JsxRuntime.jsx("input", {
                                                              autoFocus: true,
                                                              type: "text",
                                                              value: field.value,
                                                              onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                            }),
                                                        labelSlot: "Jméno"
                                                      });
                                          })
                                      }),
                                  className: "reset"
                                }),
                            id: "add_person",
                            onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                          }),
                      tmp
                    ]
                  }),
              className: classes.root,
              formId: "add_person",
              heading: "Přidat osobu",
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = PersonAddPersonsSetting;

export {
  classes ,
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* classes Not a pure module */
