// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as React from "react";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as InputDonors from "../../components/InputDonors/InputDonors.bs.js";
import * as InputToggle from "../../components/InputToggle/InputToggle.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import KegAddNewModuleCss from "./KegAddNew.module.css";

var classes = KegAddNewModuleCss;

function get(state, field) {
  switch (field) {
    case "Beer" :
        return state.beer;
    case "Donors" :
        return state.donors;
    case "Milliliters" :
        return state.milliliters;
    case "OwnerIsDonor" :
        return state.ownerIsDonor;
    case "Price" :
        return state.price;
    case "Serial" :
        return state.serial;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case "Beer" :
        return {
                beer: value,
                donors: state.donors,
                milliliters: state.milliliters,
                ownerIsDonor: state.ownerIsDonor,
                price: state.price,
                serial: state.serial
              };
    case "Donors" :
        return {
                beer: state.beer,
                donors: value,
                milliliters: state.milliliters,
                ownerIsDonor: state.ownerIsDonor,
                price: state.price,
                serial: state.serial
              };
    case "Milliliters" :
        return {
                beer: state.beer,
                donors: state.donors,
                milliliters: value,
                ownerIsDonor: state.ownerIsDonor,
                price: state.price,
                serial: state.serial
              };
    case "OwnerIsDonor" :
        return {
                beer: state.beer,
                donors: state.donors,
                milliliters: state.milliliters,
                ownerIsDonor: value,
                price: state.price,
                serial: state.serial
              };
    case "Price" :
        return {
                beer: state.beer,
                donors: state.donors,
                milliliters: state.milliliters,
                ownerIsDonor: state.ownerIsDonor,
                price: value,
                serial: state.serial
              };
    case "Serial" :
        return {
                beer: state.beer,
                donors: state.donors,
                milliliters: state.milliliters,
                ownerIsDonor: state.ownerIsDonor,
                price: state.price,
                serial: value
              };
    
  }
}

var FormFields = {
  get: get,
  set: set
};

var emptyState_donors = {};

var emptyState = {
  beer: "",
  donors: emptyState_donors,
  milliliters: 0,
  ownerIsDonor: true,
  price: 0,
  serial: 1
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

var getSelectedOption = (select => Array.from(select.selectedOptions, option => option.value));

function KegAddNew$FormComponent(props) {
  var personsAll = props.personsAll;
  var onSubmit = props.onSubmit;
  var personsAllMap = React.useMemo((function () {
          return new Map(personsAll.map(function (param) {
                          return [
                                  param[0],
                                  param[1].name
                                ];
                        }));
        }), [personsAll]);
  var match = FormattedCurrency.useCurrency();
  var minorUnit = match.minorUnit;
  var currency = match.currency;
  var mostRecentKegStatus = Db.useMostRecentKegStatus(props.placeId);
  var mostResentKegs = mostRecentKegStatus.data;
  if (mostResentKegs === undefined) {
    return null;
  }
  var recentKegState = Core__Option.mapOr(mostResentKegs.at(0), emptyState, (function (keg) {
          return {
                  beer: keg.beer,
                  donors: keg.donors,
                  milliliters: keg.milliliters,
                  ownerIsDonor: true,
                  price: keg.price,
                  serial: keg.serial + 1 | 0
                };
        }));
  var form = Form.use(recentKegState, Validators$1.schema([
            Validators$1.required("Beer"),
            Validators$1.$$int(1, "Sud nemůže být nulový", undefined, undefined, "Milliliters"),
            Validators$1.$$int(0, "Cena nemůže být záporná", undefined, undefined, "Price"),
            Validators$1.custom((function (lensState) {
                    if (lensState.ownerIsDonor) {
                      return "Valid";
                    }
                    var donorsSum = Core__Array.reduce(Js_dict.values(lensState.donors), 0, (function (a, b) {
                            return a + b | 0;
                          }));
                    var kegPrice = lensState.price;
                    if (kegPrice > 0 && kegPrice !== donorsSum) {
                      return {
                              TAG: "Error",
                              _0: "Cena sudu se neshoduje s příspěvky vkladatelů"
                            };
                    } else {
                      return "Valid";
                    }
                  }), "Donors")
          ]), (function (param) {
          onSubmit(param.state.values);
        }), undefined, undefined, "OnDemand", undefined);
  return JsxRuntime.jsx(Form.Provider.make, {
              value: form,
              children: JsxRuntime.jsxs("form", {
                    children: [
                      JsxRuntime.jsxs("fieldset", {
                            children: [
                              JsxRuntime.jsx(Form.Field.make, {
                                    field: "Beer",
                                    render: (function (field) {
                                        return JsxRuntime.jsx(InputWrapper.make, {
                                                    inputError: field.error,
                                                    inputName: "beer",
                                                    inputSlot: JsxRuntime.jsx("input", {
                                                          type: "text",
                                                          value: field.value,
                                                          onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                        }),
                                                    labelSlot: "Pivo"
                                                  });
                                      })
                                  }),
                              JsxRuntime.jsx(Form.Field.make, {
                                    field: "Milliliters",
                                    render: (function (field) {
                                        var liters = field.value / 1000.0;
                                        return JsxRuntime.jsx(InputWrapper.make, {
                                                    inputError: field.error,
                                                    inputName: "milliliters",
                                                    inputSlot: JsxRuntime.jsx("input", {
                                                          max: "200",
                                                          min: "1",
                                                          step: 1.0,
                                                          type: "number",
                                                          value: liters.toString(),
                                                          onChange: (function ($$event) {
                                                              field.handleChange(Math.imul($$event.target.valueAsNumber, 1000));
                                                            })
                                                        }),
                                                    labelSlot: "Objem sudu",
                                                    unitSlot: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedPlural, {
                                                              value: liters | 0,
                                                              other: "Litrů",
                                                              one: "Litr",
                                                              few: "Litry"
                                                            }))
                                                  });
                                      })
                                  }),
                              JsxRuntime.jsx(Form.Field.make, {
                                    field: "Price",
                                    render: (function (field) {
                                        return JsxRuntime.jsx(InputWrapper.make, {
                                                    inputError: field.error,
                                                    inputName: "price",
                                                    inputSlot: JsxRuntime.jsx("input", {
                                                          min: "0",
                                                          step: 1.0,
                                                          type: "number",
                                                          value: (field.value / minorUnit).toString(),
                                                          onChange: (function ($$event) {
                                                              field.handleChange($$event.target.valueAsNumber * minorUnit | 0);
                                                            })
                                                        }),
                                                    labelSlot: "Cena sudu",
                                                    unitSlot: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedNumberParts, {
                                                              value: 0,
                                                              style: "currency",
                                                              currency: currency,
                                                              children: (function (formattedNumberParts) {
                                                                  return Core__Option.getOr(Core__Option.map(formattedNumberParts.find(function (p) {
                                                                                      return p.type === "currency";
                                                                                    }), (function (p) {
                                                                                    return p.value;
                                                                                  })), null);
                                                                })
                                                            }))
                                                  });
                                      })
                                  })
                            ],
                            className: "reset " + Styles.fieldset.grid
                          }),
                      JsxRuntime.jsx(Form.Field.make, {
                            field: "OwnerIsDonor",
                            render: (function (field) {
                                var ownerIsDonor = field.value;
                                return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                            children: [
                                              JsxRuntime.jsx(InputWrapper.make, {
                                                    inputName: "owner_is_donor",
                                                    inputSlot: JsxRuntime.jsx(InputToggle.make, {
                                                          checked: ownerIsDonor,
                                                          onChange: (function ($$event) {
                                                              var target = $$event.target;
                                                              field.handleChange(target.checked);
                                                            })
                                                        }),
                                                    labelSlot: "Vkládá vlastník místa"
                                                  }),
                                              ownerIsDonor ? null : JsxRuntime.jsx(Form.Field.make, {
                                                      field: "Donors",
                                                      render: (function (field) {
                                                          return JsxRuntime.jsx(InputDonors.make, {
                                                                      errorMessage: field.error,
                                                                      legendSlot: "Vkladatelé sudu",
                                                                      persons: personsAllMap,
                                                                      value: field.value,
                                                                      onChange: field.handleChange
                                                                    });
                                                        })
                                                    })
                                            ]
                                          });
                              })
                          })
                    ],
                    className: classes.form,
                    id: "add_keg",
                    onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                  })
            });
}

var FormComponent = {
  make: KegAddNew$FormComponent
};

function KegAddNew(props) {
  return JsxRuntime.jsx(DialogForm.make, {
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(KegAddNew$FormComponent, {
                              onSubmit: props.onSubmit,
                              personsAll: props.personsAll,
                              placeId: props.placeId
                            })),
                    fallback: "Načítám"
                  }),
              formId: "add_keg",
              heading: "Přidat sud",
              onDismiss: props.onDismiss,
              visible: true
            });
}

var make = KegAddNew;

export {
  classes ,
  FormFields ,
  emptyState ,
  Form ,
  Validators$1 as Validators,
  getSelectedOption ,
  FormComponent ,
  make ,
}
/* classes Not a pure module */
