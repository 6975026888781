// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as ErrorUtils from "../utils/ErrorUtils.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function isFirebaseError(exn) {
  return exn.name === "FirebaseError";
}

var EmailChangeNeedsVerification = /* @__PURE__ */Caml_exceptions.create("FirebaseError.EmailChangeNeedsVerification");

var EmailExists = /* @__PURE__ */Caml_exceptions.create("FirebaseError.EmailExists");

var InvalidPassword = /* @__PURE__ */Caml_exceptions.create("FirebaseError.InvalidPassword");

var NeedConfirmation = /* @__PURE__ */Caml_exceptions.create("FirebaseError.NeedConfirmation");

var UnverifiedEmail = /* @__PURE__ */Caml_exceptions.create("FirebaseError.UnverifiedEmail");

var WeakPassword = /* @__PURE__ */Caml_exceptions.create("FirebaseError.WeakPassword");

function toFirebaseError(exn) {
  if (exn.RE_EXN_ID !== Js_exn.$$Error) {
    return exn;
  }
  var obj = exn._1;
  if (obj.name !== "FirebaseError") {
    return exn;
  }
  var match = ErrorUtils.code(obj);
  if (match === undefined) {
    return exn;
  }
  switch (match) {
    case "auth/account-exists-with-different-credential" :
        return {
                RE_EXN_ID: NeedConfirmation
              };
    case "auth/email-already-in-use" :
        return {
                RE_EXN_ID: EmailExists
              };
    case "auth/email-change-needs-verification" :
        return {
                RE_EXN_ID: EmailChangeNeedsVerification
              };
    case "auth/unverified-email" :
        return {
                RE_EXN_ID: UnverifiedEmail
              };
    case "auth/weak-password" :
        return {
                RE_EXN_ID: WeakPassword
              };
    case "auth/wrong-password" :
        return {
                RE_EXN_ID: InvalidPassword
              };
    default:
      return exn;
  }
}

export {
  isFirebaseError ,
  EmailChangeNeedsVerification ,
  EmailExists ,
  InvalidPassword ,
  NeedConfirmation ,
  UnverifiedEmail ,
  WeakPassword ,
  toFirebaseError ,
}
/* No side effect */
