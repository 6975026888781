// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as DialogForm from "../../components/DialogForm/DialogForm.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as PlaceDelete from "./PlaceDelete.bs.js";
import * as InputWrapper from "../../components/InputWrapper/InputWrapper.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function get(state, field) {
  if (field === "CreatedAt") {
    return state.createdAt;
  } else {
    return state.name;
  }
}

function set(state, field, value) {
  if (field === "CreatedAt") {
    return {
            createdAt: value,
            name: state.name
          };
  } else {
    return {
            createdAt: state.createdAt,
            name: value
          };
  }
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function BasicInfoDialog(props) {
  var onSubmit = props.onSubmit;
  var initialValues = props.initialValues;
  var form = Form.use(initialValues, Validators$1.schema([
            Validators$1.required("Name"),
            Validators$1.required("CreatedAt")
          ]), (function (param) {
          onSubmit(param.state.values);
        }), undefined, undefined, "OnDemand", undefined);
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDialog = match[1];
  var maybeErrorMessage = form.state.formState;
  var tmp;
  if (typeof maybeErrorMessage !== "object") {
    tmp = null;
  } else {
    var maybeErrorMessage$1 = maybeErrorMessage._0;
    tmp = maybeErrorMessage$1 !== undefined ? maybeErrorMessage$1 : "Neznámá chyba";
  }
  var tmp$1;
  tmp$1 = match[0] === "Hidden" ? null : JsxRuntime.jsx(PlaceDelete.make, {
          onConfirm: props.onPlaceDelete,
          onDismiss: (function () {
              setDialog(function (param) {
                    return "Hidden";
                  });
            }),
          placeName: initialValues.name
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(DialogForm.make, {
                      children: JsxRuntime.jsxs(Form.Provider.make, {
                            value: form,
                            children: [
                              JsxRuntime.jsx("form", {
                                    children: JsxRuntime.jsxs("fieldset", {
                                          children: [
                                            JsxRuntime.jsx(Form.Field.make, {
                                                  field: "Name",
                                                  render: (function (field) {
                                                      return JsxRuntime.jsx(InputWrapper.make, {
                                                                  inputError: field.error,
                                                                  inputName: "name",
                                                                  inputSlot: JsxRuntime.jsx("input", {
                                                                        type: "text",
                                                                        value: field.value,
                                                                        onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                      }),
                                                                  labelSlot: "Název"
                                                                });
                                                    })
                                                }),
                                            JsxRuntime.jsx(Form.Field.make, {
                                                  field: "CreatedAt",
                                                  render: (function (field) {
                                                      return JsxRuntime.jsx(InputWrapper.make, {
                                                                  inputError: field.error,
                                                                  inputName: "created",
                                                                  inputSlot: JsxRuntime.jsx("input", {
                                                                        type: "date",
                                                                        value: field.value,
                                                                        onChange: ReForm__Helpers.handleChange(field.handleChange)
                                                                      }),
                                                                  labelSlot: "Založeno"
                                                                });
                                                    })
                                                })
                                          ],
                                          className: "reset " + Styles.fieldset.grid
                                        }),
                                    id: "basic_info",
                                    onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                                  }),
                              tmp,
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("button", {
                                          children: "Smazat místo",
                                          className: Styles.button.variantDanger,
                                          type: "button",
                                          onClick: (function (param) {
                                              setDialog(function (param) {
                                                    return "PlaceDelete";
                                                  });
                                            })
                                        })
                                  })
                            ]
                          }),
                      formId: "basic_info",
                      heading: "Základní údaje místa",
                      onDismiss: props.onDismiss,
                      visible: true
                    }),
                tmp$1
              ]
            });
}

var make = BasicInfoDialog;

export {
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* Form Not a pure module */
