// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rxjs from "../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Reactfire from "reactfire";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function share(data) {
  try {
    return Core__Promise.$$catch(navigator.share(data), (function (err) {
                    if (err.RE_EXN_ID !== Js_exn.$$Error) {
                      return Promise.reject(err);
                    }
                    var match = err._1.name;
                    if (match === "AbortError") {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(err);
                    }
                  })).then(function () {
                return Promise.resolve("Share");
              });
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === Js_exn.$$Error) {
      return navigator.clipboard.writeText(data.url).then(function () {
                  return Promise.resolve("Clipboard");
                });
    }
    throw exn;
  }
}

function mediaRx(query) {
  var mediaQuery = window.matchMedia(query);
  return Rxjs$1.fromEvent(mediaQuery, "change").pipe(Rxjs$1.startWith(mediaQuery), Rxjs$1.map(function (list, param) {
                  return list.matches;
                }));
}

var standaloneModeRx = mediaRx("(display-mode: standalone)");

function useIsStandaloneMode() {
  return Reactfire.useObservable("isStandaloneMode", standaloneModeRx);
}

var isOnlineRx = Rxjs.op(Rxjs$1.merge(Rxjs$1.fromEvent(window, "online").pipe(Rxjs$1.map(function (param, param$1) {
                  return true;
                })), Rxjs$1.fromEvent(window, "offline").pipe(Rxjs$1.map(function (param, param$1) {
                  return false;
                }))), Rxjs$1.startWith(window.navigator.onLine));

function useIsOnline() {
  return Reactfire.useObservable("isOnline", isOnlineRx);
}

var supportsPlatformWebAuthnCache = {
  contents: undefined
};

var supportsPlatformWebAuthn = (!window.PublicKeyCredential
  ? Promise.resolve(false)
  : window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()).then(function (result) {
      supportsPlatformWebAuthnCache.contents = result;
      return Promise.resolve(result);
    });

function useSuportsPlatformWebauthn() {
  var result = supportsPlatformWebAuthnCache.contents;
  if (result !== undefined) {
    return result;
  }
  throw supportsPlatformWebAuthn;
}

export {
  share ,
  mediaRx ,
  standaloneModeRx ,
  useIsStandaloneMode ,
  isOnlineRx ,
  useIsOnline ,
  supportsPlatformWebAuthnCache ,
  supportsPlatformWebAuthn ,
  useSuportsPlatformWebauthn ,
}
/* standaloneModeRx Not a pure module */
