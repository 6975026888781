// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import HeaderModuleCss from "./Header.module.css";

var classes = HeaderModuleCss;

function Header(props) {
  return JsxRuntime.jsxs("header", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: props.headingSlot
                    }),
                JsxRuntime.jsx("p", {
                      children: props.subheadingSlot
                    }),
                props.buttonLeftSlot,
                props.buttonRightSlot
              ],
              className: classes.root + " " + Core__Option.getOr(props.className, "")
            });
}

var make = Header;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
