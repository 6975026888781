// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Assets from "../../assets/Assets.bs.js";
import * as Dialog from "../../components/Dialog/Dialog.bs.js";
import * as ReForm from "../../reform/ReForm.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as MeterKeg from "../../components/MeterKeg/MeterKeg.bs.js";
import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as Validators from "../../utils/Validators.bs.js";
import * as BackendUtils from "../../utils/BackendUtils.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReForm__Helpers from "../../reform/ReForm__Helpers.bs.js";
import * as TableConsumptions from "../../components/TableConsumptions/TableConsumptions.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import DrinkDialogModuleCss from "./DrinkDialog.module.css";

var classes = DrinkDialogModuleCss;

function get(state, field) {
  if (field === "Tap") {
    return state.tap;
  } else {
    return state.consumption;
  }
}

function set(state, field, value) {
  if (field === "Tap") {
    return {
            tap: value,
            consumption: state.consumption
          };
  } else {
    return {
            tap: state.tap,
            consumption: value
          };
  }
}

var FormFields = {
  get: get,
  set: set
};

var Form = ReForm.Make({
      set: set,
      get: get
    });

var Validators$1 = Validators.CustomValidators({
      set: set,
      get: get
    });

function DrinkDialog(props) {
  var unfinishedConsumptions = props.unfinishedConsumptions;
  var tapsWithKegs = props.tapsWithKegs;
  var onSubmit = props.onSubmit;
  var onDismiss = props.onDismiss;
  var preferredTap = props.preferredTap;
  var personName = props.personName;
  var tapsOptions = React.useMemo((function () {
          var tapsEntries = Js_dict.entries(tapsWithKegs);
          return tapsEntries.map(function (param) {
                      var keg = param[1];
                      var tapName = param[0];
                      return {
                              text: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                    children: [
                                      tapName + ": " + keg.beer + " " + keg.serialFormatted,
                                      JsxRuntime.jsx(MeterKeg.make, {
                                            keg: keg
                                          })
                                    ]
                                  }),
                              value: tapName
                            };
                    });
        }), [tapsWithKegs]);
  var unfinishedConsumptionsDesc = React.useMemo((function () {
          return unfinishedConsumptions.toReversed();
        }), [unfinishedConsumptions]);
  var preferredTapHasKeg = tapsOptions.some(function (param) {
        return param.value === preferredTap;
      });
  var form = Form.use({
        tap: preferredTapHasKeg ? preferredTap : Core__Option.getOr(Core__Option.map(tapsOptions[0], (function (param) {
                      return param.value;
                    })), ""),
        consumption: -1
      }, Validators$1.schema([Validators$1.required("Tap")]), (function (param) {
          onSubmit(param.state.values);
        }), undefined, undefined, "OnDemand", undefined);
  return JsxRuntime.jsxs(Dialog.make, {
              children: [
                JsxRuntime.jsxs("header", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: personName
                            }),
                        JsxRuntime.jsx("button", {
                              children: "Zavřít",
                              className: Styles.button.base,
                              type: "button",
                              onClick: (function (param) {
                                  onDismiss();
                                })
                            })
                      ]
                    }),
                tapsOptions.length === 0 ? JsxRuntime.jsx("p", {
                        children: "Naražte sudy!"
                      }) : JsxRuntime.jsx(Form.Provider.make, {
                        value: form,
                        children: JsxRuntime.jsxs("form", {
                              children: [
                                JsxRuntime.jsx(Form.Field.make, {
                                      field: "Tap",
                                      render: (function (field) {
                                          var handleChange = ReForm__Helpers.handleChange(field.handleChange);
                                          return JsxRuntime.jsx("fieldset", {
                                                      children: tapsOptions.map(function (param) {
                                                            var value = param.value;
                                                            return JsxRuntime.jsxs("label", {
                                                                        children: [
                                                                          JsxRuntime.jsx("input", {
                                                                                className: Styles.radio.base,
                                                                                checked: field.value === value,
                                                                                name: "tap",
                                                                                type: "radio",
                                                                                value: value,
                                                                                onChange: handleChange
                                                                              }),
                                                                          JsxRuntime.jsx("span", {
                                                                                children: param.text
                                                                              })
                                                                        ]
                                                                      }, value);
                                                          }),
                                                      className: classes.taps
                                                    });
                                        })
                                    }),
                                JsxRuntime.jsx(Form.Field.make, {
                                      field: "Consumption",
                                      render: (function (field) {
                                          var handleChange = function ($$event) {
                                            var value = Core__Option.getExn(Core__Int.fromString($$event.target.value, undefined), undefined);
                                            field.handleChange(value);
                                            form.submit();
                                          };
                                          return JsxRuntime.jsxs("fieldset", {
                                                      children: [
                                                        JsxRuntime.jsxs("label", {
                                                              children: [
                                                                JsxRuntime.jsx("img", {
                                                                      alt: "",
                                                                      src: Assets.beerGlassSmall
                                                                    }),
                                                                JsxRuntime.jsx("input", {
                                                                      checked: field.value === BackendUtils.smallBeerInMilliliters,
                                                                      name: "consumption",
                                                                      type: "radio",
                                                                      value: BackendUtils.smallBeerInMilliliters.toString(),
                                                                      onChange: handleChange
                                                                    }),
                                                                JsxRuntime.jsx("span", {
                                                                      children: "Malé"
                                                                    })
                                                              ]
                                                            }),
                                                        JsxRuntime.jsxs("label", {
                                                              children: [
                                                                JsxRuntime.jsx("img", {
                                                                      alt: "",
                                                                      src: Assets.beerGlassLarge
                                                                    }),
                                                                JsxRuntime.jsx("input", {
                                                                      checked: field.value === BackendUtils.largeBeerInMilliliters,
                                                                      name: "consumption",
                                                                      type: "radio",
                                                                      value: BackendUtils.largeBeerInMilliliters.toString(),
                                                                      onChange: handleChange
                                                                    }),
                                                                JsxRuntime.jsx("span", {
                                                                      children: "Velké"
                                                                    })
                                                              ]
                                                            })
                                                      ],
                                                      className: classes.consumptions
                                                    });
                                        })
                                    })
                              ],
                              onSubmit: ReForm__Helpers.handleSubmit(form.submit)
                            })
                      }),
                JsxRuntime.jsxs("details", {
                      children: [
                        JsxRuntime.jsx("summary", {
                              children: "Nezaúčtovaná piva",
                              id: "unfinished_consumptions"
                            }),
                        unfinishedConsumptions.length === 0 ? JsxRuntime.jsx("p", {
                                children: personName + " nemá nezaúčtovaná piva."
                              }) : JsxRuntime.jsx(TableConsumptions.make, {
                                ariaLabelledby: "unfinished_consumptions",
                                formatConsumption: props.formatConsumption,
                                onDeleteConsumption: props.onDeleteConsumption,
                                unfinishedConsumptions: unfinishedConsumptionsDesc
                              })
                      ],
                      className: classes.unfinishedConsumptions
                    })
              ],
              className: classes.root,
              onClickOutside: onDismiss,
              visible: true
            });
}

var make = DrinkDialog;

export {
  classes ,
  FormFields ,
  Form ,
  Validators$1 as Validators,
  make ,
}
/* classes Not a pure module */
