// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Assets from "../../assets/Assets.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as HtmlEntities from "../../utils/HtmlEntities.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import LoadingFullscreenModuleCss from "./LoadingFullscreen.module.css";

var classes = LoadingFullscreenModuleCss;

function LoadingFullscreen(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("img", {
                            alt: "",
                            src: Assets.pouring
                          }),
                      JsxRuntime.jsx("p", {
                            children: "Čepuju kilobajty " + HtmlEntities.hellip
                          })
                    ],
                    className: classes.container
                  }),
              className: Styles.page.centered
            });
}

var make = LoadingFullscreen;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
